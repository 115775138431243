import apiRequest from '@/lib/apiClient'
import { BaseEntity } from './base'
import { Pipeline } from './pipelinesConfig'

export type Deployment = BaseEntity & {
    id: string
    pipelineId: string
    pipelineName: string
    deploymentName: string
    pipeline?: Pipeline
}

export type CreateDeployment = {
    pipelineId: string
    name: string
    projectId: string
}

export type DeploymentList = {
    items: Deployment[]
    totalCount: number
}

export const getAllDeployments = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<DeploymentList> => {
    return apiRequest('/v1/Deployments/search', { queryParams })
}

export const createDeployment = async (deployment: CreateDeployment): Promise<Deployment> => {
    return apiRequest('/v1/Deployments', { method: 'POST', body: deployment })
}

export const getDeploymentById = async (id: string): Promise<Deployment> => {
    return apiRequest(`/v1/Deployments/${id}`)
}

export const deleteDeployment = async (id: string): Promise<void> => {
    return apiRequest(`/v1/Deployments/${id}`, { method: 'DELETE' })
}
