import { Navigate, createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_mainLayout/$projectId/_projectLayout/')({
    component: ProjectIndex,
})

function ProjectIndex() {
    const { projectId } = Route.useParams()
    return <Navigate to={'/$projectId/pipelines'} params={{ projectId }} />
}
