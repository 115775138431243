import { MessageType, WebSocketMessage } from '@/api/conversation'
import {
    PipelineArrayRes,
    PipelineExecutionResult,
    runPipeline,
    runTemporaryPipeline,
} from '@/api/pipelineExecution'
import { Pipeline } from '@/api/pipelinesConfig'
import { MemoizedIndividualMessage } from '@/components/chat/individualMessage'
import { useToast } from '@/components/ui/use-toast.ts'
import { useMemo, useState } from 'react'
import useWebSocket from 'react-use-websocket'
import { useAuth } from './use-auth'
import { sendHelpCenterChatMessage } from '@/api/helpCenterChat.ts'

interface useChatProps {
    conversationId: string
    imageArray: string[]
    websocketUrl: string
    pipeline: Pipeline
    temporaryPrompt: string
    stream?: boolean
    debug?: boolean
    setLoading?: (response: boolean) => void
    pipelineExecutionResults?: PipelineExecutionResult[]
    setPipelineExecutionResults?: React.Dispatch<React.SetStateAction<PipelineExecutionResult[]>>
    promptVariables?: Record<string, string>
    additionalInfo?: Record<string, string>
    isTemporary?: boolean
    isHelpCenter?: boolean
}

const timeStringToFloat = (time: string) => {
    const hoursMinutes = time.split(/[.:]/)
    const hours = parseInt(hoursMinutes[0], 10) / 3600
    const minutes = (hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0) / 60
    const seconds = hoursMinutes[2] ? parseInt(hoursMinutes[2], 10) : 0
    const ms =
        (hoursMinutes[3] ? parseInt(hoursMinutes[3], 10) : 0) / Math.pow(10, hoursMinutes[3].length)
    return hours + minutes + seconds + ms
}

/*
Handles:
- Receiving messages
- Sending messages
- Error handling
*/

export const useChat = ({
    conversationId,
    imageArray,
    websocketUrl,
    temporaryPrompt,
    pipeline,
    stream,
    debug = true,
    setLoading,
    pipelineExecutionResults,
    setPipelineExecutionResults,
    promptVariables,
    additionalInfo,
    isTemporary = true,
    isHelpCenter = false,
}: useChatProps) => {
    const { toast } = useToast()

    const [messages, setMessages] = useState<MessageType[]>(() => [])
    const [sendButtonDisabled, setSendButtonDisabled] = useState(false)
    const [isPipelineRunning, setIsPipelineRunning] = useState(false)
    const { user } = useAuth()
    useWebSocket(websocketUrl, {
        onMessage: (event) => {
            const incomingMessage = WebSocketMessage.parse(JSON.parse(event.data))
            // If the index is 0, it means the assistant is starting a new message
            if (incomingMessage.Index == 0) {
                setSendButtonDisabled(true)
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        message: incomingMessage.Content,
                        role: 'assistant',
                        createdAt: new Date().toLocaleString(),
                        messageId: '',
                        images: [],
                        updatedAt: null,
                        conversationId: conversationId,
                        id: '',
                    },
                ])
            } else if (incomingMessage.Index > -1) {
                // If the index is not -1, it means the assistant is continuing a message.
                setMessages((prevMessages) => {
                    const newMessages = [...prevMessages]
                    const lastMessageIndex = newMessages.length > 0 ? newMessages.length - 1 : 0
                    const lastMessage = newMessages[lastMessageIndex]
                        ? newMessages[lastMessageIndex]
                        : {
                              message: '',
                              id: '',
                              role: 'assistant',
                              createdAt: new Date().toLocaleString(),
                              images: [],
                          }

                    newMessages[lastMessageIndex] = {
                        ...lastMessage,
                        createdAt: new Date().toLocaleString(),
                        updatedAt: null,
                        message:
                            lastMessage?.message == undefined
                                ? incomingMessage.Content
                                : lastMessage.message + incomingMessage.Content,
                    }
                    return newMessages
                })
            } else if (incomingMessage.Index == -1) {
                // -1 message is the last message from the assistant
                setSendButtonDisabled(false)
                setIsPipelineRunning(false)
                if (incomingMessage.Token) {
                    setMessages((prev) => {
                        const newMessages = [...prev]
                        const lastMessageIndex = newMessages.length > 0 ? newMessages.length - 1 : 0
                        const lastMessage = newMessages[lastMessageIndex]
                        newMessages[lastMessageIndex] = {
                            ...lastMessage,
                            totalTokens: incomingMessage.Token ?? 0,
                        }
                        return newMessages
                    })
                }
                if (incomingMessage.Duration) {
                    setMessages((prev) => {
                        const newMessages = [...prev]
                        const lastMessageIndex = newMessages.length > 0 ? newMessages.length - 1 : 0
                        const lastMessage = newMessages[lastMessageIndex]
                        newMessages[lastMessageIndex] = {
                            ...lastMessage,
                            duration: incomingMessage.Duration ?? 0,
                        }
                        return newMessages
                    })
                }
                setMessages((prev) =>
                    prev.map((message) => ({ ...message, requestCompleted: true }))
                )
            }
        },
        onClose: () => {
            setIsPipelineRunning(false)
        },
    })

    const sendMessage = async ({
        message,
        handleCleanUp,
    }: {
        message: string
        handleCleanUp: () => void
    }) => {
        try {
            const newImages = imageArray.slice()

            setMessages((prev) => [
                ...prev,
                {
                    id: '',
                    message: message,
                    role: 'user',
                    createdAt: new Date().toLocaleString(),
                    updatedAt: null,
                    conversationId,
                    messageId: '',
                    images: newImages,
                },
            ])

            handleCleanUp()
            const inMemoryMessages = messages
                .filter(({ role, message }) => role !== null && message !== null)
                .map(({ role, message }) => ({ role: role!, message: message! }))
            setIsPipelineRunning(true)
            const isStreaming = stream ?? true

            const additionalInfoArray = additionalInfo
                ? Object.entries(additionalInfo).map(([key, value]) => ({
                      [key]: value,
                  }))
                : undefined

            /*
             * Front end dudes, wow, quite the rats nest you've got here, I'm not mad, I'm in awe!
             * This function is a mess. It's doing too many things.
             * It's handling the logic for sending a message to the chat, and then it's also handling the logic for running a pipeline.
             * I'm not touching this with a 10-foot pole.
             * HACKITY HACK, HACK.
             */
            let response: PipelineExecutionResult
            if (isHelpCenter) {
                response = await sendHelpCenterChatMessage(message, conversationId)
            } else {
                response = isTemporary
                    ? await runTemporaryPipeline({
                          pipeline: pipeline,
                          userInput: message,
                          images: newImages,
                          temporaryPrompt,
                          saveHistory: false,
                          debug,
                          asyncOutput: isStreaming,
                          inMemoryMessages: inMemoryMessages,
                          conversationId,
                          userId: user?.id,
                          additionalInfo: additionalInfoArray,
                          promptVariables,
                      })
                    : await runPipeline(
                          {
                              userInput: message,
                              debug,
                              asyncOutput: isStreaming,
                              images: newImages,
                              conversationId,
                          },
                          pipeline.executionName
                      )
            }

            const d = Object.values(response.report ?? []).reduce((r, c: any) => {
                const duration = c?.timeTrackingData?.duration
                return r + (duration ? timeStringToFloat(duration) : 0)
            }, 0)

            const t = Object.values(response.report ?? []).reduce((r, c: any) => {
                const totalTokens = c?.debugInformation?.totalTokens
                return (
                    r + (totalTokens && !isNaN(parseInt(totalTokens)) ? parseInt(totalTokens) : 0)
                )
            }, 0)

            if (setPipelineExecutionResults) {
                setPipelineExecutionResults((prevResults: PipelineExecutionResult[]) => {
                    return [
                        ...prevResults,
                        {
                            stream: isStreaming,
                            executionId: isStreaming ? response.toString() : undefined,
                            report: response.report,
                            isBackupPipeline: response.isBackupPipeline,
                        },
                    ]
                })
            }

            if (!stream && setLoading) {
                if (response.result != null) {
                    if (typeof response.result == 'string') {
                        setMessages((prev) => [
                            ...prev,
                            {
                                id: '',
                                message: (response?.result as string) ?? '',
                                role: 'assistant',
                                createdAt: new Date().toLocaleString(),
                                updatedAt: null,
                                conversationId,
                                messageId: '',
                                images: newImages,
                                duration: d,
                                totalTokens: t,
                            },
                        ])
                    } else {
                        for (let i = 0; i < response?.result.length; i++) {
                            setMessages((prev) => [
                                ...prev,
                                {
                                    id: '',
                                    message: (response.result![i] as PipelineArrayRes).output,
                                    role: 'assistant',
                                    createdAt: new Date().toLocaleString(),
                                    updatedAt: null,
                                    conversationId,
                                    messageId: '',
                                    images: newImages,
                                    duration: d,
                                    totalTokens: t,
                                },
                            ])
                        }
                    }
                } else {
                    const failedSteps = Object.entries(response.report!)
                        .filter(([_, value]) => value.success === false)
                        .map(([key, value]) => ({
                            key,
                            stepId: value.stepId,
                            input: value.input,
                            result: value.result,
                            stepType: value.stepType,
                            exceptionMessage: value.exceptionMessage,
                            // Include other properties as needed
                        }))
                    if (failedSteps) {
                        const errorMessage = failedSteps.some((val) =>
                            val.exceptionMessage.toLowerCase().includes('auth')
                        )
                            ? 'The request failed due to an authentication issue, please check your api key.'
                            : 'The request failed due to an unknown error. Please consult the debug output for more information.'
                        toast({
                            variant: 'destructive',
                            title: 'Pipeline Failed',
                            description: errorMessage,
                        })
                    }
                }
                setLoading(false)
                setMessages((prev) =>
                    prev.map((message) => ({ ...message, requestCompleted: true }))
                )
            }
        } catch (err: any) {
            setLoading && setLoading(false)
            if (err.message && err.message.includes('Insufficient credits.')) {
                toast({
                    variant: 'destructive',
                    title: 'Insufficient Credits',
                    description: 'More credits are required to run this pipeline.',
                })
            } else {
                toast({
                    variant: 'destructive',
                    title: 'Uh oh! Something went wrong.',
                    description: 'There was a problem sending your message.',
                })
            }
        }
    }

    const memoizedMessageComponents = useMemo(() => {
        return messages.map((message, index) => (
            <MemoizedIndividualMessage
                id={message.id}
                key={`memoize-Use-Chat-Message-${index}`}
                message={message.message}
                createdAt={message.createdAt}
                role={message.role}
                index={index}
                conversationId={conversationId}
                images={message?.images}
                updatedAt={null}
                duration={message.duration}
                totalTokens={message.totalTokens}
                requestCompleted={message.requestCompleted}
                pipelineExecutionResults={pipelineExecutionResults}
            />
        ))
    }, [messages, conversationId, pipelineExecutionResults])

    return {
        messages,
        sendButtonDisabled,
        sendMessage,
        memoizedMessageComponents,
        isPipelineRunning,
    }
}
