import type { Connector } from '@/api/connectors.tsx'
import { ConnectorCard } from '@/components/featuredConnectors/ConnectorCard'
import SelectProjectForm from '@/components/modelLibrary/selectProjectForm.tsx'
import { Dialog, DialogContent } from '@/components/ui/dialog.tsx'
import { useRouter } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import MegaSearch from '@/components/megaSearch/MegaSearch.tsx'
import { useQuery } from '@tanstack/react-query'
import { FeatureFlag, getFeatureFlag } from '@/api/featureFlag.ts'

interface ConnectorsLibraryProps {
    connectors: Connector[]
    projectId?: string
}

const enabledConnectors = ['AzureBlob', 'CloudKit', 'FileUpload']

export function ConnectorsLibrary({ connectors, projectId }: ConnectorsLibraryProps) {
    const router = useRouter()
    const [search, setSearch] = useState('')
    const [filteredConnectors, setFilteredConnectors] = useState<Connector[]>(connectors)
    const [addToProjectOpen, setAddToProjectOpen] = useState(false)
    const [selectedConnectorId, setSelectedConnectorId] = useState('')

    useEffect(() => {
        if (search !== '') {
            setFilteredConnectors(() =>
                connectors.filter(
                    (connector) =>
                        connector.name.toLowerCase().includes(search.toLowerCase()) ||
                        connector.classification.toLowerCase().includes(search.toLowerCase())
                )
            )
        } else {
            setFilteredConnectors(connectors)
        }
    }, [search, connectors])

    const onCardClick = (connectorId: string) => {
        if (projectId) {
            router.navigate({
                to: '/$projectId/datasets/new',
                params: {
                    projectId,
                },
                search: {
                    connectorId: connectorId,
                },
            })
            return
        }
        setSelectedConnectorId(connectorId)
        setAddToProjectOpen(true)
    }

    const onAddToProject = (projectId: string) => {
        router.navigate({
            to: '/$projectId/datasets/new',
            params: {
                projectId,
            },
            search: {
                connectorId: selectedConnectorId,
            },
        })
    }

    const { data: enableAllConnectors } = useQuery({
        queryKey: [FeatureFlag.EnableAllConnectorsFeatureFlag],
        queryFn: () => getFeatureFlag(FeatureFlag.EnableAllConnectorsFeatureFlag),
    })

    return (
        <div className={'gap-6 mt-2 mx-1'}>
            <div className={'my-6 px-[15%]'}>
                <MegaSearch
                    onSearch={setSearch}
                    placeholder={'Search by Connector Name or Category'}
                />
            </div>
            <div
                className={
                    'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-8'
                }
            >
                {filteredConnectors
                    .filter((c) => enabledConnectors.includes(c.id))
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((connector) => (
                        <ConnectorCard
                            key={connector.id}
                            connector={connector}
                            onCardClick={() => onCardClick(connector.id)}
                        />
                    ))}
                {filteredConnectors
                    .filter((c) => !enabledConnectors.includes(c.id))
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((connector) => (
                        <ConnectorCard
                            key={connector.id}
                            connector={connector}
                            onCardClick={() => onCardClick(connector.id)}
                            isDisabled={!enableAllConnectors}
                        />
                    ))}
            </div>
            <Dialog open={addToProjectOpen} onOpenChange={setAddToProjectOpen}>
                <DialogContent>
                    <SelectProjectForm onSelectProjectSubmit={onAddToProject} />
                </DialogContent>
            </Dialog>
        </div>
    )
}
