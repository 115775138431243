import {
    Conversation,
    createNewConversation,
    deleteConversation,
    getAllConversations,
    getConversation,
} from '@/api/conversations'
import { GetAllDataSourceGroups } from '@/api/dataSourceGroup'
import { DataSourceType, GetAllDatasets } from '@/api/datasets'
import { GetAllModels } from '@/api/models'
import { TemporaryPipelineRequest } from '@/api/pipelineExecution'
import { PipelineVersion } from '@/api/pipelineVersion'
import { DatasetStepData, StepType } from '@/api/pipelinesConfig'
import { getAllPrompts } from '@/api/prompts'
import AiriaLogoBlack from '@/assets/icons/airialogoblack.svg?react'
import DocumentCheck from '@/assets/icons/document-check.svg?react'
import EditIcon from '@/assets/icons/edit.svg?react'
import MessageCircle from '@/assets/icons/message-circle.svg?react'
import Stars from '@/assets/icons/stars.svg?react'
import { DataSourceGroupItem } from '@/components/legal/documents/DataSourceGroupItem'
import { NewClient } from '@/components/legal/documents/NewClient'
import { CreateNewClient } from '@/components/legal/onboarding/CreateNewClient'
import { CreateNewMatter } from '@/components/legal/onboarding/CreateNewMatter'
import OnboardingHeader from '@/components/legal/onboarding/OnboardingHeader'
import { UploadLegalFiles } from '@/components/legal/onboarding/UploadLegalFiles'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Separator } from '@/components/ui/separator'
import { Textarea } from '@/components/ui/textarea'
import { QueryKeys } from '@/constants/QueryKeys'
import { useAuth } from '@/hooks/use-auth'
import { useChatStream } from '@/hooks/use-chat-stream'
import { useConfidentiality } from '@/hooks/use-confidentiality'
import { customComponents } from '@/lib/MarkdownStyles'
import { cn, downloadFile } from '@/lib/utils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Document, HeadingLevel, Packer, Paragraph, TextRun } from 'docx'
import { ChevronLeft, Copy, FileText, Plus, Share } from 'lucide-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import Markdown from 'react-markdown'
import { v4 as uuidv4 } from 'uuid'

export const Route = createFileRoute('/_legalLayout/$projectId/legal/')({
    component: () => <AssistantPage />,
})

const AssistantPage: React.FC = () => {
    const params = Route.useParams()
    const [query, setQuery] = useState<string>('')
    const [selectedDatasets, setSelectedDatasets] = useState<Set<string>>(new Set())
    const { user, isLoading } = useAuth()
    const { isConfidential, setIsConfidential } = useConfidentiality()

    const [openNewClient, setOpenNewClient] = useState(false)
    const [onboarding, setOnboarding] = useState({ step: 1, enabled: false })
    const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set())

    const updateOnboarding = (step: number, enabled: boolean) => {
        const updatedOnboarding = { ...onboarding, step, enabled }
        localStorage.setItem('onboarding', JSON.stringify(updatedOnboarding))
        setOnboarding(updatedOnboarding)
    }

    const toggleDatasetSelection = (datasetId: string) => {
        setSelectedDatasets((prevSelected) => {
            const newSelected = new Set(prevSelected)
            if (newSelected.has(datasetId)) {
                newSelected.delete(datasetId)
            } else {
                newSelected.add(datasetId)
            }
            return newSelected
        })
    }

    const toggleGroupExpansion = (groupId: string) => {
        setExpandedGroups((prevExpanded) => {
            const newExpanded = new Set(prevExpanded)
            if (newExpanded.has(groupId)) {
                newExpanded.delete(groupId)
            } else {
                newExpanded.add(groupId)
            }
            return newExpanded
        })
    }

    const scrollAreaRef = useRef<HTMLDivElement>(null)

    const queryClient = useQueryClient()

    const PromptsQuery = useQuery({
        queryKey: [QueryKeys.ALL_PROMPTS, params.projectId],
        queryFn: () => getAllPrompts({ projectId: params.projectId }),
    })

    const dataSourcesQuery = useQuery({
        queryKey: [QueryKeys.ALL_DATASETS],
        queryFn: () =>
            GetAllDatasets({
                projectId: params.projectId,
                userId: user?.id ?? '',
            }),
        enabled: !!user?.id,
    })

    const datasourceGroupsQuery = useQuery({
        queryKey: [QueryKeys.ALL_DATASOURCE_GROUPS],
        queryFn: () => GetAllDataSourceGroups(undefined, user?.id ?? undefined),
        enabled: !isLoading && !!user?.id,
    })

    const reconcileDataSourcesAndGroups = useMemo(() => {
        if (!dataSourcesQuery.data || !datasourceGroupsQuery.data) return []

        return datasourceGroupsQuery.data.map((group) => ({
            ...group,
            dataSources: dataSourcesQuery.data.items.filter(
                (ds) => ds.dataSourceGroupId === group.id
            ),
        }))
    }, [dataSourcesQuery.data, datasourceGroupsQuery.data])

    const renderMarkdown = (message?: string | null) => {
        if (message) {
            return (
                <Markdown
                    components={{
                        ...customComponents,
                        code: (props) => <div>{props.children}</div>,
                    }}
                >
                    {message}
                </Markdown>
            )
        }
    }

    useEffect(() => {
        if (datasourceGroupsQuery.isFetched) {
            const isOnboarding = localStorage.getItem('onboarding')
            if (isOnboarding == null && datasourceGroupsQuery.data) {
                let initialOnboarding
                if (datasourceGroupsQuery.data.length > 0) {
                    initialOnboarding = { step: 5, enabled: false }
                } else {
                    initialOnboarding = { step: 1, enabled: true }
                }
                localStorage.setItem('onboarding', JSON.stringify(initialOnboarding))
                setOnboarding(initialOnboarding)
            } else if (isOnboarding) {
                // Add a null check before parsing
                try {
                    const parsedOnboarding = JSON.parse(isOnboarding)
                    setOnboarding(parsedOnboarding)
                } catch (error) {
                    console.error('Error parsing onboarding data:', error)
                    // Set a default value in case of parsing error
                    setOnboarding({ step: 1, enabled: true })
                }
            } else {
                // Handle the case where isOnboarding is null but we've been through this check before
                setOnboarding({ step: 1, enabled: true })
            }
        }
    }, [datasourceGroupsQuery.data])

    const conversationsQuery = useQuery<Conversation[], Error>({
        queryKey: [QueryKeys.USER_CONVERSATIONS, user?.id],
        queryFn: async () => {
            try {
                const conversations = await (await getAllConversations(user?.id ?? ''))
                    .conversations
                if (conversations.length === 0 || typeof conversations === 'object') {
                    const newConversation = await createConversationMutation.mutateAsync()
                    return [newConversation]
                }
                return conversations
            } catch (error) {
                console.error('Error fetching conversations:', error)
                return []
            }
        },
        enabled: !!user?.id,
    })

    const firstConversationQuery = useQuery<Conversation, Error>({
        queryKey: [QueryKeys.FIRST_CONVERSATION, user?.id],
        queryFn: async () => {
            const conversationId = conversationsQuery.data
                ? conversationsQuery.data[0].conversationId
                : ''
            const response = await getConversation(conversationId)
            return response
        },
        enabled: conversationsQuery.isFetched,
    })

    const createConversationMutation = useMutation<Conversation, Error, void>({
        mutationFn: () => createNewConversation(user?.id ?? ''),
        onSuccess: (newConversation) => {
            queryClient.setQueryData<Conversation[]>(
                [QueryKeys.USER_CONVERSATIONS, user?.id],
                (oldData) => [...(oldData || []), newConversation]
            )
        },
    })

    const deleteConversationMutation = useMutation({
        mutationFn: (args: { conversationId: string }) => deleteConversation(args.conversationId),

        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.USER_CONVERSATIONS, user?.id],
            })
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.FIRST_CONVERSATION, user?.id],
            })
            setMessages([])
        },
        onError: (error) => {
            console.error('Error deleting conversation:', error)
        },
    })

    const modelsQuery = useQuery({
        queryKey: [QueryKeys.ALL_MODELS, params.projectId],
        queryFn: () => GetAllModels({ projectId: params.projectId }),
    })

    const createTemporaryPipeline = (
        conversationId: string,
        modelId: string,
        selectedDatasets: Set<string>,
        projectId: string,
        query: string,
        promptId?: string
    ): TemporaryPipelineRequest => {
        const temporaryPipelineId = uuidv4()
        const tempVersion: PipelineVersion = {
            id: uuidv4(),
            pipelineId: temporaryPipelineId,
            versionNumber: 1,
            isLatest: true,
            steps: [
                {
                    stepType: StepType.InputStep,
                    stepId: uuidv4(),
                    position: { x: 200, y: 450 },
                    dependencies: [],
                    pipelineId: temporaryPipelineId,
                },
                // Data search steps will be inserted here
                {
                    modelId: modelId,
                    temperature: 1,
                    promptId: promptId ?? null,
                    selectedPromptVersion: null,
                    stepType: StepType.AIOperation,
                    stepId: uuidv4(),
                    position: { x: 420, y: 450 },
                    dependencies: [],
                    includeDateTimeContext: false,
                    pipelineId: temporaryPipelineId,
                },
                {
                    stepType: StepType.OutputStep,
                    stepId: uuidv4(),
                    position: { x: 800, y: 450 },
                    dependencies: [],
                    pipelineId: temporaryPipelineId,
                },
            ],
        }

        // Insert data search steps
        const dataSearchSteps: DatasetStepData[] = Array.from(selectedDatasets).map(
            (datasetId, index) => ({
                stepType: StepType.DataSearch,
                stepId: uuidv4(),
                position: { x: 300, y: 450 + index * 100 },
                dependencies: [tempVersion.steps[0].stepId],
                pipelineId: temporaryPipelineId,
                dataSource: datasetId,
                topK: '10000',
                relevanceThreshold: '1',
                isRetrieveNeighboringChunks: true,
                dataSourceType: DataSourceType.FILE_UPLOAD,
                databaseType: 'mongodb',
            })
        )

        // Insert data search steps after the input step
        tempVersion.steps.splice(1, 0, ...dataSearchSteps)

        // Update AI operation step dependencies
        const aiOperationStep = tempVersion.steps.find(
            (step) => step.stepType === StepType.AIOperation
        )
        if (aiOperationStep) {
            aiOperationStep.dependencies = [
                tempVersion.steps[0].stepId,
                ...dataSearchSteps.map((step) => step.stepId),
            ]
        }

        // Update output step dependencies
        const outputStep = tempVersion.steps[tempVersion.steps.length - 1]
        outputStep.dependencies = [aiOperationStep!.stepId]

        // Update positions based on the new steps
        tempVersion.steps.forEach((step, index) => {
            step.position.y = 450 + index * 100
        })

        return {
            pipeline: {
                id: temporaryPipelineId,
                description: 'Temporary Pipeline for Assistant',
                name: 'Temporary Assistant Pipeline',
                executionName: 'temporary_assistant_pipeline',
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                projectId: projectId,
                activeVersionId: tempVersion.id,
                activeVersion: tempVersion,
                versions: [tempVersion],
                executionStats: {
                    successCount: 0,
                    failureCount: 0,
                },
            },
            userId: user?.id ?? '',
            userInput: query,
            conversationId,
            asyncOutput: true,
            debug: true,
        }
    }

    const { messages, sendButtonDisabled, sendMessage, setMessages } = useChatStream({
        websocketUrl: firstConversationQuery.data?.websocketUrl ?? '',
    })

    useEffect(() => {
        if (onboarding.enabled && onboarding.step === 2) {
            if (!sendButtonDisabled) {
                setTimeout(() => {
                    updateOnboarding(3, true)
                }, 500)
            }
        }
    }, [sendButtonDisabled])

    const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        setQuery(e.target.value)

    const handleSendPrompt = () => {
        if (query.trim() !== '') {
            const modelId = modelsQuery.data
                ? modelsQuery.data.items.find((model) => {
                      if (isConfidential && model.displayName === 'Safe') {
                          return true
                      }
                      if (!isConfidential && model.displayName === 'Open') {
                          return true
                      }
                      return false // Only reach this if neither condition is met
                  })?.id ||
                  modelsQuery.data.items[0]?.id ||
                  ''
                : ''
            console.log(modelId)

            const promptId = PromptsQuery.data ? PromptsQuery.data.items[0].id : ''

            const conversationId = firstConversationQuery.data
                ? firstConversationQuery.data.conversationId
                : ''
            sendMessage({
                temporaryPipelineExecution: createTemporaryPipeline(
                    conversationId,
                    modelId,
                    selectedDatasets,
                    params.projectId,
                    query,
                    promptId
                ),
                handleCleanUp: () => {
                    setQuery('')
                    if (onboarding.enabled && onboarding.step === 1) {
                        updateOnboarding(2, true)
                    }
                },
            })
        }
    }

    const [shouldAutoScroll, setShouldAutoScroll] = useState(true)

    const scrollToBottom = () => {
        const scrollElement = scrollAreaRef.current?.querySelector(
            '[data-radix-scroll-area-viewport]'
        )
        if (scrollElement) {
            scrollElement.scrollTop = scrollElement.scrollHeight
        }
    }

    useEffect(() => {
        const scrollElement = scrollAreaRef.current?.querySelector(
            '[data-radix-scroll-area-viewport]'
        )
        if (!scrollElement) return

        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = scrollElement
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10 // 10px threshold
            setShouldAutoScroll(isAtBottom)
        }

        scrollElement.addEventListener('scroll', handleScroll)
        return () => scrollElement.removeEventListener('scroll', handleScroll)
    }, [])

    useEffect(() => {
        if (shouldAutoScroll) {
            scrollToBottom()
        }
    }, [messages, shouldAutoScroll])

    useEffect(() => {
        if (firstConversationQuery.data) {
            scrollToBottom()
        }
    }, [firstConversationQuery.data])

    const [hideDocs, setHideDocs] = useState(false)

    // Function to copy a single message
    const copyMessage = (message: { message: string; role: string }) => {
        const formattedMessage = `${message.role === 'user' ? 'You' : 'Airia'}: ${message.message}`
        navigator.clipboard
            .writeText(formattedMessage)
            .then(() => console.log('Message copied successfully'))
            .catch((err) => console.error('Error copying message: ', err))
    }

    const copyAllMessages = () => {
        const allMessages = [...(firstConversationQuery.data?.messages || []), ...messages]

        const formattedMessages = allMessages
            .map((message) => `${message.role === 'user' ? 'You' : 'Airia'}: ${message.message}`)
            .join('\n\n')

        navigator.clipboard
            .writeText(formattedMessages)
            .catch((err) => console.error('Error copying messages: ', err))
    }

    const exportMessagesToDocx = async () => {
        // Get all messages
        const allMessages = [...(firstConversationQuery.data?.messages || []), ...messages]

        // Create document content
        const docContent = [
            new Paragraph({
                text: 'Conversation Export',
                heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({}), // Empty paragraph for spacing
        ]

        // Add messages to the content
        allMessages.forEach((message) => {
            const role = message.role === 'user' ? 'You' : 'Airia'
            docContent.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: `${role}: `,
                            bold: true,
                        }),
                        new TextRun({
                            text: message?.message ?? '',
                        }),
                    ],
                }),
                new Paragraph({}) // Empty paragraph for spacing
            )
        })

        // Create a new document with all content
        const doc = new Document({
            sections: [
                {
                    properties: {},
                    children: docContent,
                },
            ],
        })

        // Generate the document
        const blob = await Packer.toBlob(doc)

        // Create a download link and trigger the download
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = 'conversation.docx'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    }

    const exportMessagesToTxt = () => {
        // Get all messages
        const allMessages = [...(firstConversationQuery.data?.messages || []), ...messages]

        // Create text content
        let textContent = 'Conversation Export\n\n'

        // Add messages to the content
        allMessages.forEach((message) => {
            const role = message.role === 'user' ? 'You' : 'Airia'
            textContent += `${role}: ${message.message}\n\n`
        })

        // Create a download link and trigger the download
        downloadFile(textContent, 'conversation.txt', 'text/plain')
    }

    const handleClearChat = async () => {
        if (confirm('Are You sure? ')) {
            await deleteConversationMutation.mutateAsync({
                conversationId: firstConversationQuery.data?.conversationId ?? '',
            })
        }
    }

    const noFileSelectedRecommendedPrompts = [
        {
            id: 'EmailToClient',
            label: 'Email to Client re Evidence',
            prompt: `Draft an email to my unsophisticated client concerning the evidence that must be preserved now that he has been sued for breach of contract for the contract with ACME corporation.`,
        },
        {
            id: 'EmailToTeam',
            label: 'Email to Team re New Case',
            prompt: `Draft an email to my associate and my paralegal concerning a new case we will file in federal court alleging fraud and provide bullets concerning discovery and pre-trial motions.`,
        },
        {
            id: 'DepositionQuestions',
            label: 'Deposition Questions for Witness',
            prompt: `Generate a list of important questions that I should ask an unsophisticated witness that I am deposing in a case where I am defending my client from allegations of fraud. My client has been accused of being involved with the manipulation of financial records associated with a series of transactions that occurred during their tenure as procurement officer for the city. The witness is a janitor at the city who was friendly with all the persons involved with the transactions, including my client, and was allegedly present to each of the alleged manipulations of the financial records while mopping the floors at night over a period of three months.`,
        },
        {
            id: 'SummaryOfRelevantLaw',
            label: 'Summary of Relevant Law',
            prompt: `Provide a summary of the most important cases and the relevant precedent they established concerning statutory subject matter under section 101 of the US Patent Law, the summary being for my team of advanced but junior patent attorney associates, the summary strictly including case law from the last 15 years from the US Supreme Court or the Court of Appeals for the Federal Circuit (CAFC).`,
        },
    ]

    const filesSelectedRecommendedPrompts = [
        {
            id: 'SummarizeCases',
            label: 'Summarize Cases',
            prompt: `Generate a detailed summary of these legal case filings, organized into the following sections: (1) background, (2) legal theories and pertinent facts, (3) takeaways.`,
        },
        {
            id: 'SummarizeArticles',
            label: 'Summarize Articles',
            prompt: `Generate a detailed summary of these legal articles, organized into the following sections: (1) background, (2) legal theories, (3) takeaways.`,
        },
        {
            id: 'GenerateTimeline',
            label: 'Generate Timeline',
            prompt: `Generate a comprehensive and detailed timeline of events from these legal case filings, organized chronologically.`,
        },
    ]

    const onBoardingPrompts = [
        {
            id: 'writeLeaseAgreement',
            label: 'Write a lease agreement',
            prompt: `Please write a lease agreement.`,
        },
        {
            id: 'writeCollectionLetterTemplate',
            label: 'Write a collection letter template',
            prompt: `Please write a collection letter template.`,
        },
        {
            id: 'writeHomeRenovationsContract',
            label: 'Write a simple contract for home renovations',
            prompt: `Please write a a simple contract for home renovations.`,
        },
    ]
    const addRecommendedPrompt = (promptName: string) => {
        let prompt =
            selectedDatasets.size === 0
                ? (noFileSelectedRecommendedPrompts.find((p) => p.id === promptName)?.prompt ?? '')
                : (filesSelectedRecommendedPrompts.find((p) => p.id === promptName)?.prompt ?? '')

        if (prompt === '') {
            prompt = onBoardingPrompts.find((p) => p.id === promptName)?.prompt ?? ''
        }
        setQuery(prompt)
    }

    const toggleAllInGroup = (groupId: string, isSelected: boolean) => {
        setSelectedDatasets((prevSelected) => {
            const newSelected = new Set(prevSelected)
            const group = reconcileDataSourcesAndGroups.find((g) => g.id === groupId)
            if (group) {
                group.dataSources.forEach((ds) => {
                    if (isSelected) {
                        newSelected.add(ds.id)
                    } else {
                        newSelected.delete(ds.id)
                    }
                })
            }
            return newSelected
        })
    }

    return (
        <div
            className={cn(
                'flex space-x-4 p-4 ',
                isConfidential ? 'h-[calc(100vh-120px)]' : 'h-[calc(100vh-140px)]'
            )}
        >
            <div
                className={cn('transition-all duration-300 ease-in-out flex', {
                    'w-16':
                        hideDocs ||
                        (onboarding.enabled && (onboarding.step < 3 || onboarding.step > 5)),
                    'w-1/3':
                        !hideDocs &&
                        (!onboarding.enabled || (onboarding.step >= 3 && onboarding.step <= 5)),
                    'opacity-50': onboarding.enabled && onboarding.step < 3,
                    'opacity-100': !onboarding.enabled || onboarding.step >= 3,
                })}
            >
                {hideDocs || (onboarding.enabled && onboarding.step < 3) ? (
                    <Button
                        variant="ghost"
                        className="w-full h-full flex items-center justify-center"
                        onClick={() => setHideDocs(false)}
                    >
                        <FileText className="w-6 h-6" />
                    </Button>
                ) : (
                    <Card
                        className={cn(
                            'w-full backdrop-blur-sm bg-white/75 shadow-md rounded-md h-full flex flex-col',
                            onboarding.enabled &&
                                (onboarding.step === 3 ||
                                    onboarding.step === 4 ||
                                    onboarding.step === 5)
                                ? 'border-2 border-primary-legal rounded-t-md'
                                : ''
                        )}
                    >
                        {onboarding.enabled && onboarding.step === 3 && (
                            <OnboardingHeader
                                step={3}
                                title="Create your first Client"
                                showCompleteButton={false}
                            />
                        )}
                        {onboarding.enabled && onboarding.step === 4 && (
                            <OnboardingHeader
                                step={4}
                                title="Create your first Matter"
                                showCompleteButton={false}
                            />
                        )}
                        {onboarding.enabled && onboarding.step === 5 && (
                            <OnboardingHeader
                                step={5}
                                title="Add your Legal Docs"
                                showCompleteButton={false}
                            />
                        )}

                        <CardHeader className="flex flex-col justify-between items-star gap-3 flex-shrink-0">
                            <div className="flex flex-row gap-[10px] text-black items-center justify-between">
                                <div className="flex flex-row gap-1 items-center">
                                    <DocumentCheck className="w-6 h-6" />
                                    <h2 className="text-lg font-medium">Legal Docs</h2>
                                </div>
                            </div>
                            <div className="flex space-x-2 justify-between">
                                <Badge className=" text-[#5c33ff] bg-[#ebebff] rounded-full">
                                    Clients
                                </Badge>
                                <Button
                                    size="sm"
                                    variant="ghost"
                                    onClick={() => setOpenNewClient(true)}
                                >
                                    <Plus className="w-4 h-4" />
                                </Button>
                            </div>
                            <Separator />
                        </CardHeader>
                        <CardContent className="flex-grow overflow-hidden">
                            {onboarding.enabled && onboarding.step === 3 ? (
                                <CreateNewClient
                                    projectId={params.projectId}
                                    onCreate={() => updateOnboarding(4, true)}
                                />
                            ) : (
                                <ScrollArea className="h-full pr-3">
                                    {reconcileDataSourcesAndGroups.map((group) => (
                                        <DataSourceGroupItem
                                            key={group.id}
                                            group={group}
                                            isExpanded={expandedGroups.has(group.id)}
                                            onToggleExpand={() => toggleGroupExpansion(group.id)}
                                            selectedDatasets={selectedDatasets}
                                            onToggleDataset={toggleDatasetSelection}
                                            onToggleAllInGroup={toggleAllInGroup}
                                            projectId={params.projectId}
                                        />
                                    ))}
                                    {onboarding.enabled && onboarding.step === 4 ? (
                                        <CreateNewMatter
                                            projectId={params.projectId}
                                            onCreate={() => {
                                                updateOnboarding(5, true)
                                                toggleGroupExpansion(
                                                    datasourceGroupsQuery?.data
                                                        ? datasourceGroupsQuery.data[0].id
                                                        : ''
                                                )
                                            }}
                                            dataSourceGroupId={
                                                datasourceGroupsQuery.data &&
                                                datasourceGroupsQuery.data.length > 0
                                                    ? datasourceGroupsQuery.data[0].id
                                                    : ''
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {onboarding.enabled && onboarding.step === 5 ? (
                                        <UploadLegalFiles
                                            userId={user?.id ?? ''}
                                            datasourceId={
                                                dataSourcesQuery.data &&
                                                dataSourcesQuery.data.items &&
                                                dataSourcesQuery.data.items.length > 0
                                                    ? dataSourcesQuery.data.items[0].id
                                                    : ''
                                            }
                                            onUpload={() => {
                                                updateOnboarding(5, false)
                                                toggleDatasetSelection(
                                                    dataSourcesQuery.data &&
                                                        dataSourcesQuery.data.items &&
                                                        dataSourcesQuery.data.items.length > 0
                                                        ? dataSourcesQuery.data.items[0].id
                                                        : ''
                                                )
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </ScrollArea>
                            )}
                        </CardContent>
                        <CardFooter className="flex-shrink-0 p-2 justify-start">
                            {!onboarding.enabled && (
                                <Button
                                    variant="ghost"
                                    className="flex items-center p-1 gap-1"
                                    onClick={() => setHideDocs(true)}
                                >
                                    <ChevronLeft />
                                    Hide
                                </Button>
                            )}
                        </CardFooter>
                    </Card>
                )}
            </div>

            <Card
                className={cn(
                    'backdrop-blur-sm bg-white/75 shadow-md rounded-md flex flex-col h-full transition-all duration-300 ease-in-out',
                    hideDocs
                        ? 'w-[calc(50%-32px)]'
                        : onboarding.enabled && onboarding.step === 1
                          ? 'border-2 border-primary-legal w-1/3'
                          : onboarding.enabled && onboarding.step !== 1
                            ? 'opacity-50 w-1/3'
                            : 'w-1/3'
                )}
            >
                {onboarding.enabled && onboarding.step === 1 && (
                    <OnboardingHeader
                        step={1}
                        title="Create your first request"
                        showCompleteButton={false}
                    />
                )}
                <CardHeader className="flex flex-col items-center gap-2 justify-center">
                    <div className="flex flex-row gap-[10px] text-black items-center justify-between w-full">
                        <div className="flex items-center gap-[10px]">
                            <EditIcon className="h-6 w-6 mt-1" />
                            <h2 className="text-lg font-medium mt-[7px]">Instructions</h2>
                        </div>
                        <DropdownMenu>
                            <DropdownMenuTrigger>
                                <Button
                                    size="sm"
                                    variant="ghost"
                                    className="rounded-full text-primary-legal"
                                >
                                    <Stars />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                {selectedDatasets.size === 0 ? (
                                    <DropdownMenuGroup>
                                        {noFileSelectedRecommendedPrompts.map((item, index) => (
                                            <>
                                                <DropdownMenuItem
                                                    onClick={() => addRecommendedPrompt(item.id)}
                                                >
                                                    <Stars />
                                                    <span className="pl-1.5">{item.label}</span>
                                                </DropdownMenuItem>
                                                {index <
                                                    noFileSelectedRecommendedPrompts.length - 1 && (
                                                    <DropdownMenuSeparator />
                                                )}
                                            </>
                                        ))}
                                    </DropdownMenuGroup>
                                ) : (
                                    <DropdownMenuGroup>
                                        {filesSelectedRecommendedPrompts.map((item, index) => (
                                            <>
                                                <DropdownMenuItem
                                                    onClick={() => addRecommendedPrompt(item.id)}
                                                >
                                                    <Stars />
                                                    <span className="pl-1.5">{item.label}</span>
                                                </DropdownMenuItem>
                                                {index <
                                                    filesSelectedRecommendedPrompts.length - 1 && (
                                                    <DropdownMenuSeparator />
                                                )}
                                            </>
                                        ))}
                                    </DropdownMenuGroup>
                                )}
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                    <div className="flex rounded-full bg-gray-100 p-1 shadow-inner">
                        <button
                            onClick={() => setIsConfidential(true)}
                            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                                isConfidential
                                    ? 'bg-white text-black shadow-sm'
                                    : 'text-gray-500 hover:text-gray-700'
                            }`}
                        >
                            Safe
                        </button>
                        <button
                            onClick={() => setIsConfidential(false)}
                            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                                !isConfidential
                                    ? 'bg-white text-black shadow-sm'
                                    : 'text-gray-500 hover:text-gray-700'
                            }`}
                        >
                            Open
                        </button>
                    </div>
                    {/* </div> */}
                    {/* <div className="flex space-x-2">
                        
                    </div> */}
                </CardHeader>
                <CardContent className="flex flex-col flex-grow p-0">
                    <Textarea
                        value={query}
                        onChange={handlePromptChange}
                        placeholder={
                            onboarding.enabled
                                ? 'Get started by writing your first request...'
                                : 'Write your instructions here...'
                        }
                        className={cn(
                            'resize-none border-none focus:ring-0 focus:outline-none p-6 bg-transparent',
                            'flex-grow'
                        )}
                        style={{ outline: 'none', boxShadow: 'none' }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault()
                                handleSendPrompt()
                            }
                        }}
                    />
                    {onboarding.enabled && (
                        <div className="flex-grow p-4">
                            <p>Try something from our request library</p>

                            <div className="flex flex-col gap-2">
                                {onBoardingPrompts.map((item, index) => (
                                    <div
                                        className="flex border-gray-200 p-2 items-center border rounded-md cursor-pointer"
                                        onClick={() => addRecommendedPrompt(item.id)}
                                        id={`${index}-${item.id}`}
                                    >
                                        <Stars />
                                        <span className="pl-1.5">{item.label}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <Separator />
                    <div className="p-4 flex justify-end">
                        <Button
                            onClick={handleSendPrompt}
                            className="bg-primary-legal hover:bg-primary-legal font-gilroy-bold leading-6 font-normal text-[13px]"
                            type="button"
                            disabled={sendButtonDisabled}
                        >
                            Send
                        </Button>
                    </div>
                </CardContent>
            </Card>

            <Card
                className={cn(
                    'backdrop-blur-sm bg-white/75 shadow-md rounded-md transition-all duration-300 ease-in-out',
                    hideDocs
                        ? 'w-[calc(50%-32px)]'
                        : onboarding.enabled
                          ? onboarding.step === 1 || onboarding.step === 2
                              ? 'w-3/4'
                              : 'w-1/3'
                          : 'w-1/3',
                    onboarding.enabled && onboarding.step === 2 && 'border-2 border-primary-legal',
                    onboarding.enabled && onboarding.step !== 2 && 'opacity-50'
                )}
            >
                {onboarding.enabled && onboarding.step === 2 && (
                    <OnboardingHeader step={2} title="Your answer" />
                )}
                <CardHeader className="flex flex-row justify-between items-center">
                    <div className="flex flex-row gap-[10px] text-black items-center">
                        <MessageCircle className="w-6 h-6" />
                        <h2 className="text-lg font-med mt-[3px]">Answers</h2>
                    </div>
                    <div>
                        <Button size="sm" variant="ghost">
                            <Copy onClick={copyAllMessages} className="w-4 h-4" />
                        </Button>
                        <DropdownMenu>
                            <DropdownMenuTrigger>
                                <Button size="sm" variant="ghost">
                                    <Share className="w-4 h-4" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuLabel>Export</DropdownMenuLabel>
                                <DropdownMenuSeparator />
                                <DropdownMenuGroup>
                                    <DropdownMenuItem onClick={exportMessagesToDocx}>
                                        Microsoft Word
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={exportMessagesToTxt}>
                                        Text
                                    </DropdownMenuItem>
                                </DropdownMenuGroup>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </CardHeader>
                <CardContent className="flex flex-col ">
                    <ScrollArea
                        className={cn(
                            'whitespace-pre-wrap flex-grow overflow-y-auto ',
                            onboarding.enabled && onboarding.step === 2
                                ? 'h-[calc(100vh-380px)]'
                                : 'h-[calc(100vh-300px)]'
                        )}
                        ref={scrollAreaRef}
                    >
                        <div className="flex flex-col min-h-full">
                            {firstConversationQuery.data &&
                                firstConversationQuery.data.messages.map((message, index) => (
                                    <div key={index} className="mb-4 px-1">
                                        <div className="flex items-center justify-between">
                                            <strong>
                                                {message.role === 'user' ? (
                                                    <div className="flex items-center gap-1">
                                                        <Avatar className="w-7 h-7 justify-center">
                                                            <AvatarFallback>
                                                                {user?.profile?.givenName
                                                                    ? user.profile.givenName.charAt(
                                                                          0
                                                                      )
                                                                    : 'U'}
                                                            </AvatarFallback>
                                                        </Avatar>
                                                        {user?.profile.givenName ?? 'user'}:
                                                    </div>
                                                ) : (
                                                    <div className="flex items-center gap-1">
                                                        <AiriaLogoBlack />
                                                        Airia:
                                                    </div>
                                                )}
                                            </strong>
                                            <Button size="sm" variant="ghost">
                                                <Copy
                                                    onClick={() => copyMessage(message)}
                                                    className="w-4 h-4"
                                                />
                                            </Button>
                                        </div>
                                        {renderMarkdown(message.message)}
                                    </div>
                                ))}
                            {messages.map((message, index) => (
                                <div key={index} className="mb-4 px-1">
                                    <div className="flex items-center justify-between">
                                        <strong>
                                            {message.role === 'user' ? (
                                                <div className="flex items-center gap-1">
                                                    <Avatar className="w-7 h-7 justify-center">
                                                        <AvatarFallback>
                                                            {user?.profile?.givenName
                                                                ? user.profile.givenName.charAt(0)
                                                                : 'U'}
                                                        </AvatarFallback>
                                                    </Avatar>
                                                    {user?.profile?.givenName ?? 'user'}:
                                                </div>
                                            ) : (
                                                <div className="flex items-center gap-1">
                                                    <AiriaLogoBlack />
                                                    Airia:
                                                </div>
                                            )}
                                        </strong>
                                        <Button size="sm" variant="ghost">
                                            <Copy
                                                // @ts-ignore
                                                onClick={() => copyMessage(message)}
                                                className="w-4 h-4"
                                            />
                                        </Button>
                                    </div>
                                    {renderMarkdown(message.message)}
                                </div>
                            ))}
                        </div>
                    </ScrollArea>
                    {/* @ts-ignore */}
                    {(firstConversationQuery?.data?.messages?.length > 0 ||
                        messages.length > 0) && (
                        <div className="flex justify-end mt-4 p-2 text-red-500">
                            <Button variant="ghost" size="sm" onClick={handleClearChat}>
                                Clear Chat
                            </Button>
                        </div>
                    )}
                </CardContent>
            </Card>
            <NewClient
                open={openNewClient}
                setOpen={setOpenNewClient}
                projectId={params.projectId}
            />
        </div>
    )
}
