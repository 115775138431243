import { Group } from '@/api/groups.ts'
import apiRequest from '@/lib/apiClient'

export interface User {
    id: string
    identityServerUserId: string
    firstName: string
    lastName: string
    email: string
    isActive?: boolean
    enterprise: string
    createdAt: string
    updatedAt: string
    groups: Group[]
    isSelected?: boolean
    password?: string
}

export type UserList = {
    items: User[]
    totalCount: number
}

export const getAllUsers = async (
    queryParams?: Record<string, string | number | boolean> | undefined,
    org?: boolean
): Promise<User[]> => {
    const url = `/v1/Users${org ? '?organization=true' : ''}`
    const params = new URLSearchParams(queryParams as Record<string, string>)
    return apiRequest(url + params.toString())
}

export const getAllInternalUsers = async (
    queryParams?: Record<string, string | number | boolean> | undefined
): Promise<UserList> => {
    const params = new URLSearchParams(queryParams as Record<string, string>)
    return apiRequest(`/v1/Users?${params.toString()}`)
}

export const createUser = async (User: User): Promise<void> => {
    return apiRequest('/v1/Users', { method: 'POST', body: User })
}

export const updateUser = async (User: User): Promise<void> => {
    return apiRequest(`/v1/Users/${User.id}`, { method: 'PUT', body: User })
}

export const getUserById = async (id: string): Promise<User> => {
    return apiRequest(`/v1/Users/${id}?organization=true`)
}

export const getInternalUserById = async (id: string): Promise<User> => {
    return apiRequest(`/v1/Users/${id}`)
}

export const assignUserToGroup = async (user: User): Promise<void> => {
    return apiRequest(`/v1/Users/${user.id}/groups`, { method: 'POST', body: user })
}

export const deleteUser = async ({
    userId,
    orgId,
}: {
    userId: string
    orgId: string
}): Promise<void> => {
    return apiRequest(`/v1/Users/${userId}/organizations/${orgId}`, { method: 'DELETE' })
}

export const deleteUserByAdmin = async (userId: string): Promise<void> => {
    return apiRequest(`/v1/Users/${userId}`, { method: 'DELETE' })
}

export const syncUsers = async (): Promise<void> => {
    return apiRequest('/v1/UserSync', { method: 'POST' })
}

export const inviteUser = async ({
    email,
    firstName,
    lastName,
}: {
    email: string
    firstName: string
    lastName: string
}): Promise<void> => {
    return apiRequest('/v1/Tenants/InviteUser', {
        method: 'POST',
        body: { email, firstName, lastName },
    })
}
