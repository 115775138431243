import { useEffect, useState } from 'react'
import { ChevronDown, Plus, type LucideIcon } from 'lucide-react'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { DropdownMenuArrow } from '@radix-ui/react-dropdown-menu'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from '@/components/ui/tooltip'
import {
    DropdownMenu,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu'
import { Link, useNavigate } from '@tanstack/react-router'
import { SideNavItem } from './SideNavItem'
import type { Submenu } from './MenuList'
import { t } from 'i18next'
import { CreateNewProject } from '../administration/Projects'
import { useQueryClient } from '@tanstack/react-query'
import type { ProjectWithRecent } from './Menu'
import { QueryKeys } from '@/constants/QueryKeys'
import { useSideNavStore } from '@/stores/SideNavStore'
import { useTour } from '@reactour/tour'
import { Pin } from 'lucide-react'

interface CollapseMenuButtonProps {
    icon: LucideIcon
    label: string
    active: boolean
    submenus: Submenu[]
    isOpen: boolean | undefined
    visibleProjects: ProjectWithRecent[]
}

export function CollapseMenuButton({
    icon: Icon,
    label,
    active,
    submenus,
    isOpen,
    visibleProjects,
}: CollapseMenuButtonProps) {
    const { setCurrentStep, isOpen: isTourOpen } = useTour()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const RECENT_PROJECT_IDS_KEY = 'recentProjectIds'
    const LAST_ACCESSED_PROJECT_KEY = 'lastAccessedProject'
    const isProjects = label === 'Projects'
    const { setRecentProjectIds, recentProjectIds, isAccordionOpen, setIsAccordionOpen } =
        useSideNavStore()
    const [isTooltipVisible, setIsTooltipVisible] = useState(true)

    const updateAccordionState = (stateArray: string[]) => {
        if (stateArray.includes(label)) {
            setIsAccordionOpen(stateArray.filter((state) => state !== label))
        } else {
            setIsAccordionOpen([...stateArray, label])
        }
    }

    const checkAccordionState = (stateArray: string[]) => {
        return stateArray.includes(label)
    }

    const [isCollapsed, setIsCollapsed] = useState(false)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setIsCollapsed(checkAccordionState(isAccordionOpen))
    }, [isAccordionOpen])

    const updateRecentProjects = (selectedProjectId: string) => {
        const updatedRecent = [
            selectedProjectId,
            ...recentProjectIds.filter((id) => id !== selectedProjectId),
        ].slice(0, 5)

        setRecentProjectIds(updatedRecent)

        localStorage.setItem(RECENT_PROJECT_IDS_KEY, JSON.stringify(updatedRecent))
        localStorage.setItem(LAST_ACCESSED_PROJECT_KEY, selectedProjectId)
    }

    return isOpen ? (
        <Collapsible
            open={isCollapsed}
            onOpenChange={() => updateAccordionState(isAccordionOpen)}
            className="w-full"
        >
            <CollapsibleTrigger
                className="[&[data-state=open]>div>div>svg]:rotate-180 mb-1"
                asChild
            >
                <Button
                    variant={'ghost'}
                    className={cn(
                        'w-full justify-start h-9 px-1 -ml-1 hover:bg-primary-100 hover:text-primary',
                        active && 'bg-primary-100 text-primary'
                    )}
                >
                    <div className="w-full items-center flex justify-between">
                        <div className="flex items-center">
                            <span className="mr-4">
                                <Icon size={16} />
                            </span>
                            <p
                                className={cn(
                                    'max-w-[150px] truncate',
                                    isOpen
                                        ? 'translate-x-0 opacity-100'
                                        : '-translate-x-96 opacity-0'
                                )}
                            >
                                {label}
                            </p>
                        </div>
                        {label === 'Projects' && (
                            <CreateNewProject
                                variant={'ghost'}
                                size="custom"
                                customSizeClassName="h-4 w-4 p-0 ml-3 text-black"
                                Icon={Plus as LucideIcon}
                                onSuccess={() => {
                                    queryClient.invalidateQueries({
                                        queryKey: [QueryKeys.PROJECTS],
                                    })
                                    navigate({ to: '/' })
                                }}
                            />
                        )}
                        <div
                            className={cn(
                                'whitespace-nowrap',
                                isOpen ? 'translate-x-0 opacity-100' : '-translate-x-96 opacity-0'
                            )}
                        >
                            <ChevronDown size={18} className="transition-transform duration-200" />
                        </div>
                    </div>
                </Button>
            </CollapsibleTrigger>
            <CollapsibleContent className="overflow-hidden data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down border-l-[1px] border-gray-250 ml-[7px] px-1">
                {submenus.map(({ path, label, params, id, disabled }) => {
                    const project = visibleProjects.find((project) => project.id === id)
                    if (isProjects && !project) return null
                    return (
                        <SideNavItem
                            disabled={disabled}
                            path={path}
                            params={params}
                            key={label}
                            title={label}
                            endAdornment={
                                isProjects && project && project.isPinned ? (
                                    <Pin className={cn('w-4 h-4 text-primary-900')} />
                                ) : undefined
                            }
                            onClick={
                                isProjects
                                    ? () => {
                                          isTourOpen &&
                                              setCurrentStep((currentStep) => currentStep + 1)
                                          updateRecentProjects(id ?? '')
                                      }
                                    : undefined
                            }
                        />
                    )
                })}
                {label === 'Projects' && (
                    <Link
                        to="/projects"
                        className={cn(
                            'flex rounded-[8px] items-center text-sm hover:bg-primary-100 transition-colors px-2 h-8 ml-2 text-primary'
                        )}
                        activeProps={{
                            className: 'text-primary bg-primary-100 hover:bg-accent',
                        }}
                        activeOptions={{
                            exact: false,
                        }}
                    >
                        {t('view_all_projects')}
                    </Link>
                )}
            </CollapsibleContent>
        </Collapsible>
    ) : (
        <DropdownMenu onOpenChange={() => setIsTooltipVisible(false)}>
            <TooltipProvider disableHoverableContent>
                <Tooltip delayDuration={100}>
                    <TooltipTrigger onMouseEnter={() => setIsTooltipVisible(true)} asChild>
                        <DropdownMenuTrigger asChild>
                            <Button
                                variant={'ghost'}
                                className={cn(
                                    'w-full justify-start h-9 p-0 hover:bg-primary-100 hover:text-primary',
                                    active && 'bg-primary-100 text-primary'
                                )}
                            >
                                {isOpen && (
                                    <div className="w-full items-center flex justify-between">
                                        <div className="flex items-center">
                                            <span className={cn(isOpen === false ? '' : 'mr-4')}>
                                                <Icon size={16} />
                                            </span>

                                            <p
                                                className={cn(
                                                    'max-w-[200px] truncate',
                                                    isOpen === false ? 'opacity-0' : 'opacity-100'
                                                )}
                                            >
                                                {label}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {!isOpen && <Icon className="m-auto" size={16} />}
                            </Button>
                        </DropdownMenuTrigger>
                    </TooltipTrigger>
                    {isTooltipVisible && (
                        <TooltipContent side="right" align="start" alignOffset={2}>
                            {label}
                        </TooltipContent>
                    )}
                </Tooltip>
            </TooltipProvider>
            <DropdownMenuContent side="right" sideOffset={25} align="start">
                <DropdownMenuLabel className="max-w-[190px] text-gray-550 truncate">
                    {label}
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                {submenus.map(({ path, label, params, id, disabled }) => {
                    const project = visibleProjects.find((project) => project.id === id)

                    return (
                        <DropdownMenuItem
                            className="focus:bg-primary-100 focus:text-primary"
                            disabled={disabled}
                            key={label}
                            asChild
                        >
                            <Link
                                className={
                                    'flex justify-between rounded-[8px] items-center text-sm hover:bg-primary-100 hover:text-primary px-2 h-9 cursor-pointer'
                                }
                                activeProps={{
                                    className:
                                        'text-primary bg-primary-100 hover:bg-primary-100 hover:text-primary',
                                }}
                                activeOptions={{
                                    exact: false,
                                }}
                                to={path}
                                params={params && params}
                                onClick={() =>
                                    isProjects && updateRecentProjects(params?.projectId ?? '')
                                }
                            >
                                <p className="max-w-[180px] truncate">{label}</p>
                                {isProjects && project && project.isPinned ? (
                                    <Pin className={cn('w-4 h-4 text-primary-900')} />
                                ) : undefined}
                            </Link>
                        </DropdownMenuItem>
                    )
                })}
                {label === 'Projects' && (
                    <DropdownMenuItem
                        className="focus:bg-primary-100 focus:text-primary"
                        key={label}
                        asChild
                    >
                        <Link
                            to="/projects"
                            className={cn(
                                'flex rounded-[8px] items-center text-sm hover:bg-primary-100 transition-colors px-2 h-9 text-primary cursor-pointer'
                            )}
                            activeProps={{
                                className: 'text-primary bg-primary-100 hover:bg-accent',
                            }}
                            activeOptions={{
                                exact: false,
                            }}
                        >
                            {t('view_all_projects')}
                        </Link>
                    </DropdownMenuItem>
                )}
                <DropdownMenuArrow className="fill-border" />
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
