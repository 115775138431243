import apiRequest from '@/lib/apiClient'

export const getAIGateway = async (): Promise<{ url: string }> => {
    return apiRequest('/v1/Configurations/AIGateway')
}

export const getHelpCenterConfiguration = async (): Promise<{
    pipeline: string
    apiKey: string
}> => {
    return apiRequest('/v1/Configurations/HelpCenter')
}
