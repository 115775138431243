import { LoaderCircle } from 'lucide-react'
import React from 'react'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts'

interface DonutChartProps {
    data: any[]
    dataKey: string
    nameKey: string
    colors: string[]
    loading?: boolean
}

const DonutChart: React.FC<DonutChartProps> = ({ data, dataKey, nameKey, colors, loading }) => {
    const renderLegend = (props: any) => {
        const { payload } = props
        if (payload)
            return (
                <ul className="w-32 overflow-ellipsis">
                    {payload.map((entry: any, index: number) => (
                        <li
                            className="text-[13px] items-center flex flex-row gap-2"
                            key={`item-${index}`}
                        >
                            <div
                                className="w-2 h-2 rounded-full flex-shrink-0"
                                style={{ backgroundColor: entry.color }}
                            />
                            <p className="line-clamp-1">{entry.value}</p>
                        </li>
                    ))}
                </ul>
            )
    }

    if (loading) {
        return (
            <div className="w-full h-[200px] justify-center flex flex-col">
                <LoaderCircle className="self-center w-12 h-12 animate-spin" />
            </div>
        )
    }

    return (
        <div className="w-full h-full">
            <ResponsiveContainer width="100%" height={200}>
                <PieChart>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={'50%'}
                        outerRadius={'80%'}
                        fill="#8884d8"
                        dataKey={dataKey}
                        nameKey={nameKey}
                        labelLine={false}
                    >
                        {data.map((_, index) => (
                            <Cell
                                className="outline-none"
                                key={`cell-${index}`}
                                fill={colors[index % colors.length]}
                            />
                        ))}
                    </Pie>
                    <Legend
                        layout="vertical"
                        align="right"
                        verticalAlign="middle"
                        iconType="circle"
                        content={renderLegend}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    )
}

export default DonutChart
