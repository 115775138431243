import { DefaultDataTable } from '@/components/DefaultDataTable'
import PaginationHeader, {
    type paginationAndSortingParams,
} from '@/components/pagination/paginationHeader'
import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { usePagination } from '@/hooks/use-pagination'
import useSearchEffect from '@/hooks/use-search-effect'
import { useSorting } from '@/hooks/use-sorting'
import { HandleDeleteError } from '@/lib/HandleErrors'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import {
    Link,
    Outlet,
    type SearchSchemaInput,
    createFileRoute,
    useNavigate,
    useRouter,
} from '@tanstack/react-router'
import type { ColumnDef } from '@tanstack/react-table'
import { useDebounce } from '@uidotdev/usehooks'
import { MoreHorizontal, Pencil, Trash } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from '@/lib/i18n'
import {
    type Project,
    getAllProjectsPaginated,
    deleteProject,
    pinProject,
    unPinProject,
} from '@/api/projects'
import { CreateNewProject } from '@/components/administration/Projects'
import { RECENT_PROJECT_IDS_KEY, LAST_ACCESSED_PROJECT_KEY } from '@/components/sidenav/MenuList'
import { useSideNavStore } from '@/stores/SideNavStore'
import DefaultProjectIcon from '@/assets/icons/default-project-icon.svg?react'
import { cn } from '@/lib/utils'
import { Pin } from 'lucide-react'

export const Route = createFileRoute('/_mainLayout/projects/')({
    component: () => {
        return <Projects />
    },
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            pageNumber: search.pageNumber as number,
            pageSize: search.pageSize as number,
            sortBy: search.sortBy as string,
        }
    },
})

const Projects = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const router = useRouter()
    const params = Route.useParams()
    const queryParams = Route.useSearch()
    const { setRecentProjectIds, recentProjectIds, pinnedProjects } = useSideNavStore()
    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy,
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })

    const [searchValue, setSearchValue] = useState<string>('')
    const debouncedSearchValue = useDebounce(searchValue, 500)
    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        params,
        onPaginationChange,
    })

    const GetAllProjects = useQuery({
        queryKey: [QueryKeys.PROJECTS, queryParams],
        queryFn: () => getAllProjectsPaginated(queryParams),
    })

    const queryClient = useQueryClient()

    const deleteMutation = useMutation({
        mutationFn: deleteProject,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.PROJECTS, queryParams],
            })
            toast({
                title: t('success_title'),
                description: t('project_delete_success'),
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const handleDeleteProject = async (id: string) => {
        if (window.confirm(t('project_delete_confirmation'))) {
            try {
                await deleteMutation.mutateAsync(id)
                router.invalidate()
            } catch (error) {
                HandleDeleteError(error, 'project')
            }
        }
    }

    const updateRecentProjects = (selectedProjectId: string) => {
        const updatedRecent = [
            selectedProjectId,
            ...recentProjectIds.filter((id) => id !== selectedProjectId),
        ].slice(0, 5)

        setRecentProjectIds(updatedRecent)

        localStorage.setItem(RECENT_PROJECT_IDS_KEY, JSON.stringify(updatedRecent))
        localStorage.setItem(LAST_ACCESSED_PROJECT_KEY, selectedProjectId)
    }

    const pinProjectMutation = useMutation({
        mutationFn: pinProject,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.PROJECTS],
            })
            toast({
                title: t('success_title'),
                description: t('project_pin_success'),
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const unPinProjectMutation = useMutation({
        mutationFn: unPinProject,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.PROJECTS],
            })
            toast({
                title: t('success_title'),
                description: t('project_unpin_success'),
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const columns: ColumnDef<Project>[] = [
        {
            accessorKey: 'isPinned',
            id: 'pin',
            enableSorting: false,
            header: () => {
                return <Pin className="w-4 h-4 text-primary-900" />
            },
            cell: ({ row }) => {
                let isPinned = false

                if (pinnedProjects.find((project) => project.id === row.original.id)) {
                    isPinned = true
                }

                const canPin = pinnedProjects.length < 4 || isPinned === true
                return (
                    <button
                        onClick={async (e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (isPinned) {
                                await unPinProjectMutation.mutateAsync(row.original.id)
                            } else if (canPin) {
                                await pinProjectMutation.mutateAsync(row.original.id)
                            }
                        }}
                        className={!canPin ? 'opacity-50 cursor-not-allowed ' : ''}
                        type="button"
                    >
                        {isPinned ? (
                            <Pin className="w-4 h-4 text-primary-900 fill-primary-900" />
                        ) : (
                            <Pin
                                className={cn(
                                    'w-4 h-4 text-primary-900',
                                    canPin && 'hover:fill-primary-900'
                                )}
                            />
                        )}
                    </button>
                )
            },
        },
        {
            accessorKey: 'name',
            header: 'Project',
            cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-2 items-center">
                        {row.original.projectIcon ? (
                            <img
                                src={row.original.projectIcon}
                                alt={t('imgAlt.projectIcon')}
                                className="w-6 h-6  rounded-md object-contain"
                            />
                        ) : (
                            <DefaultProjectIcon className="w-6 h-6 rounded-md " />
                        )}
                        <p className="text-primary-900 text-base text-ellipsis max-w-32">
                            {row.original.name}
                        </p>
                    </div>
                )
            },
        },
        {
            accessorKey: 'updatedAt',
            header: 'Modified',
            cell: ({ row }) => {
                return <p>{new Date(row.original.updatedAt).toLocaleString()}</p>
            },
        },
        {
            accessorKey: 'createdAt',
            header: 'Created',
            cell: ({ row }) => {
                return <p>{new Date(row.original.updatedAt).toLocaleString()}</p>
            },
        },
        {
            accessorKey: 'pipelines',
            header: 'Pipeline',
            cell: ({ row }) => {
                const length = row.original.pipelines.length
                if (length !== 0) {
                    return (
                        <div className="flex flex-row gap-1 text-primary-900">
                            <p>
                                {row.original?.pipelines[0]?.name}
                                {length > 1 && ','}
                            </p>
                            {length > 1 && <p>+{length}</p>}
                        </div>
                    )
                }
            },
            enableSorting: false,
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only fixed">{t('open_menu')}</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="p-2 min-w-40 rounded-lg" align="end">
                            <DropdownMenuItem className="p-2 text-gray-550 focus:text-gray-550 focus:bg-primary-muted">
                                <Link
                                    to="/projects/$projectId/edit"
                                    params={{
                                        projectId: row.original.id,
                                    }}
                                    className="flex gap-1"
                                >
                                    <Pencil className="h-4 w-4" />
                                    {'Edit'}
                                </Link>
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                className="flex gap-1 text-error focus:text-error p-2 focus:bg-primary-muted"
                                onClick={async () => {
                                    try {
                                        await handleDeleteProject(row.original.id)
                                    } catch (error) {
                                        HandleDeleteError(error, 'delete project')
                                    }
                                }}
                            >
                                <Trash className="h-4 w-4" />
                                {'Delete'}
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy: field,
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState, router.history.location])

    return (
        <div className="flex flex-col gap-2 pr-2 m-2xl">
            <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center">
                    <PaginationHeader
                        queryParams={queryParams}
                        filter={searchValue}
                        setFilter={setSearchValue}
                    />
                </div>
                <CreateNewProject
                    variant={'default'}
                    size="default"
                    onSuccess={() => {
                        queryClient.invalidateQueries({
                            queryKey: [QueryKeys.PROJECTS],
                        })
                        navigate({ to: '/projects' })
                    }}
                />
            </div>
            <DefaultDataTable
                data={GetAllProjects.data?.items ?? []}
                columns={columns}
                onRowClick={(row) => {
                    updateRecentProjects(row.original.id)
                    navigate({ to: '/$projectId/', params: { projectId: row.original.id } })
                }}
                serverPagination
                totalCount={GetAllProjects.data?.totalCount ?? 0}
                rowsPerPage={queryParams.pageSize}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
            />
            <Outlet />
        </div>
    )
}
