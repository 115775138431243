import { getActiveOrganization } from '@/api/workspaces'
import Container from '@/assets/icons/container.svg?react'
import { QueryKeys } from '@/constants/QueryKeys'
import { useQuery } from '@tanstack/react-query'
import { Outlet, type RoutePaths, createFileRoute, useNavigate } from '@tanstack/react-router'

import { useMemo } from 'react'

import { FeatureFlag, getFeatureFlag } from '@/api/featureFlag'

import { Dialog, DialogContent } from '@/components/ui/dialog'
import { SideNavItem } from '@/components/sidenav/SideNavItem'
import type { routeTree } from '@/routeTree.gen'
import { Separator } from '@/components/ui/separator'

export const Route = createFileRoute('/_mainLayout/settings')({
    component: () => {
        const navigate = useNavigate()
        return (
            <Dialog key={'settings'} modal open={true} onOpenChange={() => navigate({ to: '/' })}>
                <DialogContent className="max-w-[calc(100vw-56px)] max-h-[calc(100vh-56px)] p-0 m-0">
                    <Settings />
                </DialogContent>
            </Dialog>
        )
    },
})

function Settings() {
    const activeOrganizationQuery = useQuery({
        queryKey: [QueryKeys.ACTIVE_ORGANIZATION],
        queryFn: getActiveOrganization,
    })

    const featureFlagQuery = useQuery({
        queryKey: [FeatureFlag.EnableSsoConfigurationFeatureFlag],
        queryFn: () => getFeatureFlag(FeatureFlag.EnableSsoConfigurationFeatureFlag),
    })

    const otherConfigs = [
        {
            value: 'groups',
            label: 'Groups',
            to: '/settings/groups',
        },
        {
            value: 'users',
            label: 'Users',
            to: '/settings/users',
        },
    ]

    const tabConfigs = useMemo(() => {
        let baseConfigs = [
            {
                value: 'billing',
                label: 'Billing',
                to: '/settings/billing',
            },
            {
                value: 'gateway',
                label: 'AI Gateway',
                to: '/settings/aigateway',
            },
            {
                value: 'apikeys',
                label: 'API Keys',
                to: '/settings/apikeys',
            },
            {
                value: 'credentials',
                label: 'Credentials',
                to: '/settings/credentials',
            },
            {
                value: 'siem',
                label: 'SIEM',
                to: '/settings/siem',
            },
        ]

        if (featureFlagQuery.data) {
            const ssoConfig = {
                value: 'sso',
                icon: <Container className="w-6 h-6 mr-2 -ml-1" />,
                label: 'SSO',
                to: '/settings/sso',
            }
            baseConfigs = [baseConfigs[0], ssoConfig, ...baseConfigs.slice(1)]
        }
        if (
            activeOrganizationQuery.isSuccess &&
            activeOrganizationQuery.data.createTenantsEnabled
        ) {
            baseConfigs.push({
                value: 'workspaces',
                label: 'Workspaces',
                to: '/settings/workspaces',
            })
        }

        return baseConfigs
    }, [
        activeOrganizationQuery.isSuccess,
        activeOrganizationQuery.data?.createTenantsEnabled,
        featureFlagQuery.data,
    ])

    return (
        <div className="grid grid-cols-5 h-[calc(100vh-56px)]">
            <div className="col-span-1 flex flex-col gap-1 bg-background-sidenav h-[calc(100vh-58px)] py-10 px-4 rounded-tl-2xl rounded-bl-2xl">
                <div className="text-lg font-medium mb-4 px-smd">Settings</div>
                {tabConfigs
                    .sort((a, b) => {
                        if (a.label < b.label) {
                            return -1
                        }
                        if (a.label > b.label) {
                            return 1
                        }
                        return 0
                    })
                    .map((config) => (
                        <SideNavItem
                            key={config.label}
                            path={config.to as RoutePaths<typeof routeTree>}
                            title={config.label}
                        />
                    ))}
                <Separator className="my-2" />
                {otherConfigs.map((config) => (
                    <SideNavItem
                        key={config.label}
                        path={config.to as RoutePaths<typeof routeTree>}
                        title={config.label}
                    />
                ))}
            </div>
            <div className=" bg-white col-span-4 rounded-br-2xl rounded-tr-2xl my-10 p-4 overflow-y-auto">
                <Outlet />
            </div>
        </div>
    )
}
