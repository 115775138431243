import { DataSourceFileOverview } from '@/api/datasets'
import Upload from '@/assets/icons/upload.svg?react'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { AlertCircle, CheckCircle, Loader2, X } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type ConflictingFile = {
    file: File
    existingFileId: string
    status: 'pending' | 'skip' | 'update' | 'addBoth'
}

type DragAndDropProps = {
    files: File[]
    setFiles: React.Dispatch<React.SetStateAction<File[]>>
    handleUpload: (file: File, action?: 'update' | 'addBoth') => Promise<void>
    onUploadComplete: () => void
    existingFiles: DataSourceFileOverview[]
}

const DragAndDropLegal: React.FC<DragAndDropProps> = ({
    handleUpload,
    files,
    setFiles,
    onUploadComplete,
    existingFiles,
}) => {
    const [conflictingFiles, setConflictingFiles] = useState<ConflictingFile[]>([])
    const [uploadingFiles, setUploadingFiles] = useState<Set<string>>(new Set())
    const [uploadedFiles, setUploadedFiles] = useState<Set<string>>(new Set())

    const { toast } = useToast()
    const { t } = useTranslation()

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files
        if (selectedFiles) {
            const newFiles = Array.from(selectedFiles)
            processNewFiles(newFiles)
        }
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        const droppedFiles = event.dataTransfer.files
        if (droppedFiles.length > 0) {
            const newFiles = Array.from(droppedFiles)
            processNewFiles(newFiles)
        }
    }

    const processNewFiles = (newFiles: File[]) => {
        const updatedConflictingFiles: ConflictingFile[] = []
        const updatedFiles: File[] = []

        newFiles.forEach((file) => {
            const existingFile = existingFiles.find((ef) => ef.name === file.name)
            if (existingFile) {
                updatedConflictingFiles.push({
                    file,
                    existingFileId: existingFile.fileId ?? '',
                    status: 'pending',
                })
            } else {
                updatedFiles.push(file)
            }
        })

        setFiles((prevFiles) => [...prevFiles, ...updatedFiles])
        setConflictingFiles((prevConflicts) => [...prevConflicts, ...updatedConflictingFiles])
    }

    const handleRemoveFile = (fileName: string) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName))
    }

    const handleRemoveConflictingFile = (fileName: string) => {
        setConflictingFiles((prevConflicts) =>
            prevConflicts.filter((conflict) => conflict.file.name !== fileName)
        )
    }

    const handleConflictResolution = (fileName: string, action: 'skip' | 'update' | 'addBoth') => {
        setConflictingFiles((prevConflicts) =>
            prevConflicts.map((conflict) =>
                conflict.file.name === fileName ? { ...conflict, status: action } : conflict
            )
        )
    }

    const uploadFile = async (file: File, action?: 'update' | 'addBoth') => {
        setUploadingFiles((prev) => new Set(prev).add(file.name))
        try {
            await handleUpload(file, action)
            setFiles((prevFiles) => prevFiles.filter((f) => f.name !== file.name))
            setConflictingFiles((prevConflicts) =>
                prevConflicts.filter((cf) => cf.file.name !== file.name)
            )
            setUploadedFiles((prev) => new Set(prev).add(file.name))
        } finally {
            setUploadingFiles((prev) => {
                const updated = new Set(prev)
                updated.delete(file.name)
                return updated
            })
        }
    }

    const hasUnresolvedConflicts = () => {
        return conflictingFiles.some((conflict) => conflict.status === 'pending')
    }

    const onClickUpload = async () => {
        if (hasUnresolvedConflicts()) {
            toast({
                title: 'Error',
                description: 'Please resolve all file conflicts before uploading',
                variant: 'destructive',
            })
            return
        }
        const uploadPromises = [
            ...files.map((file) => uploadFile(file)),
            ...conflictingFiles
                .filter((conflict) => conflict.status === 'update' || conflict.status === 'addBoth')
                .map((conflict) =>
                    uploadFile(conflict.file, conflict.status === 'update' ? 'update' : 'addBoth')
                ),
        ]

        await Promise.all(uploadPromises)
        toast({
            title: t('success_title'),
            description: t('file_upload_success'),
        })
        onUploadComplete()
    }
    const truncateFileName = (fileName: string, maxLength: number = 35) => {
        if (fileName.length <= maxLength) return fileName
        const extension = fileName.split('.').pop()
        const nameWithoutExtension = fileName.slice(0, fileName.lastIndexOf('.'))
        const truncatedName = nameWithoutExtension.slice(
            0,
            maxLength - 3 - (extension?.length || 0)
        )
        return `${truncatedName}...${extension ? `.${extension}` : ''}`
    }

    return (
        <div className="flex flex-col gap-3 h-full">
            <section className="rounded-md h-full p-4">
                <div
                    className="h-full border-[3px] border-dashed border-[#EAEAEA] rounded-lg p-4"
                    onDrop={handleDrop}
                    onDragOver={(event) => event.preventDefault()}
                >
                    <div className="flex flex-col justify-center items-center h-full">
                        <Upload className="w-12 h-12 text-primary-legal mb-5" />
                        <p className="text-[15px] leading-5 font-medium">
                            Drag your playbook files
                        </p>
                        <p className="text-[15px] leading-5 font-medium">here to upload or</p>
                        <input
                            type="file"
                            hidden
                            id="browse"
                            onChange={handleFileChange}
                            accept=""
                            multiple
                        />
                        <label
                            htmlFor="browse"
                            className="cursor-pointer text-[15px] font-bold leading-5 text-primary-legal transition-colors"
                        >
                            Browse files
                        </label>
                        {(files.length > 0 || conflictingFiles.length > 0) && (
                            <div className="mt-8 w-full">
                                <h3 className="text-lg font-semibold mb-2">Selected Files:</h3>
                                <div className="flex flex-col flex-wrap gap-2">
                                    {files.map((file) => (
                                        <Badge
                                            key={file.name}
                                            variant="outline"
                                            className="file-item flex flex-row gap-2 items-center cursor-default border-primary-legal rounded-full m-1"
                                        >
                                            {uploadingFiles.has(file.name) ? (
                                                <Loader2 className="w-4 h-4 animate-spin" />
                                            ) : uploadedFiles.has(file.name) ? (
                                                <CheckCircle className="w-4 h-4 text-green-500" />
                                            ) : null}
                                            <div className="text-xs" title={file.name}>
                                                {truncateFileName(file.name)}
                                            </div>
                                            <X
                                                className="cursor-pointer hover:text-destructive w-4 h-4"
                                                onClick={() => handleRemoveFile(file.name)}
                                            />
                                        </Badge>
                                    ))}
                                    {conflictingFiles.map((conflict) => (
                                        <div key={conflict.file.name} className="flex items-center">
                                            <Badge
                                                variant="outline"
                                                className="file-item flex flex-row gap-2 items-center cursor-default border-primary rounded-full m-1"
                                            >
                                                {conflict.status === 'pending' && (
                                                    <AlertCircle className="text-yellow-500 w-4 h-4" />
                                                )}
                                                {uploadingFiles.has(conflict.file.name) ? (
                                                    <Loader2 className="w-4 h-4 animate-spin" />
                                                ) : uploadedFiles.has(conflict.file.name) ? (
                                                    <CheckCircle className="w-4 h-4 text-green-500" />
                                                ) : conflict.status !== 'pending' ? (
                                                    <Badge className="text-xs" variant="default">
                                                        {conflict.status}
                                                    </Badge>
                                                ) : null}
                                                <div className="text-xs" title={conflict.file.name}>
                                                    {truncateFileName(conflict.file.name)}
                                                </div>
                                                <X
                                                    className="cursor-pointer hover:text-destructive w-4 h-4"
                                                    onClick={() =>
                                                        handleRemoveConflictingFile(
                                                            conflict.file.name
                                                        )
                                                    }
                                                />
                                            </Badge>
                                            {conflict.status === 'pending' &&
                                                !uploadingFiles.has(conflict.file.name) &&
                                                !uploadedFiles.has(conflict.file.name) && (
                                                    <div className="flex gap-1 ml-2">
                                                        <Button
                                                            size="sm"
                                                            onClick={() =>
                                                                handleConflictResolution(
                                                                    conflict.file.name,
                                                                    'skip'
                                                                )
                                                            }
                                                        >
                                                            Skip
                                                        </Button>
                                                        <Button
                                                            size="sm"
                                                            onClick={() =>
                                                                handleConflictResolution(
                                                                    conflict.file.name,
                                                                    'update'
                                                                )
                                                            }
                                                        >
                                                            Update
                                                        </Button>
                                                        <Button
                                                            size="sm"
                                                            onClick={() =>
                                                                handleConflictResolution(
                                                                    conflict.file.name,
                                                                    'addBoth'
                                                                )
                                                            }
                                                        >
                                                            Add Both
                                                        </Button>
                                                    </div>
                                                )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <div className="flex flex-row items-center justify-end">
                <div className="flex flex-row gap-2">
                    <Button
                        type="button"
                        onClick={() => onUploadComplete()}
                        className="bg-[#ECE8FF] text-primary-legal hover:bg-[#ECE8FFAA] hover:text-primary-legal"
                    >
                        Cancel
                    </Button>
                    <Button
                        className="text-white bg-primary-legal hover:bg-[#5C33FFDD]"
                        onClick={onClickUpload}
                        disabled={
                            files.length === 0 &&
                            conflictingFiles.every((cf) => cf.status === 'skip')
                        }
                    >
                        {uploadingFiles.size > 0 ? (
                            <>
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                Uploading...
                            </>
                        ) : (
                            'Upload'
                        )}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default DragAndDropLegal
