import ChatLogo from '@/assets/system_chat.png'
import { useAuth } from '@/hooks/use-auth'
import { CircleUser } from 'lucide-react'
import { preprocessMessage } from '@/components/chat/preprocessMessage'
import { MessageType } from '@/api/conversation'

export const HelpCenterChatMessage = (props: MessageType) => {
    const { user: loggedInUser } = useAuth()

    const { role, message, createdAt } = props
    return (
        <div className="flex flex-row p-2 gap-4" key={props.id}>
            <div className="flex flex-col w-[30px]">
                {role == 'user' && <CircleUser className="w-full" />}
                {role == 'assistant' && <img src={ChatLogo} className="w-full p-1" />}
            </div>
            <div className="flex flex-col justify-start w-[300px]">
                <p className="font-semibold">
                    {role == 'assistant' ? 'Airia Support' : loggedInUser?.firstName}
                </p>
                {preprocessMessage(message ?? '', role ?? 'user')}
                <p className="text-sm text-gray-300">{createdAt}</p>
            </div>
        </div>
    )
}
