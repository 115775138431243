import apiRequest from '@/lib/apiClient'
export type PipelineArrayRes = {
    stepId: string
    stepType: string
    output: string
}

export type DebugReport = {
    [key: string]: {
        stepId: string
        input: string
        result: string | null
        stepType: StepType
        success: boolean
        timeTrackingData: {
            duration: string
            finishedAt: string
            startedAt: string
        }
        exceptionMessage: string
        debugInformation: {
            [key: string]: unknown
        }
    }
}
export type PipelineRes = {
    result: string | PipelineArrayRes[] | null
    report?: DebugReport
}

import { Pipeline, StepType } from './pipelinesConfig'

export type PipelineRequest = {
    userInput: string
    debug: boolean
    asyncOutput?: boolean
    images?: string[]
    conversationId?: string
}

export type InMemoryMessage = {
    role: string
    message: string
}

/*
 * This is to store the response of the temporary pipeline execution
 * stream: boolean - whether the pipeline is streaming or not
 * executionId: string? - the id of the execution (Only if the pipeline is streaming)
 * report: string? - the result of the execution (For non-streaming pipelines)
 */
export type PipelineExecutionResult = {
    result?: string | PipelineArrayRes[]
    stream: boolean
    executionId?: string
    report?: DebugReport
    isBackupPipeline: boolean
}

export type TemporaryPipelineRequest = {
    pipeline: Pipeline
    userInput: string
    conversationId: string
    userId?: string
    saveHistory?: boolean
    asyncOutput: boolean
    temporaryPrompt?: string
    debug: boolean
    images?: string[]
    inMemoryMessages?: InMemoryMessage[]
    additionalInfo?: Record<string, string>[]
    promptVariables?: Record<string, string>
}

export type PipelineExecutionStatus = {
    id: string
    executionId: string
    currentState: string
    pipelineResult: string
    createdAt: string
    updatedAt: string
}

export type PipelineExecutionStatusList = {
    items: PipelineExecutionStatus[]
    totalCount: number
}

export type PipelineList = {
    items: Pipeline[]
    totalCount: number
}

export const runPipeline = async (
    request: PipelineRequest,
    id: string
): Promise<PipelineExecutionResult> => {
    try {
        const response = await apiRequest<PipelineExecutionResult>(`/v1/PipelineExecution/${id}`, {
            method: 'POST',
            body: request,
        })
        return response
    } catch (error: any) {
        if (error.status === 402) {
            // User does not have enough credits to run the pipeline
            throw new Error('Insufficient credits.')
        }
        // Re-throw other errors
        throw error
    }
}

export const runTemporaryPipeline = async (
    request: TemporaryPipelineRequest
): Promise<PipelineExecutionResult> => {
    try {
        const response = await apiRequest<PipelineExecutionResult>(
            '/v1/PipelineExecution/TemporaryPipeline',
            {
                method: 'POST',
                body: request,
            }
        )
        return response
    } catch (error: any) {
        if (error.status === 402) {
            // User does not have enough credits to run the pipeline
            throw new Error('Insufficient credits.')
        }
        // Re-throw other errors
        throw error
    }
}

export const getPipelineExecutionLogs = async (id: string): Promise<string> => {
    return apiRequest(`/v1/PipelineExecutionLogs?ExecutionId=${id}`)
}

export const getPipelineExecutionLogsCount = async (range: string): Promise<any> => {
    return apiRequest(`/v1/PipelineExecutionMetrics/count?range=${range}`)
}

export const getPipelineExecutionLogsCountByDateRange = async (
    startDate: string,
    endDate: string
): Promise<any> => {
    return apiRequest(
        `/v1/PipelineExecutionMetrics/count-range?startDate=${startDate}&endDate=${endDate}`
    )
}

export const getPipelineExecutionErrorRate = async (
    startDate: string,
    endDate: string
): Promise<any> => {
    return apiRequest(
        `/v1/PipelineExecutionMetrics/error-rate?startDate=${startDate}&endDate=${endDate}`
    )
}

export const getPipelineExecutionLatency = async (
    startDate: string,
    endDate: string
): Promise<any> => {
    return apiRequest(
        `/v1/PipelineExecutionMetrics/latency?startDate=${startDate}&endDate=${endDate}`
    )
}

export const getPipelineExecutionModelUsage = async (
    startDate: string,
    endDate: string
): Promise<any> => {
    return apiRequest(
        `/v1/PipelineExecutionMetrics/model/usage?startDate=${startDate}&endDate=${endDate}`
    )
}

export const getPipelineExecutionCountByModel = async (
    startDate: string,
    endDate: string
): Promise<any> => {
    return apiRequest(
        `/v1/PipelineExecutionMetrics/model/count?startDate=${startDate}&endDate=${endDate}`
    )
}

export const getAllPipelineExecutionStatusObjects = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<PipelineExecutionStatusList> => {
    return apiRequest(`/v1/PipelineExecutionMetrics/execution-status`, {
        queryParams,
    })
}

export const getFeed = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<PipelineExecutionStatusList> => {
    return apiRequest(`/v1/Feed`, { queryParams })
}

export const GetPipelineUsingGivenTool = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<PipelineList> => {
    return apiRequest('/v1/PipelineExecution', { queryParams })
}
