import { Button } from '@/components/ui/button.tsx'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { MessageCircleWarning, X } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from '@/lib/i18n'
import { UserFeedback } from './userFeedback'

export const Feedback = () => {
    const { t } = useTranslation()

    const [dropDownOpen, setDropDownOpen] = useState(false)

    return (
        <DropdownMenu open={dropDownOpen} onOpenChange={setDropDownOpen}>
            <DropdownMenuTrigger asChild>
                <Button variant={'ghost'} size={'icon'}>
                    <MessageCircleWarning className={dropDownOpen ? 'text-primary' : ''} />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align={'end'} className={'w-[411px] bg-background p-0'}>
                <div className="flex justify-center gap-4 pt-4">
                    <Button
                        className="absolute top-2 left-2 text-black"
                        variant={'textOnly'}
                        onClick={() => setDropDownOpen(false)}
                    >
                        <X className="w-4"></X>
                    </Button>
                    <h1 className="font-bold text-sm">{t('help_center.feedback')}</h1>
                </div>
                <hr className="mt-2 mb-2" />
                <UserFeedback onClose={() => setDropDownOpen(false)}></UserFeedback>
                <div className="px-4 pb-4 pt-2 flex justify-center gap-2">
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-600">
                            {t('help_center.need_help')}
                            &nbsp;
                            <a
                                href="https://airia.com/explore"
                                target="_blank"
                                className="text-blue-500"
                            >
                                {t('help_center.help_center')}
                            </a>
                        </p>
                    </div>
                </div>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
