import { PipelineExecutionResult } from './pipelineExecution'
import apiRequest from '@/lib/apiClient.ts'
import { WebSocketUrlResponse } from '@/api/streamSocketConfig.ts'

const endpoint = 'HelpCenter'
export const sendHelpCenterChatMessage = async (message: string, conversationId: string) => {
    return await apiRequest<PipelineExecutionResult>('/v1/' + endpoint + '/', {
        method: 'POST',
        body: {
            userInput: message,
            debug: false,
            asyncOutput: true,
            conversationId,
        },
    })
}

export const getHelpCenterWebSocketUrl = async (
    socketIdentifier: string
): Promise<WebSocketUrlResponse> => {
    return apiRequest('/v1/' + endpoint + '/GenerateUrl', {
        method: 'POST',
        body: { SocketIdentifier: socketIdentifier },
    })
}
