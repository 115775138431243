import { GetModelById } from '@/api/models.ts'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_mainLayout/$projectId/_projectLayout/models/$modelId/edit')(
    {
        loader: ({ params: { modelId } }) => {
            return GetModelById(modelId)
        },
    }
)
