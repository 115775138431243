import { GetDatasetById, getDataSourceForms } from '@/api/datasets'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
    '/_mainLayout/$projectId/_projectLayout/datasets/$datasetId/edit'
)({
    loader: async ({ params }) => {
        const Dataset = await GetDatasetById(params.datasetId)
        const Forms = await getDataSourceForms()

        return { Dataset, Forms }
    },
})
