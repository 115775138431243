import { createFileRoute, type SearchSchemaInput } from '@tanstack/react-router'
import type { paginationAndSortingParams } from '@/components/pagination/paginationHeader.tsx'
import { ExecutionFeedTable } from '@/components/home/feed/ExecutionFeedTable.tsx'

export const Route = createFileRoute('/_mainLayout/feed/execution')({
    component: Feed,
    // //@ts-ignore
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            projectId: search.projectId as string,
            pageNumber: search.pageNumber as number,
            pageSize: search.pageSize as number,
            sortBy: search.sortBy as string,
        }
    },
})

function Feed() {
    const queryParams = Route.useSearch()
    const params = Route.useParams()
    return <ExecutionFeedTable queryParams={queryParams} params={params} />
}
