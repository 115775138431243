import { getPromptById } from '@/api/prompts'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
    '/_mainLayout/$projectId/_projectLayout/prompts/$promptId/edit/$versionNumber'
)({
    loader: async ({ params: { promptId } }) => {
        const data = await getPromptById(promptId)
        data.versions.sort((a, b) => a.versionNumber - b.versionNumber)
        return data
    },
})
