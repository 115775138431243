import apiRequest from '@/lib/apiClient'

export type Credential = {
    id: string
    projectId?: string
    name: string
    createdAt: string
    type: CredentialType
    credentialData: { key: string; value: string }[] // { key: string; value: string }[]
}

export type CredentialList = {
    items: Credential[]
    totalCount: number
}

export enum CredentialType {
    OPENAI = 'OpenAI',
    OLLAMA = 'Ollama',
    AZURE = 'Azure',
    HUGGING_FACE = 'HuggingFace',
    ANTHROPIC = 'Anthropic',
    AZURE_AI_STUDIO = 'AzureAIStudio',
    OPENVINO = 'OpenVINO',
    PYTORCH = 'PyTorch',
    AZURE_BLOB = 'AzureBlob',
    S3 = 'S3',
    SCALE_FLEX = 'ScaleFlex',
    REPLICATE = 'Replicate',
    STABILITY = 'Stability',
    MISTRAL = 'Mistral',
    PERPLEXITY = 'Perplexity',
    WatsonAix = 'WatsonAix',
    Ai21 = 'Ai21',
    Cohere = 'Cohere',
    Google = 'Google',
}

export const MODEL_PROVIDERS = [
    'OpenAI',
    'Azure',
    'Ollama',
    'HuggingFace',
    'Anthropic',
    'AzureAIStudio',
    'OpenVINO',
    'PyTorch',
    'Replicate',
    'Stability',
    'Mistral',
    'Perplexity',
    'WatsonAix',
    'Ai21',
    'Cohere',
    'Google',
]

export const getAllCredentials = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<CredentialList> => {
    return apiRequest('/v1/Credentials', { queryParams })
}

export const getCredentialById = async (id: string): Promise<Credential> => {
    return apiRequest(`/v1/Credentials/${id}`)
}

export const createCredential = async (credential: Credential): Promise<Credential> => {
    return apiRequest('/v1/Credentials', {
        method: 'POST',
        body: { ...credential, credentialData: JSON.stringify(credential.credentialData) },
    })
}

export const updateCredential = async (credential: Credential): Promise<void> => {
    return apiRequest(`/v1/Credentials/${credential.id}`, {
        method: 'PUT',
        body: { ...credential, credentialData: JSON.stringify(credential.credentialData) },
    })
}

export const deleteCredentials = async (id: string): Promise<void> => {
    return apiRequest(`/v1/Credentials/${id}`, { method: 'DELETE' })
}
