import { UseNavigateResult } from '@tanstack/react-router'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

interface searchEffectParams {
    debouncedSearchValue: string
    navigate: UseNavigateResult<string>
    queryParams: Record<string, unknown>
    params?: Record<string, unknown>
    onPaginationChange: Dispatch<
        SetStateAction<{
            pageSize: number
            pageIndex: number
        }>
    >
}

function useSearchEffect({
    debouncedSearchValue,
    navigate,
    queryParams,
    params,
    onPaginationChange,
}: searchEffectParams) {
    const [filter, setFilter] = useState(queryParams.filter)

    useEffect(() => {
        if (debouncedSearchValue) {
            navigate({
                search: !queryParams.pageNumber
                    ? {
                          ...queryParams,
                          pageSize: 25,
                          pageNumber: 1,
                          projectId: params?.projectId ?? undefined,
                          filter: debouncedSearchValue,
                      }
                    : { ...queryParams, filter: debouncedSearchValue, pageNumber: 1 },
            })
            debouncedSearchValue !== filter &&
                onPaginationChange((prev) => ({ ...prev, pageIndex: 0 }))
        } else {
            navigate({ search: { ...queryParams, filter: undefined } })
        }
        setFilter(queryParams.filter)
    }, [debouncedSearchValue, navigate, queryParams, params, onPaginationChange, setFilter])
}

export default useSearchEffect
