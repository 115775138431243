import { WorkspaceSelector } from '@/components/workspaceSelector/WorkspaceSelector.tsx'
import { ProfileDropdown } from './ProfileDropdown'
import { HelpCenter } from './helpCenter'
import FileQuestion from '@/assets/icons/file-question.svg?react'
import { Button } from '@/components/ui/button.tsx'
import { Feedback } from './feedback'
import NotificationBanner from '@/components/banner/notificationBanner.tsx'
import useTenantQuery from '@/hooks/use-tenant-query.ts'
import { useEffect, useState } from 'react'
import { useRouter } from '@tanstack/react-router'
import { LOW_BALANCE_THRESHOLD } from '@/components/billing/accountSummary.tsx'

export const TopNav = () => {
    const { isLoading, details } = useTenantQuery()
    const router = useRouter()
    const [lowBalance, setLowBalance] = useState<boolean>(false)

    useEffect(() => {
        if (!isLoading && details?.balance !== undefined) {
            setLowBalance(details.balance <= LOW_BALANCE_THRESHOLD)
        }
    }, [isLoading, details?.balance])

    const onAddFunds = () => {
        router.navigate({
            to: '/settings/billing',
            hash: 'addFunds',
        })
    }

    return (
        <>
            <div className="h-16 flex items-center px-5 border-b shadow-sm finish-step">
                <div className={'flex w-full justify-end items-center gap-3'}>
                    <WorkspaceSelector />
                    <HelpCenter />
                    <Feedback />
                    <a href="https://airia.com/explore" target="_blank" rel="noreferrer">
                        <Button variant="ghost" size="icon">
                            <FileQuestion className="w-6 h-6" />
                        </Button>
                    </a>
                    <ProfileDropdown />
                </div>
            </div>
            {lowBalance && (
                <NotificationBanner
                    variant={'warning'}
                    message={
                        "Warning: Your balance is running low. if you're account balance is less than $1.00, executions will be paused."
                    }
                    linkText={'Add funds now.'}
                    onLinkClick={() => onAddFunds()}
                />
            )}
        </>
    )
}
