import { getAllLibraryModels } from '@/api/library'
import { getProjectById } from '@/api/projects'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_no_nav_layout/$projectId/library/models/')({
    loader: async ({ params }) => {
        const Project = await getProjectById(params.projectId)
        const LibraryModels = await getAllLibraryModels(true)
        return { Project, LibraryModels }
    },
})
