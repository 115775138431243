import { cn, isJSON, isMarkdown } from '@/lib/utils.ts'
import { CodeBlock } from 'react-code-block'
import { themes } from 'prism-react-renderer'
import Markdown from 'react-markdown'
import { customComponents } from '@/lib/MarkdownStyles.tsx'

export const preprocessMessage = (message: string, role: string) => {
    if (message && isJSON(message) && role !== 'user') {
        // add codeblock component for JSON
        return (
            <CodeBlock language="js" code={message} theme={themes.duotoneLight}>
                <CodeBlock.Code
                    as="div"
                    className="bg-gray-100 p-6 rounded-xl shadow-lg text-wrap whitespace-pre-wrap break-all"
                >
                    <div className="table-row overflow-auto">
                        <CodeBlock.LineNumber className="table-cell pr-4 w-12 text-xs text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell text-sm">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                        <p> {'\n'} </p>
                    </div>
                </CodeBlock.Code>
            </CodeBlock>
        )
    } else if (message && isMarkdown(message) && role !== 'user') {
        if (message === '```\\n' || message === '```') {
            return (
                <Markdown
                    components={{
                        ...customComponents,
                        code: (props) => <div>{props.children} </div>,
                    }}
                >
                    {message}
                </Markdown>
            )
        }
        return <Markdown components={customComponents}>{message}</Markdown>
    } else {
        //make links clickable
        return message?.split(/(https?:\/\/\S+\w)/g).map((item) => {
            let breakWords = false

            if (item.indexOf(' ') < 0) {
                breakWords = true
            }

            if (item.startsWith('http://') || item.startsWith('https://')) {
                return (
                    <a
                        href={item}
                        target={'_blank'}
                        className={cn(
                            breakWords
                                ? 'underline text-primary break-all'
                                : 'underline text-primary'
                        )}
                    >
                        {item}
                    </a>
                )
            } else {
                return breakWords ? <div className="break-all"> {item} </div> : item
            }
        })
    }
}
