import ErrorHappened from '@/components/ErrorComponent'
import NotFoundComponent from '@/components/NotFoundComponent'
import { AuthProvider, useAuth } from '@/hooks/use-auth'
import { useOnboarding } from '@/hooks/use-onboarding'
import { isApiError, isNotFound } from '@/lib/HandleErrors'
import { Outlet, createRootRoute } from '@tanstack/react-router'
import { LoaderCircle } from 'lucide-react'

export const Route = createRootRoute({
    component: Root,
    notFoundComponent: () => {
        return <NotFoundComponent />
    },
    errorComponent: ({ error }: { error: unknown }) => {
        if (isApiError(error)) {
            if (error.message == '401' || error.status == 401) {
                window.location.href = `/auth/logon?ReturnUrl=${encodeURIComponent(window.location.href)}`
                return <></>
            }
        }
        if (isNotFound(error)) {
            return <NotFoundComponent />
        }
        //@ts-ignore
        if (error.message.includes('Failed to fetch dynamically imported module')) {
            const currentPath = window.location.pathname + window.location.search
            window.location.href = currentPath
            return (
                <div className="flex items-center justify-center h-screen w-screen">
                    <LoaderCircle className="animate animate-spin w-15 h-15" />
                </div>
            )
        }
        return <ErrorHappened />
    },
})

export function Root() {
    useOnboarding()

    return (
        <AuthProvider>
            <AuthenticatedRoot />
        </AuthProvider>
    )
}

function AuthenticatedRoot() {
    const { isLoading } = useAuth()

    return <>{!isLoading && <Outlet />}</>
}
