import { Group, getAllGroups } from '@/api/groups'
import { User, getUserById, updateUser } from '@/api/users'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '@/components/ui/dialog'
import { Label } from '@/components/ui/label'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { Skeleton } from '@/components/ui/skeleton'
import { toast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { useAuth } from '@/hooks/use-auth'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { t } from 'i18next'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

export const Route = createFileRoute('/_legalLayout/$projectId/legal/users/$userId/edit')({
    component: () => {
        const navigate = useNavigate()
        const params = Route.useParams()

        return (
            <Dialog
                open
                onOpenChange={() =>
                    navigate({
                        to: '/$projectId/legal/users',
                        params: { projectId: params.projectId },
                    })
                }
            >
                <DialogContent className="max-w-modal-sm max-h-modal rounded-2xl">
                    <DialogHeader className="text-2xl font-gilroy-medium font-normal leading-10 h-[40px] p-0 m-0">
                        Edit User
                    </DialogHeader>
                    <EditUser />
                </DialogContent>
            </Dialog>
        )
    },
})

function EditUser() {
    const params = Route.useParams()
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const { checkAndRedirect, isLegalAdmin, user } = useAuth()

    const userQuery = useQuery({
        queryKey: [QueryKeys.CURRENT_USER, params.userId],
        queryFn: () => getUserById(params.userId),
    })

    const allGroupsQuery = useQuery({
        queryKey: ['all-groups'],
        queryFn: () => getAllGroups(),
    })
    console.log(userQuery.data)

    const { handleSubmit, control, setValue } = useForm({
        defaultValues: {
            group:
                userQuery.data?.groups[0]?.id ??
                allGroupsQuery?.data?.items?.find((group) => group.name === 'Legal User')?.id ??
                '2dc796a9-256a-4024-9775-4d47031ca990',
        },
    })

    const updateUserMutation = useMutation({
        mutationFn: updateUser,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_USERS] })
            queryClient.invalidateQueries({ queryKey: ['user', params.userId] })
            toast({
                title: t('success_title'),
                description: 'User updated successfully',
            })
            navigate({
                // @ts-ignore
                to: '/$projectId/legal/users',
                // @ts-ignore
                params: { projectId: params.projectId },
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const handleUpdateGroup = async ({ group }: { group: string }) => {
        const newGroup = (allGroupsQuery.data?.items.find((g) => g.id === group) as Group) ?? null
        if (userQuery.data != null) {
            const updatedUser = {
                ...userQuery.data,
                groups: [{ ...userQuery.data.groups[0], id: newGroup.id, name: newGroup.name }],
                isActive: true,
            } as User

            await updateUserMutation.mutate(updatedUser)
        }
    }

    useEffect(() => {
        if (userQuery?.data?.groups) setValue('group', userQuery.data?.groups[0]?.id)
    }, [userQuery.data])

    useEffect(() => {
        checkAndRedirect(
            isLegalAdmin && user?.identityServerUserId !== userQuery?.data?.identityServerUserId
        )
    }, [userQuery.data])

    return (
        <>
            {userQuery.isLoading ? (
                <div className="flex flex-col gap-2">
                    <Skeleton className="w-[25%] h-8" />
                    <Skeleton className="w-full h-8" />
                    <Skeleton className="w-[25%] h-8" />
                    <Skeleton className="w-full h-8" />
                </div>
            ) : (
                <form className="flex flex-col gap-6" onSubmit={handleSubmit(handleUpdateGroup)}>
                    {userQuery.data?.firstName != null && userQuery.data.lastName != null && (
                        <div className="flex flex-col gap-2">
                            <Label>User</Label>
                            <p>{userQuery.data?.firstName + ' ' + userQuery.data?.lastName}</p>
                        </div>
                    )}
                    <div className="flex flex-col gap-2">
                        <Label>Role</Label>
                        <Controller
                            control={control}
                            name={'group'}
                            rules={{ required: true }}
                            render={({ field }) => {
                                const { ref: _, ...rest } = field
                                return (
                                    <Select onValueChange={field.onChange} {...rest}>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Group" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectLabel>Roles</SelectLabel>
                                            </SelectGroup>
                                            {allGroupsQuery.data?.items.map((group) => {
                                                if (
                                                    group.name === 'Legal User' ||
                                                    group.name === 'Legal Admin'
                                                )
                                                    return (
                                                        <SelectItem key={group.id} value={group.id}>
                                                            {group.name === 'Legal User'
                                                                ? 'User'
                                                                : 'Admin'}
                                                        </SelectItem>
                                                    )
                                            })}
                                        </SelectContent>
                                    </Select>
                                )
                            }}
                        />
                    </div>
                    <DialogFooter>
                        <Button className="bg-primary-legal hover:bg-[#5C33FFDD] font-gilroy-bold leading-6 font-normal text-[13px]">
                            Save
                        </Button>
                    </DialogFooter>
                </form>
            )}
        </>
    )
}
