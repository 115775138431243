import { Memory, deleteMemory, getAllMemory } from '@/api/memory'
import { DefaultDataTable } from '@/components/DefaultDataTable'
import PaginationHeader, {
    paginationAndSortingParams,
} from '@/components/pagination/paginationHeader'
import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { usePagination } from '@/hooks/use-pagination'
import useSearchEffect from '@/hooks/use-search-effect'
import { useSorting } from '@/hooks/use-sorting'
import { HandleDeleteError } from '@/lib/HandleErrors'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import {
    Link,
    Outlet,
    SearchSchemaInput,
    createFileRoute,
    useNavigate,
    useRouter,
} from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import { useDebounce } from '@uidotdev/usehooks'
import { MoreHorizontal, Pencil, Trash } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from '@/lib/i18n'

export const Route = createFileRoute('/_mainLayout/$projectId/_projectLayout/memory')({
    component: () => {
        const params = Route.useParams()
        return <AllMemory key={`Memory-Overview-${params.projectId}`} />
    },
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            projectId: search.projectId as string,
            pageNumber: search.pageNumber as number,
            pageSize: search.pageSize as number,
            sortBy: search.sortBy as string,
        }
    },
})

function AllMemory() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const router = useRouter()
    const params = Route.useParams()
    const queryParams = Route.useSearch()
    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy,
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })

    const [searchValue, setSearchValue] = useState<string>('')
    const debouncedSearchValue = useDebounce(searchValue, 500)
    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        params,
        onPaginationChange,
    })

    const GetAllMemory = useQuery({
        queryKey: [QueryKeys.ALL_MEMORY, params.projectId, queryParams],
        queryFn: () =>
            getAllMemory(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams, projectId: params.projectId }
                    : { pageSize: 25, pageNumber: 1, projectId: params.projectId }
            ),
    })

    const queryClient = useQueryClient()
    const deleteMutation = useMutation({
        mutationFn: deleteMemory,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_MEMORY, params.projectId, queryParams],
            })
            toast({
                title: t('success_title'),
                description: t('memory_delete_success'),
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const handleRowClick = (row: { original: Memory }) => {
        navigate({
            to: '/$projectId/memory/$memoryId/edit',
            params: { memoryId: row.original.id, projectId: params.projectId },
            search: { ...queryParams },
        })
    }

    const handleDeleteMemory = async (id: string) => {
        if (window.confirm(t('memory_delete_confirmation'))) {
            try {
                await deleteMutation.mutateAsync(id)
                router.invalidate()
            } catch (error) {
                HandleDeleteError(error, 'memory')
            }
        }
    }

    const columns: ColumnDef<Memory>[] = [
        {
            accessorKey: 'name',
            header: t('name'),
        },
        {
            accessorKey: 'updatedAt',
            header: t('last_modified'),
            cell: ({ row }) => {
                return <p>{new Date(row.original.updatedAt).toLocaleString()}</p>
            },
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only fixed">{t('open_menu')}</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel className="flex gap-1 ">
                                {t('actions')}
                            </DropdownMenuLabel>
                            <DropdownMenuItem>
                                <Link
                                    to="/$projectId/memory/$memoryId/edit"
                                    params={{
                                        memoryId: row.original.id,
                                        projectId: params.projectId,
                                    }}
                                    className="flex gap-1"
                                >
                                    <Pencil className="h-4 w-4" />
                                    {t('memoryIndex.editMemory')}
                                </Link>
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                onClick={async () => {
                                    try {
                                        await handleDeleteMemory(row.original.id)
                                    } catch (error) {
                                        HandleDeleteError(error, t('editMemory.errorDescription'))
                                    }
                                }}
                                className="flex gap-1 text-red-500"
                            >
                                <Trash className="h-4 w-4" />
                                {t('memoryIndex.deleteMemory')}
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy: field,
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState, router.history.location])

    return (
        <div className="flex flex-col gap-2 pr-2">
            <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center">
                    <PaginationHeader
                        queryParams={queryParams}
                        filter={searchValue}
                        setFilter={setSearchValue}
                        sortOptions={columns}
                    />
                </div>
                <Link
                    to="/$projectId/memory/new"
                    params={{ projectId: params.projectId }}
                    search={{ ...queryParams }}
                >
                    <Button>{t('memoryIndex.newMemory')}</Button>
                </Link>
            </div>
            <DefaultDataTable
                data={GetAllMemory.data?.items ?? []}
                columns={columns}
                onRowClick={handleRowClick}
                serverPagination
                totalCount={GetAllMemory.data?.totalCount}
                rowsPerPage={queryParams.pageSize}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
            />
            <Outlet />
        </div>
    )
}
