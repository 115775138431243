import { themes } from 'prism-react-renderer'
import { CodeBlock } from 'react-code-block'
import { Components } from 'react-markdown'

export const customComponents: Partial<Components> = {
    a: (props) => <a target={'_blank'} className={'text-primary underline'} {...props} />,
    h1: (props) => <h1 className="text-4xl my-4" {...props} />,
    h2: (props) => <h2 className={'text-2xl my-4'} {...props} />,
    h3: (props) => <h3 className={'text-xl my-2 font-bold'} {...props} />,
    h4: (props) => <h4 className={'text-lg my-2'} {...props} />,
    h5: (props) => <h5 className={'text-base'} {...props} />,
    h6: (props) => <h6 className={'text-sm'} {...props} />,
    ul: (props) => <ul className={'list-disc ml-4'} {...props} />,
    ol: (props) => <ol className={'list-decimal ml-4'} {...props} />,
    code: (props) => (
        <CodeBlock language="js" code={props.children as string} theme={themes.duotoneLight}>
            <CodeBlock.Code
                as="div"
                className="bg-gray-100 p-6 rounded-xl shadow-lg text-wrap whitespace-pre-wrap break-all"
            >
                <div className="table-row overflow-auto">
                    <CodeBlock.LineNumber className="table-cell pr-4 w-12 text-xs text-gray-500 text-right select-none" />
                    <CodeBlock.LineContent className="table-cell text-sm">
                        <CodeBlock.Token />
                    </CodeBlock.LineContent>
                    <p>{'\n'}</p>
                </div>
            </CodeBlock.Code>
        </CodeBlock>
    ),
}
