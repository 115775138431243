import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { DefaultDataTable } from '@/components/DefaultDataTable'
import type { ColumnDef } from '@tanstack/react-table'
import type { paginationAndSortingParams } from '@/components/pagination/paginationHeader'
import { ProjectSelect } from '@/components/modelLibrary/selectProjectForm.tsx'
import GatewayModal from '@/components/home/feed/GatewayModal'
import { useDebounce } from '@uidotdev/usehooks'
import { usePagination } from '@/hooks/use-pagination'
import { useSorting } from '@/hooks/use-sorting'
import useSearchEffect from '@/hooks/use-search-effect'
import { useNavigate, useRouter } from '@tanstack/react-router'
import { type StepExecutionContext, getGatewayFeed, type GatewayFeedResult } from '@/api/feed'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { getAllProjects } from '@/api/projects.ts'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'
import millify from 'millify'
import { Badge } from '@/components/ui/badge.tsx'

export interface FeedTableProps {
    queryParams: paginationAndSortingParams
    params: { projectId: string }
}

export const GatewayFeedTable: React.FC<FeedTableProps> = ({ queryParams, params }) => {
    const [selectedProject, setSelectedProject] = useState<string | undefined>(undefined)
    const [filter, _setFilter] = useState<string>('')
    const [selectedCall, setSelectedCall] = useState<
        GatewayFeedResult | StepExecutionContext | null
    >(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const navigate = useNavigate()
    const router = useRouter()

    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy ?? 'createdAt',
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })

    const projectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })
    const gatewayFeedQuery = useQuery({
        queryKey: [QueryKeys.GATEWAY_FEED_ALL, selectedProject, queryParams],
        queryFn: () =>
            getGatewayFeed(
                //@ts-ignore
                selectedProject != null
                    ? queryParams.pageSize && queryParams.pageNumber
                        ? { ...queryParams, projectId: selectedProject }
                        : { pageSize: 25, pageNumber: 1, projectId: selectedProject }
                    : queryParams.pageSize && queryParams.pageNumber
                      ? { ...queryParams }
                      : { pageSize: 25, pageNumber: 1 }
            ),
    })

    const handleRowClick = (row: { original: GatewayFeedResult }) => {
        setSelectedCall(row.original)
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
        setSelectedCall(null)
    }

    const debouncedSearchValue = useDebounce(filter, 500)
    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        params,
        onPaginationChange,
    })

    const columns: ColumnDef<GatewayFeedResult>[] = [
        {
            accessorKey: 'createdAt',
            header: 'Timestamp',
            cell: ({ row }) => {
                const date = new Date(row.original.createdAt)
                return (
                    <p>{`${date.toLocaleDateString(undefined, { dateStyle: 'short' })} at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`}</p>
                )
            },
        },
        {
            header: 'Model',
            accessorKey: 'requestModel',
            cell: ({ row }) => {
                return (
                    <div className="flex gap-[3px] items-center">
                        <ProviderIcon
                            provider={row.original.providerType}
                            className="w-6 h-6 mr-1 -ml-2"
                        />
                        <p className="text-gray-550">{row.original.providerType}</p>
                    </div>
                )
            },
        },
        {
            header: 'Model Name',
            accessorKey: 'requestModel',
        },
        {
            accessorKey: 'projectId',
            header: 'Project',
            cell: ({ row }) => {
                const project = projectsQuery.data?.find(
                    (project) => project.id === row.original.projectId
                )
                return (
                    <div className={'flex gap-1 items-center'}>
                        {project?.projectIcon ? (
                            <img
                                src={project.projectIcon}
                                alt={project.name}
                                height={17}
                                width={17}
                            />
                        ) : (
                            <AiriaIcon className="w-[17px] h-[17px]" />
                        )}
                        <span
                            className={
                                ' whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                            }
                        >
                            {project?.name}
                        </span>
                    </div>
                )
            },
        },
        {
            header: 'API Key',
            accessorKey: 'apiKeyName',
            cell: ({ row }) => (
                <p className="text-[15px] text-gray-550">
                    {row.original.apiKeyName !== null && row.original.apiKeyName !== ''
                        ? row.original.apiKeyName
                        : 'AiriaKey'}
                </p>
            ),
        },
        {
            header: 'Response Time',
            accessorKey: 'duration',
            cell: ({ row }) => {
                const parts = row.original.duration.split(':')
                const hours = Number.parseInt(parts[0], 10)
                const minutes = Number.parseInt(parts[1], 10)
                const seconds = Number.parseFloat(parts[2])
                const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000
                const duration = millify(totalMilliseconds, {
                    precision: 2,
                    units: ['ms', 's', 'm', 'h'],
                })

                return <p className="text-[15px] text-gray-550]">{duration}</p>
            },
        },
        {
            header: 'Total Tokens',
            accessorKey: 'totalTokens',
        },
        {
            header: 'Client IP Address',
            accessorKey: 'clientIpAddress',
        },
        {
            header: 'Tags',
            accessorKey: 'tags',
            enableSorting: false,
            cell: ({ row }) => {
                if (row.original.streamed) {
                    return (
                        <div className="flex gap-2 flex-wrap max-w-[300px] items-center content-center align-middle m-auto">
                            <Badge className="rounded-[24px] text-sm px-3 py-[2px] w-fit text-gray-550 bg-primary-100 hover:bg-primary-100 hover:text-gray-550 justify-center">
                                Streamed
                            </Badge>
                        </div>
                    )
                }
            },
        },
    ]

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy: field,
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState, router.history.location])

    return (
        <div className="flex flex-col gap-2 mt-6">
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-2">
                    <div className="min-w-48 w-fit">
                        <ProjectSelect
                            selectedProjectId={selectedProject}
                            setSelectedProjectId={setSelectedProject}
                        />
                    </div>
                    {/* <PaginationHeader
                        filter={filter}
                        setFilter={setFilter}
                        sortOptions={columns}
                        queryParams={queryParams}
                    /> */}
                </div>
            </div>
            <DefaultDataTable
                className="text-gray-550"
                data={gatewayFeedQuery.data?.items ?? []}
                columns={columns}
                onRowClick={handleRowClick}
                serverPagination
                totalCount={gatewayFeedQuery.data?.totalCount}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
                rowsPerPage={queryParams.pageSize}
            />
            {selectedCall && (
                <GatewayModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    callDetails={selectedCall as GatewayFeedResult}
                    projectId={selectedProject}
                />
            )}
        </div>
    )
}

export const getPrettyTag = (tag: string) => {
    const lowerCaseTag = tag.toLocaleLowerCase()
    switch (lowerCaseTag) {
        case 'datasearch':
            return 'RAG'
        case 'pythonstep':
            return 'Python'
        default:
            return tag
    }
}
