import type React from 'react'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { DefaultDataTable } from '@/components/DefaultDataTable'
import type { ColumnDef } from '@tanstack/react-table'
import type { paginationAndSortingParams } from '@/components/pagination/paginationHeader'
import { ProjectSelect } from '@/components/modelLibrary/selectProjectForm.tsx'
import { useDebounce } from '@uidotdev/usehooks'
import { usePagination } from '@/hooks/use-pagination'
import { useSorting } from '@/hooks/use-sorting'
import useSearchEffect from '@/hooks/use-search-effect'
import { Link, useNavigate, useRouter } from '@tanstack/react-router'
import { type StepExecutionContext, getPipelineFeed, type PipelineFeedResult } from '@/api/feed'
import PipelineExecutionModal from '@/components/home/feed/PipelineExecutionModal.tsx'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { getAllProjects } from '@/api/projects.ts'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'

export interface FeedTableProps {
    queryParams: paginationAndSortingParams
    params: { projectId: string }
}

export const ExecutionFeedTable: React.FC<FeedTableProps> = ({ queryParams, params }) => {
    const [selectedProject, setSelectedProject] = useState<string | undefined>(undefined)
    const [filter, _setFilter] = useState<string>('')
    const [selectedCall, setSelectedCall] = useState<
        PipelineFeedResult | StepExecutionContext | null
    >(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const navigate = useNavigate()
    const router = useRouter()

    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy ?? 'createdAt',
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })

    const feedExecutionsQuery = useQuery({
        queryKey: [QueryKeys.EXECUTION_FEED_ALL, selectedProject, queryParams],
        queryFn: () =>
            getPipelineFeed(
                //@ts-ignore
                selectedProject != null
                    ? queryParams.pageSize && queryParams.pageNumber
                        ? { ...queryParams, projectId: selectedProject }
                        : { pageSize: 25, pageNumber: 1, projectId: selectedProject }
                    : queryParams.pageSize && queryParams.pageNumber
                      ? { ...queryParams }
                      : { pageSize: 25, pageNumber: 1 }
            ),
    })
    const projectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })

    const handleRowClick = (row: { original: PipelineFeedResult }) => {
        setSelectedCall(row.original)
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
        setSelectedCall(null)
    }

    const debouncedSearchValue = useDebounce(filter, 500)
    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        params,
        onPaginationChange,
    })

    const columns: ColumnDef<PipelineFeedResult>[] = [
        {
            accessorKey: 'createdAt',
            header: 'Timestamp',
            cell: ({ row }) => {
                const date = new Date(row.original.createdAt)
                return (
                    <p>{`${date.toLocaleDateString(undefined, { dateStyle: 'short' })} at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`}</p>
                )
            },
        },
        {
            accessorKey: 'projectId',
            header: 'Project',
            cell: ({ row }) => {
                const project = projectsQuery.data?.find(
                    (project) => project.id === row.original.projectId
                )
                return (
                    <div className={'flex gap-1 items-center'}>
                        {project?.projectIcon ? (
                            <img
                                src={project.projectIcon}
                                alt={project.name}
                                height={17}
                                width={17}
                            />
                        ) : (
                            <AiriaIcon className="w-[17px] h-[17px]" />
                        )}
                        <span
                            className={
                                ' whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                            }
                        >
                            {project?.name}
                        </span>
                    </div>
                )
            },
        },
        {
            accessorKey: 'pipelineId',
            header: 'Pipeline',
            cell: ({ row }) => (
                <Link
                    to={'/$projectId/pipelines/$pipelineId/$versionNumber'}
                    onClick={(e) => e.stopPropagation()}
                    params={{
                        projectId: row.original.projectId,
                        pipelineId: row.original.pipelineId,
                        versionNumber: `${row.original.pipelineVersionNumber ?? 1}`,
                    }}
                    className="flex items-center text-primary hover:underline whitespace-nowrap overflow-hidden max-w-[200px] truncate"
                >
                    {row.original.pipelineExecutionName}
                    {row.original.pipelineVersionNumber &&
                        ` (v${row.original.pipelineVersionNumber})`}
                </Link>
            ),
        },
    ]

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy: field,
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState, router.history.location])

    return (
        <div className="flex flex-col gap-2 mt-6">
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-2">
                    <div className="min-w-48 w-fit">
                        <ProjectSelect
                            selectedProjectId={selectedProject}
                            setSelectedProjectId={setSelectedProject}
                        />
                    </div>
                    {/* <PaginationHeader
                        filter={filter}
                        setFilter={setFilter}
                        sortOptions={columns}
                        queryParams={queryParams}
                    /> */}
                </div>
            </div>
            <DefaultDataTable
                className="text-gray-550"
                data={feedExecutionsQuery.data?.items ?? []}
                columns={columns}
                onRowClick={handleRowClick}
                serverPagination
                totalCount={feedExecutionsQuery.data?.totalCount}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
                rowsPerPage={queryParams.pageSize}
            />
            {selectedCall && (
                <PipelineExecutionModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    callDetails={selectedCall as PipelineFeedResult}
                    projectId={selectedProject}
                />
            )}
        </div>
    )
}
