import { Button } from '@/components/ui/button'
import React from 'react'

interface OnboardingHeaderProps {
    step: number
    title: string
    onComplete?: () => void
    showCompleteButton?: boolean
}

const OnboardingHeader: React.FC<OnboardingHeaderProps> = ({
    step,
    title,
    onComplete,
    showCompleteButton = true,
}) => {
    return (
        <div className="bg-primary-legal w-full rounded-none rounded-t-sm px-6 py-5 flex flex-row items-center justify-between">
            <div className="flex flex-row gap-4 items-center p-0">
                <div className="w-6 h-6 rounded-full bg-white text-primary-legal justify-center flex">
                    {step}
                </div>
                <p className="text-white font-gilroy-bold pt-1">{title}</p>
            </div>
            {showCompleteButton && onComplete && (
                <Button
                    onClick={onComplete}
                    variant="secondary"
                    className="bg-white text-primary-legal hover:bg-white/90 select-none"
                >
                    Complete
                </Button>
            )}
        </div>
    )
}

export default OnboardingHeader
