import { User } from '@/api/users'
import { Badge } from '@/components/ui/badge'
import { UserIcon } from 'lucide-react'
import React from 'react'

const UserCard: React.FC<User> = ({ firstName, lastName, email, enterprise }) => {
    return (
        <div className="flex flex-col justify-between bg-white items-center h-[200px] w-[300px] gap-2 p-4 rounded-[8px] border border-[#E3E3E3] hover:shadow-md">
            <div className="w-[72px] h-[72px] rounded-[72px] bg-gray-300 items-center justify-center flex flex-row">
                <UserIcon className="w-8 h-8 text-black" />
            </div>
            <div className="flex flex-col items-center">
                <p className="font-medium text-lg leading-normal">{`${firstName} ${lastName}`}</p>
                <p className="text-[#007AFF] text-ellipsis line-clamp-1">{email}</p>
            </div>
            {enterprise && (
                <Badge className="rounded-full bg-gray-100 text-muted-foreground cursor-default hover:bg-gray-100 hover:text-muted-foreground">
                    {enterprise}
                </Badge>
            )}
            {!enterprise && <div className="h-4 w-4" />}
        </div>
    )
}

export default UserCard
