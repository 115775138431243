export const GatewayProviders = ['OpenAI', 'Anthropic', 'Ollama'] as const
export type GatewayProvidersType = (typeof GatewayProviders)[number]
export type GatewayCodeType = 'js' | 'python'

export const GatewayCodeExamples = {
    Anthropic: {
        js: (url: string) => `curl '${url}/anthropic/v1/messages' \\
     --header "x-api-key: AIRIA_API_KEY" \\
     --header "anthropic-version: 2023-06-01" \\
     --header "content-type: application/json" \\
     --data '{
  "model": "claude-3-5-sonnet-20240620",
  "max_tokens": 4096,
  "stream": false,
  "messages": [
    {
      "role": "user",
      "content": [
        {
          "type": "text",
          "text": "tell me a short story!"
        }]
    }]
}'`,
        python: (url: string) => `import anthropic
client = anthropic.Anthropic(base_url="${url}/anthropic", api_key=AIRIA_API_KEY)
simple_text_message = client.messages.create(
    model="claude-3-5-sonnet-20240620",
    max_tokens=2048,
    messages=[{ "role": "user", "content": "tell me a short story!" }])`,
    },
    OpenAI: {
        js: (url: string) => `curl '${url}/v1/chat/completions' \\
  -H 'Content-Type: application/json' \\
  -H 'Authorization: Bearer AIRIA_API_KEY' \\
  -d '{
    "model": "gpt-4o",
    "stream": false,
    "logprobs": false,
    "messages": [
      {
        "role": "system",
        "content": "You are a helpful news assistant."
      },
      {
        "role": "user",
        "content": "Tell me a short story!"
      }]
}'`,
        python: (url: string) => `import json
import openai
client = openai.OpenAI(base_url="${url}/openai/v1", api_key=AIRIA_API_KEY)
simple_text_message = client.chat.completions.create(
    model="gpt-4o",
    messages=[ { "role": "user", "content": "Tell me a short story!" } ])`,
    },
    Ollama: {
        js: (url: string) => `curl '${url}/ollama/api/chat' \\
--header "x-api-key: AIRIA_API_KEY" \\
-d '{
  "model": "llama3:8b",
  "stream": false,
  "messages": [
    {
      "role": "user",
      "content": "Tell me a short story!"
    }]
}'`,
        python: (url: string) => `from ollama import Client
headers = {"Content-Type": "application/json", "Accept": "application/json", "X-Api-Key": "AIRIA_API_KEY"}
client = Client(host="${url}", headers=headers)
simple_text_message = client.chat(
    model="llama3:8b",
    stream=False,
    messages=[{ "role": "user", "content": "Tell me a short story!" }])`,
    },
}
