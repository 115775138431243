import { Dialog, DialogContent } from '@/components/ui/dialog.tsx'
import { useTranslation } from '@/lib/i18n'

interface ImagePreviewDialogProps {
    open: boolean
    setOpen: (open: boolean) => void
    imageUrl: string
}

export function ImagePreviewDialog({ open, setOpen, imageUrl }: ImagePreviewDialogProps) {
    const { t } = useTranslation()
    return (
        <Dialog open={open} onOpenChange={() => setOpen(false)}>
            <DialogContent className={'max-w-modal-lg max-h-modal'}>
                <div className={'flex justify-center items-center'}>
                    <img src={imageUrl} alt={`${t('imgAlt.preview')}-${imageUrl}`} />
                </div>
            </DialogContent>
        </Dialog>
    )
}
