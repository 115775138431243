import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next, useTranslation as useTranslationOrg } from 'react-i18next'
import { englishTranslation } from '@/constants/locales/en/en.lazy'
import { spanishTranslation } from '@/constants/locales/es/es.lazy'
import { TFunction } from './types/tFunction'

// Define the structure of your translations
export type TranslationResource = typeof englishTranslation

// Create a type-safe resources object
const resources = {
    en: { translation: englishTranslation },
    es: { translation: spanishTranslation },
} as const

type SupportedLanguages = keyof typeof resources

i18n.use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en' as SupportedLanguages,
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: './public/locales/{{lng}}/{{ns}}.json',
        },
        react: {
            useSuspense: false,
        },
        resources,
    })

export default i18n

export function useTranslation() {
    const { t, i18n, ready } = useTranslationOrg()
    return { t: t as TFunction, i18n, ready }
}
