import React, { createContext, useState, useContext, ReactNode } from 'react'

interface ConfidentialityContextType {
    isConfidential: boolean
    setIsConfidential: React.Dispatch<React.SetStateAction<boolean>>
}

const ConfidentialityContext = createContext<ConfidentialityContextType | undefined>(undefined)

interface ConfidentialityProviderProps {
    children: ReactNode
}

export const ConfidentialityProvider: React.FC<ConfidentialityProviderProps> = ({ children }) => {
    const [isConfidential, setIsConfidential] = useState<boolean>(true)

    return (
        <ConfidentialityContext.Provider value={{ isConfidential, setIsConfidential }}>
            {children}
        </ConfidentialityContext.Provider>
    )
}

export const useConfidentiality = (): ConfidentialityContextType => {
    const context = useContext(ConfidentialityContext)
    if (context === undefined) {
        throw new Error('useConfidentiality must be used within a ConfidentialityProvider')
    }
    return context
}
