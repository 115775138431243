import { UserClaims, getUserProfile } from '@/api/profile'
import { deleteUser, getUserById } from '@/api/users'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
} from '@/components/ui/dialog'
import { toast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { useAuth } from '@/hooks/use-auth'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createFileRoute, useNavigate, useRouter } from '@tanstack/react-router'
import { t } from 'i18next'
import { useEffect } from 'react'

export const Route = createFileRoute('/_legalLayout/$projectId/legal/users/$userId/delete')({
    component: () => <DeleteUser />,
})

function DeleteUser() {
    const router = useRouter()
    const params = Route.useParams()
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const { checkAndRedirect, isLegalAdmin, user } = useAuth()

    const userQuery = useQuery({
        queryKey: [QueryKeys.CURRENT_USER, params.userId],
        queryFn: () => getUserById(params.userId),
    })
    const userProfileQuery = useQuery<UserClaims, Error>({
        queryKey: [QueryKeys.USER_PROFILE],
        queryFn: getUserProfile,
    })

    const deleteUserMutation = useMutation({
        mutationFn: ({ userId, orgId }: { userId: string; orgId: string }) =>
            deleteUser({ userId, orgId }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_USERS] })
            toast({
                title: t('success_title'),
                description: 'User deleted successfully',
            })
            navigate({
                // @ts-ignore
                to: '/$projectId/legal/users',
                // @ts-ignore
                params: { projectId: params.projectId },
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    useEffect(() => {
        checkAndRedirect(
            isLegalAdmin && user?.identityServerUserId !== userQuery?.data?.identityServerUserId
        )
    }, [userQuery.data])

    return (
        <Dialog
            open
            onOpenChange={() =>
                navigate({
                    // @ts-ignore
                    to: '/$projectId/legal/users',
                    // @ts-ignore
                    params: { projectId: params.projectId },
                })
            }
        >
            <DialogContent className="max-w-modal-sm max-h-modal">
                <DialogHeader>Delete User</DialogHeader>
                <DialogDescription>
                    Are you sure you want to delete{' '}
                    {userQuery.data?.firstName
                        ? userQuery.data?.firstName + ' ' + userQuery.data?.lastName
                        : 'this user'}
                    ?
                </DialogDescription>
                <DialogFooter>
                    <Button
                        className="bg-[#ECE8FF] text-primary-legal hover:bg-[#ECE8FFAA] hover:text-primary-legal"
                        onClick={() => router.history.back()}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            await deleteUserMutation.mutateAsync({
                                userId: params.userId,
                                orgId: userProfileQuery.data?.activeOrganizationClaim.id ?? '',
                            })
                        }}
                        variant="destructive"
                    >
                        Delete
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
