import apiRequest from '@/lib/apiClient'

export type ApiKey = {
    id: string
    name: string
    key: string
    createdAt: string
    projectId: string | null | undefined
}

export type ApiKeyValue = {
    key: string
}

export type ApiKeyList = {
    items: ApiKey[]
    totalCount: number
}

export const getAllApiKeys = (
    queryParams?: Record<string, string | number>
): Promise<ApiKeyList> => {
    return apiRequest('/v1/ApiKeys', { queryParams })
}

export const getNewApiKey = (): Promise<ApiKeyValue> => {
    return apiRequest('/v1/ApiKeys/Generate')
}

export const createApiKey = (apiKey: ApiKey): Promise<void> => {
    return apiRequest('/v1/ApiKeys', { method: 'POST', body: apiKey })
}

export const deleteApiKey = (id: string): Promise<void> => {
    return apiRequest(`/v1/ApiKeys/${id}`, { method: 'DELETE' })
}

export const getApiKeyById = (id: string): Promise<ApiKey> => {
    return apiRequest(`/v1/ApiKeys/${id}`)
}
