import {
    getPipelineExecutionCountByModel,
    getPipelineExecutionErrorRate,
    getPipelineExecutionLatency,
    getPipelineExecutionLogsCount,
    getPipelineExecutionLogsCountByDateRange,
    getPipelineExecutionModelUsage,
} from '@/api/pipelineExecution'
import BankNote from '@/assets/icons/bank-note.svg?react'
import Cube from '@/assets/icons/cube.svg?react'
import LineChartUp from '@/assets/icons/line-chart-up.svg?react'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { useQuery } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'
import millify from 'millify'
import type React from 'react'
import { useState } from 'react'
import DonutChart from './DonutChart'
import UsageChart from './UsageChart'
import {
    startOfMonth,
    endOfMonth,
    sub,
    startOfYear,
    endOfYear,
    startOfWeek,
    endOfWeek,
} from 'date-fns'

interface Execution {
    pipelineName: string
    modelName: string
    timePeriod: string
    executionCount: number
}

interface RawData {
    [date: string]: Execution[]
}

export interface ChartDataItem {
    date: string
    originalDate: Date
    [pipelineName: string]: number | string | Date
}

const Overview: React.FC = () => {
    const [dateRange, setDateRange] = useState('last30')
    const pieColors = ['#4298FF', '#3100FF', '#9999FF', '#33CB98', '#E03FD8']

    const calculateDateRanges = (dateRange: string) => {
        const currentDate = new Date()
        let startDate: Date
        let endDate: Date
        switch (dateRange) {
            case 'last30':
                startDate = sub(currentDate, { days: 30 })
                endDate = currentDate
                break
            case 'year':
                startDate = startOfYear(currentDate)
                endDate = endOfYear(currentDate)
                break
            case 'month':
                startDate = startOfMonth(currentDate)
                endDate = sub(endOfMonth(currentDate), { days: 1 })
                break
            case 'week':
                startDate = startOfWeek(currentDate)
                endDate = sub(endOfWeek(currentDate), { days: 1 })
                break
            default:
                startDate = sub(currentDate, { days: 30 })
                endDate = currentDate
        }
        return { startDate, endDate }
    }

    const executionCountQuery = useQuery({
        queryKey: ['execution-count', dateRange],
        queryFn: () => getPipelineExecutionLogsCount(dateRange),
        enabled: dateRange === 'year',
    })

    const executionCountDateRangeQuery = useQuery({
        queryKey: ['execution-count', dateRange],
        queryFn: () => {
            const ranges = calculateDateRanges(dateRange)
            return getPipelineExecutionLogsCountByDateRange(
                ranges.startDate.toISOString(),
                ranges.endDate.toISOString()
            )
        },
    })

    const executionErrorRateQuery = useQuery({
        queryKey: ['execution-error-rate', dateRange],
        queryFn: () => {
            const ranges = calculateDateRanges(dateRange)
            return getPipelineExecutionErrorRate(
                ranges.startDate.toISOString(),
                ranges.endDate.toISOString()
            )
        },
    })

    const executionLatencyQuery = useQuery({
        queryKey: ['execution-latency', dateRange],
        queryFn: () => {
            const ranges = calculateDateRanges(dateRange)
            return getPipelineExecutionLatency(
                ranges.startDate.toISOString(),
                ranges.endDate.toISOString()
            )
        },
    })

    const modelUsageQuery = useQuery({
        queryKey: ['model-usage', dateRange],
        queryFn: () => {
            const ranges = calculateDateRanges(dateRange)
            return getPipelineExecutionModelUsage(
                ranges.startDate.toISOString(),
                ranges.endDate.toISOString()
            )
        },
    })

    const executionCountByModelQuery = useQuery({
        queryKey: ['execution-count-model', dateRange],
        queryFn: () => {
            const ranges = calculateDateRanges(dateRange)
            return getPipelineExecutionCountByModel(
                ranges.startDate.toISOString(),
                ranges.endDate.toISOString()
            )
        },
    })

    const getTotalRequests = (data: RawData): number => {
        return Object.values(data)
            .flat()
            .reduce((total, execution) => total + execution.executionCount, 0)
    }

    const transformData = (data: RawData, range: string, type: string): ChartDataItem[] => {
        if (data) {
            const transformedData = Object.entries(data).map(([date, executions]) => {
                const originalDate = new Date(date)
                const adjustedDate = new Date(
                    originalDate.getFullYear(),
                    originalDate.getMonth(),
                    originalDate.getDate() + 1
                )
                const chartDataItem: ChartDataItem = {
                    date: '',
                    originalDate: originalDate,
                }

                if (range === 'year') {
                    const month = adjustedDate.toLocaleString('default', { month: 'short' })
                    chartDataItem.date = month
                } else if (range === 'week') {
                    chartDataItem.date = adjustedDate.toLocaleString('default', {
                        weekday: 'short',
                    })
                } else {
                    chartDataItem.date = adjustedDate.toLocaleString('default', { day: 'numeric' })
                }

                const counts: { [key: string]: number } = {}
                for (const execution of executions) {
                    const key = type === 'pipelines' ? execution.pipelineName : execution.modelName
                    counts[key] = (counts[key] || 0) + execution.executionCount
                }

                const topResults = Object.entries(counts)
                    .sort((a, b) => b[1] - a[1])
                    .slice(0, 4)

                let otherCount = 0
                for (const [name, count] of Object.entries(counts)) {
                    if (topResults.some(([topName]) => topName === name)) {
                        chartDataItem[name] = count
                    } else {
                        otherCount += count
                    }
                }

                if (otherCount > 0) {
                    chartDataItem.Other = otherCount
                }

                return chartDataItem
            })

            const sortedData = transformedData.sort(
                (a, b) => a.originalDate.getTime() - b.originalDate.getTime()
            )

            if (range === 'year') {
                const monthCounts = new Map()
                return sortedData.map((item) => {
                    const count = monthCounts.get(item.date) || 0
                    monthCounts.set(item.date, count + 1)

                    if (count === 1) {
                        return item
                    }
                    return { ...item, date: '' }
                })
            }

            return sortedData
        }

        return []
    }

    const processPieChartData = (
        items: {
            modelName: string
            executionCount: number
            modelProvider: string
        }[],
        key: 'modelName' | 'modelProvider'
    ) => {
        if (items) {
            const aggregated = items.reduce(
                (acc, item) => {
                    const name = item[key]
                    acc[name] = (acc[name] || 0) + item.executionCount
                    return acc
                },
                {} as Record<string, number>
            )

            const sorted = Object.entries(aggregated)
                .sort((a, b) => b[1] - a[1])
                .map(([name, value]) => ({ name, value }))

            const top4 = sorted.slice(0, 4)
            const otherSum = sorted.slice(4).reduce((sum, item) => sum + item.value, 0)

            if (otherSum > 0) {
                top4.push({ name: 'Other', value: otherSum })
            }

            return top4
        }

        return []
    }

    return (
        <div className="flex flex-col gap-6 mt-6">
            <div className="flex flex-row justify-between">
                <Select value={dateRange} onValueChange={setDateRange}>
                    <SelectTrigger className="w-40">
                        <SelectValue placeholder="Date Range" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="last30">Last 30 days</SelectItem>
                        <SelectItem value="year">This year</SelectItem>
                        <SelectItem value="month">This month</SelectItem>
                        <SelectItem value="week">This week</SelectItem>
                    </SelectContent>
                </Select>
            </div>
            <div className="flex gap-10">
                <div className="flex flex-col">
                    <p className="text-[15px] text-gray-500">Total Requests</p>
                    <p className="text-3xl">
                        {getTotalRequests(executionCountDateRangeQuery.data ?? []) !== 0
                            ? millify(getTotalRequests(executionCountDateRangeQuery.data ?? []))
                            : '--'}
                    </p>
                </div>
                <div className="flex flex-col">
                    <p className="text-[15px] text-gray-500">Avg Latency</p>
                    <p className="text-3xl">
                        {executionLatencyQuery?.data != null &&
                        getTotalRequests(executionCountDateRangeQuery.data ?? []) !== 0
                            ? millify(executionLatencyQuery.data, { units: ['ms', 's'] })
                            : '--'}
                    </p>
                </div>
                <div className="flex flex-col">
                    <p className="text-[15px] text-gray-500">Error Rate</p>
                    <p className="text-3xl">
                        {executionErrorRateQuery?.data != null &&
                        getTotalRequests(executionCountDateRangeQuery.data ?? []) !== 0
                            ? `${(executionErrorRateQuery.data * 100).toFixed(2)}%`
                            : '--'}
                    </p>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-10">
                <div className="flex flex-col w-full col-span-2 gap-8">
                    <div className="flex flex-col">
                        <div className="flex flex-col pb-4">
                            <p>Usage</p>
                            <p className={'text-sm'}>Calls by Pipeline</p>
                        </div>
                        <UsageChart
                            data={transformData(
                                dateRange === 'year'
                                    ? executionCountQuery.data
                                    : executionCountDateRangeQuery.data,
                                dateRange,
                                'pipelines'
                            )}
                            dateRange={dateRange}
                            loading={
                                dateRange === 'year'
                                    ? executionCountQuery.isLoading
                                    : executionCountDateRangeQuery.isLoading
                            }
                            type="pipelines"
                        />
                    </div>
                    <div className="flex flex-col">
                        <div className="flex flex-col pb-4">
                            <p>Usage</p>
                            <p className={'text-sm'}>Calls by Model</p>
                        </div>
                        <UsageChart
                            data={transformData(
                                executionCountByModelQuery.data,
                                dateRange,
                                'models'
                            )}
                            dateRange={dateRange}
                            loading={executionCountByModelQuery.isLoading}
                            type="models"
                        />
                    </div>
                </div>
                <div className="flex flex-col w-full col-span-1 gap-2">
                    {(modelUsageQuery?.data &&
                        Object.entries(modelUsageQuery?.data).length !== 0) ||
                    modelUsageQuery.isLoading ? (
                        <>
                            <p>Top Models</p>
                            <DonutChart
                                data={processPieChartData(modelUsageQuery.data, 'modelName')}
                                dataKey="value"
                                nameKey="name"
                                colors={pieColors}
                                loading={modelUsageQuery.isLoading}
                            />
                        </>
                    ) : (
                        <MetricEmptyState type="models" />
                    )}
                    {(modelUsageQuery?.data &&
                        Object.entries(modelUsageQuery?.data).length !== 0) ||
                    modelUsageQuery.isLoading ? (
                        <>
                            <p>Top Providers</p>
                            <DonutChart
                                data={processPieChartData(modelUsageQuery.data, 'modelProvider')}
                                dataKey="value"
                                nameKey="name"
                                colors={pieColors}
                                loading={modelUsageQuery.isLoading}
                            />
                        </>
                    ) : (
                        <MetricEmptyState type="models" />
                    )}
                </div>
            </div>
        </div>
    )
}

const MetricEmptyState = (props: { type: string }) => {
    const renderIcon = (type: string) => {
        switch (type) {
            case 'cost':
                return <BankNote className="w-6 h-6" />
            case 'usage':
                return <LineChartUp className="w-6 h-6" />
            case 'models':
                return <Cube className="w-6 h-6" />
        }
    }
    const renderHeader = (type: string) => {
        switch (type) {
            case 'cost':
                return <p className="text-[15px]">No costs yet</p>
            case 'usage':
                return <p className="text-[15px]">No usage yet</p>
            case 'models':
                return <p className="text-[15px]">No models yet</p>
        }
    }
    const renderDescription = (type: string) => {
        switch (type) {
            case 'cost':
                return (
                    <p className="w-48 text-center text-[13px]">
                        <Link className="text-primary" to="/settings/billing">
                            Purchase Credits
                        </Link>{' '}
                        to start monitoring your cost
                    </p>
                )
            case 'usage':
                return (
                    <p className="w-56 text-center text-[13px]">
                        <Link className="text-primary">Create a Project</Link> to get started
                    </p>
                )
            case 'models':
                return (
                    <p className="w-56 text-center text-[13px]">
                        <Link className="text-primary" to="/library">
                            Add a model
                        </Link>{' '}
                        to get started
                    </p>
                )
        }
    }
    return (
        <div
            className={cn(
                'flex flex-col border border-gray-300 rounded-lg justify-center items-center w-full h-[180px] gap-2',
                props.type === 'models' && 'h-full'
            )}
        >
            {renderIcon(props.type)}
            {renderHeader(props.type)}
            {renderDescription(props.type)}
        </div>
    )
}
export default Overview
