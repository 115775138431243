import { UseCase } from '@/components/tools/UseCase'
import apiRequest from '@/lib/apiClient'

export type Parameter = {
    id: string
    name: string
    type: string
    description: string
    default: string
    validOptions: string[]
    toolDefinitionId: string
}

export type Tool = {
    id: string
    name: string
    description: string
    methodType: string
    purpose: string
    useCases: UseCase[]
    headers: { key: string; value: string }[]
    parameters: Parameter[]
    apiEndpoint: string
    toolsHandler: string
    createdAt: string
    updatedAt: string
    projectId: string
}

export type ToolList = {
    items: Tool[]
    totalCount: number
}
export const CreateTool = async (tool: Tool): Promise<void> => {
    return apiRequest(`/v1/Tools`, { method: 'POST', body: tool })
}

export const DeleteTool = async (id: string): Promise<void> => {
    return apiRequest(`/v1/Tools/${id}`, { method: 'DELETE' })
}

export const getAllToolsByProjectId = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<ToolList> => {
    return apiRequest('/v1/Tools', { queryParams })
}

export const getAllTools = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<Tool[]> => {
    return apiRequest('/v1/Tools', queryParams)
}

export const getToolById = async (toolId: string): Promise<Tool> => {
    return apiRequest(`/v1/Tools/${toolId}`)
}

export const updateTool = async (tool: Tool): Promise<void> => {
    return apiRequest(`/v1/Tools/${tool.id}`, { method: 'PUT', body: tool })
}
