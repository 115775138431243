import { useState } from 'react'

export interface sortingParams {
    initialField?: string
    initialOrder?: 'ASC' | 'DESC'
}

export function useSorting({ initialField, initialOrder }: sortingParams) {
    const [sortingState, setSortingState] = useState([
        { id: initialField ?? 'updatedAt', desc: initialOrder === 'DESC' },
    ])

    return {
        sortingState,
        onSortingChange: setSortingState,
        order: !sortingState.length ? initialOrder : sortingState[0].desc ? 'DESC' : 'ASC',
        field: sortingState.length ? sortingState[0].id : initialField,
    }
}
