import { StrictMode, Suspense, lazy } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './lib/sentry'
import App from '@/App.tsx'

// *** DO NOT MERGE THIS CODE UNCOMMENTED ***
// Uncomment the following lines to mock the Keycloak context
// For testing themes of the login page, register page, and account page
// import { getKcContextMock } from './keycloak-theme/login/KcPageStory'
//
// if (import.meta.env.DEV) {
//     window.kcContext = getKcContextMock({
//         pageId: 'login.ftl',
//         overrides: {},
//     })
// }

const KcLoginThemePage = lazy(() => import('./keycloak-theme/login/KcPage'))
const KcAccountThemePage = lazy(() => import('./keycloak-theme/account/KcPage'))

// Render the app
const rootElement = document.getElementById('root')!
if (!rootElement?.innerHTML) {
    const root = ReactDOM.createRoot(rootElement)
    root.render(
        <StrictMode>
            <Suspense>
                {(() => {
                    switch (window.kcContext?.themeType) {
                        case 'login':
                            return <KcLoginThemePage kcContext={window.kcContext} />
                        case 'account':
                            return <KcAccountThemePage kcContext={window.kcContext} />
                    }
                    return <App />
                })()}
            </Suspense>
        </StrictMode>
    )
}

declare global {
    interface Window {
        kcContext?:
            | import('./keycloak-theme/login/KcContext').KcContext
            | import('./keycloak-theme/account/KcContext').KcContext
    }
}
