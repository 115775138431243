import { User } from '@/api/users.ts'
import apiRequest from '@/lib/apiClient'

interface BaseGroup {
    id: string
    name: string
    parentId: string
    createdAt: Date
    identityServerGroupId: string
}

export type Group = BaseGroup & {
    users: User[]
}

export type GroupList = {
    items: Group[]
    totalCount: number
}
export const getAllGroups = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<GroupList> => {
    return apiRequest('/v1/Groups', { queryParams })
}

export const createGroup = async (group: Group): Promise<void> => {
    return apiRequest('/v1/Groups', { method: 'POST', body: group })
}
export const updateGroup = async (group: Group): Promise<void> => {
    return apiRequest(`/v1/Groups/${group.id}`, { method: 'PUT', body: group })
}

export const getGroupById = async (id: string): Promise<Group> => {
    return apiRequest(`/v1/Groups/${id}`)
}

export const deleteGroup = async (id: string): Promise<void> => {
    return apiRequest(`/v1/Groups/${id}`, { method: 'DELETE' })
}
