import { getAllProjects } from '@/api/projects.ts'
import Airia from '@/assets/models/Airia.svg?react'
import { Button } from '@/components/ui/button.tsx'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '@/components/ui/command.tsx'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover.tsx'
import { Separator } from '@/components/ui/separator.tsx'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { cn } from '@/lib/utils.ts'
import { useQuery } from '@tanstack/react-query'
import { Check, ChevronsUpDown } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from '@/lib/i18n'
import { ScrollArea } from '../ui/scroll-area'

interface SelectProjectFormProps {
    onSelectProjectSubmit: (projectId: string) => void
}

interface SelectProjectProps {
    selectedProjectId: string | undefined
    setSelectedProjectId: React.Dispatch<React.SetStateAction<string | undefined>>
    placeholder?: string
}

const SelectProjectForm: React.FC<SelectProjectFormProps> = ({ onSelectProjectSubmit }) => {
    const { t } = useTranslation()
    const [selectedProjectId, setSelectedProjectId] = useState<string>()

    return (
        <div className={'flex flex-col gap-2'}>
            <span>{t('which_project')}</span>
            <ProjectSelect
                selectedProjectId={selectedProjectId}
                setSelectedProjectId={setSelectedProjectId}
            />
            <Separator className={'my-4'} />
            <div className={'flex justify-end'}>
                <Button
                    disabled={!selectedProjectId}
                    onClick={() => onSelectProjectSubmit(selectedProjectId ?? '')}
                >
                    {t('add_to_project')}
                    <Check className={'h-4 w-4 ml-1'} />
                </Button>
            </div>
        </div>
    )
}

export const ProjectSelect: React.FC<SelectProjectProps> = ({
    selectedProjectId,
    setSelectedProjectId,
    placeholder,
}) => {
    const [projectSelectOpen, setProjectSelectOpen] = useState(false)

    const triggerRef = useRef<HTMLButtonElement>(null)
    const [triggerWidth, setTriggerWidth] = useState<number>()

    const allProjectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: getAllProjects,
    })

    const renderSelectedProject = () => {
        const selectedProject = allProjectsQuery.data?.find(
            (project) => project.id === selectedProjectId
        )
        return selectedProject ? (
            <div className={'flex gap-1 items-center'}>
                {selectedProject.projectIcon ? (
                    <img src={selectedProject.projectIcon} className={'h-4 w-4 mr-1'} />
                ) : (
                    <Airia className={'h-4 w-4'} />
                )}
                {selectedProject.name}
            </div>
        ) : placeholder ? (
            placeholder
        ) : (
            'Select Project...'
        )
    }

    useEffect(() => {
        if (triggerRef.current) {
            setTriggerWidth(triggerRef.current.offsetWidth)
        }
    }, [triggerRef.current])

    return (
        <Popover open={projectSelectOpen} onOpenChange={setProjectSelectOpen}>
            <PopoverTrigger asChild>
                <Button
                    ref={triggerRef}
                    variant="outline"
                    role="combobox"
                    className="w-full justify-between"
                >
                    {renderSelectedProject()}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="p-0" style={{ width: triggerWidth }}>
                <Command
                    filter={(_, search, keywords) => {
                        if (keywords?.join(' ').toLowerCase().includes(search.toLowerCase())) {
                            return 1
                        }
                        return 0
                    }}
                >
                    <CommandInput placeholder="Search project..." />
                    <CommandList>
                        <ScrollArea>
                            <CommandEmpty>No framework found.</CommandEmpty>
                            <CommandGroup className="">
                                {allProjectsQuery.data?.map((project) => (
                                    <CommandItem
                                        key={project.id}
                                        value={project.id}
                                        keywords={[project.name]}
                                        onSelect={(currentValue) => {
                                            setSelectedProjectId(
                                                currentValue === selectedProjectId
                                                    ? ''
                                                    : currentValue
                                            )
                                            setProjectSelectOpen(false)
                                        }}
                                    >
                                        <Check
                                            className={cn(
                                                'mr-2 h-4 w-4',
                                                selectedProjectId === project.id
                                                    ? 'opacity-100'
                                                    : 'opacity-0'
                                            )}
                                        />
                                        {project.projectIcon ? (
                                            <img
                                                src={project.projectIcon}
                                                className={'h-4 w-4 mr-1'}
                                            />
                                        ) : (
                                            <Airia className={'h-4 w-4 mr-1'} />
                                        )}
                                        {project.name}
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </ScrollArea>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}

export default SelectProjectForm
