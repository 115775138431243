import AiriaLogoBlack from '@/assets/airia-logo-black.svg?react'
import { useConfidentiality } from '@/hooks/use-confidentiality'
import { useTheme } from '@/lib/ThemeProvider'
import { getAuthUrl } from '@/lib/utils'
import { Link } from '@tanstack/react-router'
import { t } from 'i18next'
import { TriangleAlert, UserCircle } from 'lucide-react'
import { useEffect } from 'react'
import { Button } from '../ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '../ui/dropdown-menu'

export const LegalTopNav = ({ params }: { params: { projectId: string } }) => {
    const { setTheme } = useTheme()

    useEffect(() => {
        setTheme('light')
    }, [])
    const { isConfidential } = useConfidentiality()

    return (
        <div className="">
            {!isConfidential && (
                <div className="flex flex-row items-center justify-center bg-[#FFEBC5] py-2 w-full">
                    <TriangleAlert />
                    This app is using AI models on the public internet, which may not be secure or
                    verified. Proceed with caution and avoid sharing sensitive information.
                </div>
            )}
            <div className="flex flex-row bg-frontline items-start justify-between p-6 z-0 h-[300px]">
                <AiriaLogoBlack className="w-28 h-11" />
                <div className="flex flex-row gap-6">
                    <Link
                        to="/$projectId/legal/"
                        params={{ projectId: params.projectId }}
                        className="text-[15px] font-bold rounded-2xl px-4 py-2 hover:bg-white"
                        activeProps={{
                            className: 'bg-white text-primary-legal shadow-none',
                        }}
                        activeOptions={{ exact: true }}
                        inactiveProps={{
                            className: 'text-white hover:text-primary-legal',
                        }}
                    >
                        Assistant
                    </Link>
                    {/* <Link
                    to="/$projectId/legal/documents"
                    params={{ projectId: params.projectId }}
                    className="text-[15px] font-bold rounded-2xl px-4 py-2 hover:bg-white"
                    activeProps={{
                        className: 'bg-white text-primary-legal shadow-none',
                    }}
                    activeOptions={{ exact: true }}
                    inactiveProps={{
                        className: 'text-white hover:text-primary-legal',
                    }}
                >
                    Documents
                </Link> */}
                    <Link
                        // @ts-ignore
                        to="/$projectId/legal/users"
                        // @ts-ignore
                        params={{ projectId: params.projectId }}
                        className="text-[15px] font-bold rounded-2xl px-4 py-2 hover:bg-white"
                        activeProps={{
                            className: 'bg-white text-primary-legal shadow-none',
                        }}
                        activeOptions={{ exact: true }}
                        inactiveProps={{
                            className: 'text-white hover:text-primary-legal',
                        }}
                    >
                        Users
                    </Link>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button
                                className="text-[15px] font-bold rounded-2xl mt-[1.5px] hover:bg-white text-white hover:text-primary-legal"
                                variant="ghost"
                                size="icon"
                            >
                                <UserCircle className="h-6 w-6" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="mt-0" align="end">
                            <DropdownMenuItem className="focus:text-primary-legal" asChild>
                                <a
                                    href={
                                        import.meta.env.DEV
                                            ? 'https://localhost:5001/auth/logout'
                                            : `${getAuthUrl()}/MicrosoftIdentity/Account/SignOut`
                                    }
                                >
                                    {t('logout')}
                                </a>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
        </div>
    )
}
