import { TopNav } from '@/components/TopNav'
import { Toaster } from '@/components/ui/toaster'
import { useAuth } from '@/hooks/use-auth'
import { Outlet, createFileRoute } from '@tanstack/react-router'
import { LoaderCircle } from 'lucide-react'
import React, { Suspense } from 'react'
import SideNavLayout from '@/components/sidenav/SideNavLayout'

export const Route = createFileRoute('/_mainLayout')({
    component: () => {
        const { isPlatformUser } = useAuth()
        if (!isPlatformUser) {
            return (
                <div className="flex items-center justify-center h-screen w-screen">
                    <LoaderCircle className="animate animate-spin w-15 h-15" />
                </div>
            )
        }
        return <MainLayout />
    },
    onError: (err) => {
        console.log('error in mainlayout: ', err)
    },
    parseParams: (params) => {
        return {
            ...params,
            //@ts-ignore
            projectId: params?.projectId,
        }
    },
})

export const TanStackRouterDevtools =
    process.env.NODE_ENV === 'production'
        ? () => null // Render nothing in production
        : React.lazy(() =>
              // Lazy load in development
              import('@tanstack/router-devtools').then((res) => ({
                  default: res.TanStackRouterDevtools,
                  // For Embedded Mode
                  // default: res.TanStackRouterDevtoolsPanel
              }))
          )

function MainLayout() {
    return (
        <div className="flex h-screen bg-background overflow-y-clip">
            <SideNavLayout />
            <div className="grow">
                <TopNav />
                <div className="px-6 flex justify-center">
                    <div className="w-full max-w-[80vw] h-[calc(100vh-88px)] overflow-y-auto">
                        <Outlet />
                    </div>
                </div>
            </div>
            <Suspense>
                <TanStackRouterDevtools position="bottom-right" />
            </Suspense>
            <Toaster />
        </div>
    )
}

/* 
- API Keys by usage
- Timeline of tokens spent.

*/
