import apiRequest from '@/lib/apiClient'

export enum FeatureFlag {
    EnableSsoConfigurationFeatureFlag = 'EnableSsoConfigurationFeatureFlag',
    EnableAllConnectorsFeatureFlag = 'EnableAllConnectorsFeatureFlag',
}

export const getFeatureFlag = async (featureFlag: FeatureFlag): Promise<boolean> => {
    return apiRequest(`/v1/FeatureFlag/${featureFlag}`)
}
