import { MessageType, WebSocketMessage } from '@/api/conversation'
import { TemporaryPipelineRequest, runTemporaryPipeline } from '@/api/pipelineExecution'
import { useToast } from '@/components/ui/use-toast'
import { useState } from 'react'
import useWebSocket from 'react-use-websocket'

interface useChatStreamProps {
    websocketUrl: string
}

export const useChatStream = ({ websocketUrl }: useChatStreamProps) => {
    const { toast } = useToast()

    const [messages, setMessages] = useState<MessageType[]>(() => [])
    const [sendButtonDisabled, setSendButtonDisabled] = useState(false)

    useWebSocket(websocketUrl, {
        onMessage: (event) => {
            const incomingMessage = WebSocketMessage.parse(JSON.parse(event.data))

            // If the index is 0, it means the assistant is starting a new message
            if (incomingMessage.Index == 0) {
                setSendButtonDisabled(true)
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        message: incomingMessage.Content,
                        role: 'assistant',
                        createdAt: new Date().toLocaleString(),
                        messageId: '',
                        images: [],
                        updatedAt: null,
                        id: '',
                    },
                ])
            } else if (incomingMessage.Index != -1) {
                // If the index is not -1, it means the assistant is continuing a message.
                setMessages((prevMessages) => {
                    const newMessages = [...prevMessages]
                    const lastMessageIndex = newMessages.length > 0 ? newMessages.length - 1 : 0
                    const lastMessage = newMessages[lastMessageIndex]
                        ? newMessages[lastMessageIndex]
                        : {
                              message: '',
                              id: '',
                              role: 'assistant',
                              createdAt: new Date().toLocaleString(),
                              images: [],
                          }

                    newMessages[lastMessageIndex] = {
                        ...lastMessage,
                        createdAt: new Date().toLocaleString(),
                        updatedAt: null,
                        message:
                            lastMessage?.message == undefined
                                ? incomingMessage.Content
                                : lastMessage.message + incomingMessage.Content,
                    }
                    return newMessages
                })
            } else if (incomingMessage.Index == -1) {
                // -1 message is the last message from the assistant
                setSendButtonDisabled(false)
                setMessages((prev) =>
                    prev.map((message) => ({ ...message, requestCompleted: true }))
                )
            }
        },
    })

    const sendMessage = async ({
        temporaryPipelineExecution,
        handleCleanUp,
    }: {
        temporaryPipelineExecution: TemporaryPipelineRequest
        handleCleanUp: () => void
    }) => {
        try {
            setMessages((prev) => [
                ...prev,
                {
                    id: '',
                    message: temporaryPipelineExecution.userInput,
                    role: 'user',
                    createdAt: new Date().toLocaleString(),
                    updatedAt: null,
                    messageId: '',
                    images: [],
                },
            ])

            handleCleanUp()

            await runTemporaryPipeline(temporaryPipelineExecution)
        } catch {
            toast({
                variant: 'destructive',
                title: 'Uh oh! Something went wrong.',
                description: 'There was a problem sending your message.',
            })
        }
    }

    return {
        messages,
        sendButtonDisabled,
        sendMessage,
        setMessages,
    }
}
