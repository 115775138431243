import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react'
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { Input } from './input'

interface MaskedInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    maskChar?: string
    maskAll: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue?: UseFormSetValue<any>
    getValues?: UseFormGetValues<any>
    fieldName?: string
}

export const MaskedInput = forwardRef<HTMLInputElement, MaskedInputProps>(
    (
        {
            onChange,
            onBlur,
            maskChar = '•',
            value: initialValue = '',
            maskAll = false,
            setValue,
            getValues,
            fieldName,
            ...props
        },
        ref
    ) => {
        const [maskedValue, setMaskedValue] = useState('')
        const actualValueRef = useRef(initialValue as string)
        const inputRef = useRef<HTMLInputElement>(null)

        useImperativeHandle(ref, () => ({
            ...inputRef.current!,
            value: actualValueRef.current,
        }))

        const getMaskedValue = (value: string) => {
            if (maskAll) {
                return maskChar.repeat(value.length)
            } else {
                if (value.length <= 4) {
                    return value
                } else if (value.length <= 12) {
                    return value.slice(0, 2) + maskChar.repeat(8) + value.slice(-2)
                } else {
                    return value.slice(0, 4) + maskChar.repeat(8) + value.slice(-4)
                }
            }
        }

        const updateValues = (newValue: string) => {
            actualValueRef.current = newValue
            setMaskedValue(getMaskedValue(newValue))

            if (setValue && fieldName) {
                setValue(fieldName, newValue)
            }
        }

        const handleTextUpdate = (
            value: string,
            selectionStart: number | null,
            selectionEnd: number | null,
            newText: string
        ) => {
            if (selectionStart != null && selectionEnd != null) {
                const before = value.slice(0, selectionStart)
                const after = value.slice(selectionEnd)
                const newValue = before + newText + after
                updateValues(newValue)
            }
        }

        useEffect(() => {
            if (initialValue) {
                updateValues(initialValue as string)
            } else if (getValues && fieldName) {
                updateValues(getValues(fieldName))
            }
        }, [initialValue])

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value
            if (e.target.selectionStart != null) {
                const start = e.target.selectionStart

                if (start <= actualValueRef.current.length) {
                    const before = actualValueRef.current.slice(0, start - 1)
                    const newChar = inputValue[start - 1]
                    const after = actualValueRef.current.slice(start)

                    actualValueRef.current = before + newChar + after
                } else {
                    actualValueRef.current += inputValue.slice(start - 1)
                }

                updateValues(actualValueRef.current)
            }
            if (onChange) {
                const syntheticEvent = {
                    ...e,
                    target: {
                        ...e.target,
                        value: actualValueRef.current,
                    },
                }
                onChange(syntheticEvent)
            }
        }

        const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            if (onBlur) {
                const syntheticEvent = {
                    ...e,
                    target: {
                        ...e.target,
                        value: actualValueRef.current,
                    },
                }
                onBlur(syntheticEvent)
            }
        }

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            const { selectionStart, selectionEnd } = e.currentTarget
            const value = actualValueRef.current

            if (e.key === 'Backspace') {
                if (selectionStart != null && selectionStart === selectionEnd) {
                    handleTextUpdate(value, selectionStart - 1, selectionEnd, '')
                    e.preventDefault()
                } else if (selectionStart != null && selectionStart !== selectionEnd) {
                    handleTextUpdate(value, selectionStart, selectionEnd, '')
                    e.preventDefault()
                }
            } else if (e.key.length === 1 && selectionStart !== selectionEnd) {
                handleTextUpdate(value, selectionStart, selectionEnd, e.key)
                e.preventDefault()
            }
        }

        const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
            e.preventDefault()
            const pasteText = e.clipboardData.getData('text')
            const { selectionStart, selectionEnd } = e.currentTarget
            const value = actualValueRef.current

            handleTextUpdate(value, selectionStart, selectionEnd, pasteText)
        }

        return (
            <Input
                type={props.type}
                {...props}
                ref={inputRef}
                value={maskedValue}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
                autoComplete="off"
            />
        )
    }
)

MaskedInput.displayName = 'MaskedInput'
