import { ArrowLeftToLine, ArrowRightFromLine } from 'lucide-react'
import { Button } from '@/components/ui/button'

interface SidebarToggleProps {
    isOpen: boolean | undefined
    setIsOpen?: (open: boolean) => void
}

export function SideNavToggle({ isOpen, setIsOpen }: SidebarToggleProps) {
    return (
        <Button
            onClick={() => setIsOpen?.(!isOpen)}
            className="cursor-pointer z-[200] p-0 hover:bg-primary-100 hover:text-primary"
            variant="ghost"
            size="icon"
        >
            {isOpen ? <ArrowLeftToLine size={16} /> : <ArrowRightFromLine size={16} />}
        </Button>
    )
}
