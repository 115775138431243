import React from 'react'
import AlertTriangle from '@/assets/icons/alert-triangle.svg?react'

interface NotificationBannerProps {
    message: string
    variant?: 'default' | 'success' | 'warning'
    linkText?: string
    onLinkClick?: () => void
}

const NotificationBanner: React.FC<NotificationBannerProps> = ({
    message,
    variant = 'default',
    onLinkClick,
    linkText,
}) => {
    const getBgColor = () => {
        switch (variant) {
            case 'success':
                return 'bg-success-muted'
            case 'warning':
                return 'bg-warning-muted'
            default:
                return 'bg-card'
        }
    }

    const getIconColor = () => {
        switch (variant) {
            case 'success':
                return 'text-success-foreground'
            case 'warning':
                return 'text-warning'
            default:
                return 'text-foreground'
        }
    }
    return (
        <div className={`w-full flex justify-center items-center gap-2 ${getBgColor()} px-4 py-2`}>
            <AlertTriangle className={`h-4 w-4 ${getIconColor()}`} />
            <p className={'text-sm'}>{message}</p>
            {onLinkClick && linkText && (
                <>
                    <span
                        onClick={() => onLinkClick()}
                        className="text-primary text-sm font-bold cursor-pointer underline"
                    >
                        {linkText}
                    </span>
                </>
            )}
        </div>
    )
}

export default NotificationBanner
