import apiRequest from '@/lib/apiClient'

export interface FeedResult {
    id: string
    source: string
    apiKeyName: string
    streamed: boolean
    duration: string
    requestModel: string
    responseModel: string
    providerType: string
    promptTokens: number
    completionTokens: number
    totalTokens: number
    clientIpAddress?: string
    userAgent?: string
    tags?: string[]
}

export interface PipelineFeedResult {
    id: string
    executionId: string
    pipelineId: string
    pipelineExecutionName: string
    pipelineVersionId?: string
    pipelineVersionNumber?: number
    temporary: boolean
    currentState: string
    pipelineResult: string
    tenantId: string
    projectId: string
    createdAt: string
    updatedAt: string
    userId: string
}

export interface GatewayFeedResult {
    id: string
    tenantId: string
    projectId?: string
    createdAt: string
    apiKeyName?: string
    streamed: boolean
    duration: string
    requestModel?: string
    responseModel?: string
    providerType: string
    responseProviderType: string
    promptTokens: number
    completionTokens: number
    totalTokens: number
    userAgent?: string
    clientIpAddress?: string
}

export interface StepExecutionContext {
    [key: string]: {
        stepId: string
        success: boolean
    }
}

export interface PipelineFeedResultList {
    items: PipelineFeedResult[]
    totalCount: number
}

export interface GatewayFeedResultList {
    items: GatewayFeedResult[]
    totalCount: number
}

export const getPipelineFeed = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<PipelineFeedResultList> => {
    if (queryParams?.projectId == null) {
        // biome-ignore lint/performance/noDelete: <explanation>
        delete queryParams?.projectId
    }
    return apiRequest('/v1/Feed/pipelines', { queryParams })
}

export const getPipelineFeedDetails = async (id: string): Promise<StepExecutionContext[]> => {
    return apiRequest(`/v1/Feed/pipelines/${id}`)
}

export const getGatewayFeed = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<GatewayFeedResultList> => {
    if (queryParams?.projectId == null) {
        // biome-ignore lint/performance/noDelete: <explanation>
        delete queryParams?.projectId
    }
    return apiRequest('/v1/Feed/gateway', { queryParams })
}

export const getGatewayFeedDetails = async (id: string): Promise<StepExecutionContext[]> => {
    return apiRequest(`/v1/Feed/gateway/${id}`)
}
