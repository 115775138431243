import { useState } from 'react'

export interface paginationParams {
    initialPageSize?: number
    initialPageIndex?: number
}

export function usePagination({ initialPageSize, initialPageIndex }: paginationParams) {
    const [paginationState, setPaginationState] = useState({
        pageSize: initialPageSize ?? 25,
        pageIndex: initialPageIndex ?? 0,
    })
    const { pageSize, pageIndex } = paginationState

    return {
        limit: pageSize,
        onPaginationChange: setPaginationState,
        paginationState,
        skip: pageSize * pageIndex,
    }
}
