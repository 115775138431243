import { type Group, getAllGroups } from '@/api/groups.ts'
import { type User, assignUserToGroup, getAllUsers, getInternalUserById } from '@/api/users.ts'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog.tsx'
import { Input } from '@/components/ui/input.tsx'
import { Label } from '@/components/ui/label.tsx'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { useDebounce } from '@uidotdev/usehooks'
import { cx } from 'class-variance-authority'
import { Search } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@/lib/i18n'

export const Route = createFileRoute('/_mainLayout/settings/edit-user/$userId')({
    loader: async ({ params }) => getInternalUserById(params.userId),
    component: () => {
        const params = Route.useParams()
        const router = useRouter()
        return (
            <Dialog open onOpenChange={() => router.history.back()}>
                <DialogContent className={'max-w-modal-lg max-h-modal px-0'}>
                    <EditUser key={`edit-user-${params.userId}`} />
                </DialogContent>
            </Dialog>
        )
    },
})

function EditUser() {
    const user = Route.useLoaderData()
    const [selectedGroups, setSelectedGroups] = useState([] as Group[])
    const [suggestedGroups, setSuggestedGroups] = useState([] as Group[])
    const [searchTerm, setSearchTerm] = useState('')
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const debouncedSearchTerm = useDebounce(searchTerm, 500)
    const router = useRouter()

    const options = {
        page: 1,
        pageSize: 10,
        filter: '',
    }
    const [groupQuery, setGroupQuery] = useState(options)

    const handleRemoveGroup = (group: Group) => {
        setSelectedGroups(selectedGroups.filter((g) => g.id !== group.id))
        setSuggestedGroups([...suggestedGroups, group])
    }

    const handleAddGroup = (group: Group) => {
        setSelectedGroups([...selectedGroups, group])
    }
    const handleSearch = (searchText: string) => {
        setSearchTerm(searchText)
    }
    // Queries
    useQuery({
        queryKey: [QueryKeys.USERS, user.id],
        queryFn: () => getAllUsers(),
    })

    const GetAllGroupsQuery = useQuery({
        queryKey: [QueryKeys.GROUPS, groupQuery],
        queryFn: () => getAllGroups(groupQuery ?? { page: 1, pageSize: 10 }),
    })

    const assignUserToGroupMutation = useMutation({
        mutationFn: (data: User) => assignUserToGroup(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.USERS] })
            toast({
                title: t('success_title'),
                description: t('users_models.updateUserSuccess'),
                variant: 'default',
            })
            router.history.back()
        },
        onError: (error: any) => {
            toast({
                title: t('error_title'),
                description: error.message,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = async (data: User) => {
        try {
            await assignUserToGroupMutation.mutateAsync({
                ...data,
                groups: selectedGroups,
            })
        } catch (error) {
            console.error(error)
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        }
    }

    const isGroupSelected = (group: Group) => {
        return selectedGroups.some((selectedGroup) => selectedGroup.id === group.id)
    }

    useEffect(() => {
        setSelectedGroups(user.groups || [])
    }, [user.groups])

    useEffect(() => {
        const query = { ...groupQuery, filter: debouncedSearchTerm }
        setGroupQuery(query)
        GetAllGroupsQuery.refetch()
    }, [debouncedSearchTerm])

    const appForm = useForm<User>({
        defaultValues: {
            ...user,
            groups: selectedGroups,
        },
    })

    return (
        <div>
            <form
                onSubmit={appForm.handleSubmit(onSubmit)}
                className="max-h-modal flex flex-col justify-between"
            >
                <DialogHeader className="-mt-3 mb-2 px-5">
                    {t('users_models.editUser')}
                </DialogHeader>
                <div className={'overflow-auto max-h-[625px] px-5'}>
                    <div className={'grid grid-cols-4'}>
                        <div></div>
                        <div className={'col-span-4'}>
                            <div className={'flex flex-col gap-4'}>
                                <div>
                                    <Label htmlFor="firstName" className="text-left">
                                        {t('users_models.firstName')}:
                                    </Label>
                                    <Label htmlFor="userFirstName" className="text-left p-2">
                                        {user.firstName}
                                    </Label>
                                </div>
                                <div>
                                    <Label htmlFor="lastName" className="text-left">
                                        {t('users_models.lastName')}:
                                    </Label>

                                    <Label htmlFor="userLastName" className="text-left p-2">
                                        {user.lastName}
                                    </Label>
                                </div>
                                <div>
                                    <Label htmlFor="email" className="text-left">
                                        {t('users_models.email')}:
                                    </Label>

                                    <Label htmlFor="userEmail" className="text-right p-2">
                                        {user.email}
                                    </Label>
                                </div>
                                <div>
                                    <Label htmlFor="addGroups" className="text-left">
                                        {t('users_models.addGroups')}:
                                    </Label>
                                    <Input
                                        startIcon={Search}
                                        value={searchTerm}
                                        onChange={(e) => handleSearch(e.target.value)}
                                        className={cx('border-none, outline-none, flex-1')}
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="suggestedGroups" className="text-left">
                                        {t('users_models.suggestedGroups')}:
                                    </Label>
                                    <ul className="overflow-auto h-60 w-96 border border-b-0 border-gray-200">
                                        {GetAllGroupsQuery.data?.items.map((group) => (
                                            <li
                                                key={group.id}
                                                className="flex h-10 items-center justify-center gap-1 border-b border-gray-200"
                                            >
                                                <div className="ml-2 space-x-4" />
                                                <h6 className="flex-1 text-sm text-slate-600">
                                                    {group.name}
                                                </h6>

                                                <button
                                                    type="button"
                                                    className="group block h-full w-16 hover:bg-slate-400"
                                                    onClick={() =>
                                                        isGroupSelected(group)
                                                            ? handleRemoveGroup(group)
                                                            : handleAddGroup(group)
                                                    }
                                                >
                                                    <span className="text-lg text-slate-400 group-hover:text-slate-200">
                                                        {isGroupSelected(group) ? '✔' : '+'}
                                                    </span>
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    <Label htmlFor="selectedGroups" className="text-left">
                                        {t('users_models.selectedGroups')}:
                                    </Label>
                                    <ul className="overflow-auto h-60 w-96 border border-b-0 border-gray-200">
                                        {selectedGroups.map((group) => (
                                            <li
                                                key={group.id}
                                                className="flex h-10 items-center justify-center gap-1 border-b border-gray-200"
                                            >
                                                <div className="ml-2 space-x-4"></div>
                                                <h6 className="flex-1 text-sm text-slate-600 space-x-4">
                                                    {group.name}
                                                </h6>

                                                <button
                                                    type="button"
                                                    className="group block h-full w-16 hover:bg-slate-400"
                                                    onClick={() => handleRemoveGroup(group)}
                                                >
                                                    <span className="text-lg text-slate-400 group-hover:text-slate-200">
                                                        -
                                                    </span>
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
                <div className="px-5 mt-2">
                    <Button
                        className="w-16"
                        onClick={appForm.handleSubmit(onSubmit)}
                        type="submit"
                        disabled={selectedGroups.length <= 0}
                    >
                        {t('save')}
                    </Button>
                </div>
            </form>
        </div>
    )
}
