import { CreateDataSourceGroup, DataSourceGroup } from '@/api/dataSourceGroup'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { toast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from '@tanstack/react-router'
import { t } from 'i18next'
import { useForm } from 'react-hook-form'

export const CreateNewClient = ({
    projectId,
    onCreate,
}: {
    projectId: string
    onCreate: () => void
}): JSX.Element => {
    const queryClient = useQueryClient()
    const router = useRouter()
    const { register, handleSubmit } = useForm<{ name: string }>()

    const datasourceGroupMutation = useMutation({
        mutationFn: CreateDataSourceGroup,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_DATASOURCE_GROUPS] })
            toast({
                title: t('success_title'),
                description: 'Client created successfully',
            })
            onCreate()
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const handleCreateNewDataSourceGroup = async (data: { name: string }) => {
        try {
            const dataToSend = {
                projectId: projectId,
                name: data.name,
                description: '',
            }

            await datasourceGroupMutation.mutateAsync(dataToSend as DataSourceGroup)
            await router.invalidate()
        } catch (error: any) {
            if (error.status === 409) {
                toast({
                    title: t('error_title'),
                    description: error.message,
                    variant: 'destructive',
                })
            } else {
                toast({
                    title: t('error_title'),
                    description: t('error_description_generic'),
                    variant: 'destructive',
                })
            }
        }
    }

    return (
        <div>
            <h3>Name your first client</h3>
            <form onSubmit={handleSubmit(handleCreateNewDataSourceGroup)}>
                <div className="flex flex-col gap-6">
                    <div className="flex flex-col gap-2">
                        <Label>Name</Label>
                        <Input id="name" {...register('name')} placeholder="Client Name" />
                    </div>
                    <Button
                        type="submit"
                        className="bg-primary-legal hover:bg-[#5C33FFDD] font-gilroy-bold leading-6 font-normal text-[13px]"
                    >
                        Create
                    </Button>
                </div>
            </form>
        </div>
    )
}
