import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { X } from 'lucide-react'
import { Dispatch, FC, SetStateAction } from 'react'
import { Checkbox } from '../ui/checkbox'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '../ui/select'
import { Parameter } from './Parameter'

export type UseCase = {
    id: string
    description: string
    examples: {
        id: string
        toolParameterId: string
        value: string
        enabled: boolean
    }[]
}

interface UseCaseProps {
    index: number
    useCase: UseCase
    useCases: UseCase[]
    setUseCases: Dispatch<SetStateAction<UseCase[]>>
    parameters: Parameter[]
}

export const UseCase: FC<UseCaseProps> = (props) => {
    const { index, useCase, useCases, setUseCases, parameters } = props

    return (
        <div className="flex flex-row gap-2 w-full items-center">
            {useCases.length > 1 && (
                <Button
                    type="button"
                    variant="ghost"
                    className="px-3 py-1 rounded-full"
                    onClick={() => {
                        setUseCases((prev) => prev.filter((_, i) => i !== index))
                    }}
                >
                    <X className="h-4 w-4" />
                </Button>
            )}
            <div className="flex flex-col p-3 w-full rounded-md bg-gray-100/50 dark:bg-gray-100/5">
                <p className="text-sm italic my-1">If the user says</p>
                <Input
                    required
                    value={useCases[index].description}
                    onChange={(e) =>
                        setUseCases((prev) => {
                            prev[index].description = e.target.value
                            return [...prev]
                        })
                    }
                    placeholder=""
                    className="bg-background"
                />
                <p className="text-sm italic my-1">The tool should provide:</p>
                <div className="flex flex-row justify-between gap-2 items-center ">
                    <div className="flex flex-col gap-1 w-1/4 h-full">
                        {parameters.map((param, paramIndex) => {
                            if (param.name === '') return null
                            return (
                                <div className="flex flex-row items-center gap-2">
                                    <Checkbox
                                        checked={useCase.examples[paramIndex]?.enabled}
                                        onCheckedChange={(val) => {
                                            setUseCases((prev) => {
                                                prev[index].examples[paramIndex].enabled =
                                                    val as boolean

                                                return [...prev]
                                            })
                                        }}
                                    />
                                    <p className="px-2 h-9 content-center text-sm select-none">
                                        {param.name || '---'}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                    <div className="flex flex-col gap-1 w-3/4 h-full">
                        {parameters.map((param, paramIndex) => {
                            if (param.name === '') return null

                            if (param.validOptions.length > 0 && param.validOptions[0] !== '') {
                                return (
                                    <Select
                                        required={useCase.examples[paramIndex]?.enabled}
                                        disabled={!useCases[index]?.examples[paramIndex]?.enabled}
                                        onValueChange={(val) =>
                                            setUseCases((prev) => {
                                                const thisParam = prev[index].examples[paramIndex]
                                                if (thisParam && thisParam?.value != null) {
                                                    prev[index].examples[paramIndex].value = val
                                                    return [...prev]
                                                } else {
                                                    return prev
                                                }
                                            })
                                        }
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select an option" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectLabel>Options</SelectLabel>
                                                {param.validOptions.map(
                                                    (option) =>
                                                        option !== '' && (
                                                            <SelectItem value={option}>
                                                                {option}
                                                            </SelectItem>
                                                        )
                                                )}
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                )
                            }
                            return (
                                <Input
                                    required={useCase.examples[paramIndex]?.enabled}
                                    value={useCases[index]?.examples[paramIndex]?.value}
                                    disabled={!useCases[index]?.examples[paramIndex]?.enabled}
                                    onChange={(e) => {
                                        setUseCases((prev) => {
                                            const thisParam = prev[index].examples[paramIndex]
                                            if (thisParam && thisParam?.value !== null) {
                                                prev[index].examples[paramIndex].value =
                                                    e.currentTarget?.value
                                                return [...prev]
                                            } else {
                                                return prev
                                            }
                                        })
                                    }}
                                    type={
                                        parameters.find(
                                            (p) =>
                                                p.id ===
                                                useCases[index]?.examples[paramIndex]
                                                    ?.toolParameterId
                                        )?.type === 'int'
                                            ? 'number'
                                            : 'text'
                                    }
                                    placeholder="Parameter Value"
                                    className="bg-background"
                                    id="name"
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
