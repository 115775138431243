import { cn } from '@/lib/utils'
import type { routeTree } from '@/routeTree.gen'
import * as Tabs from '@radix-ui/react-tabs'
import { Link, type RoutePaths } from '@tanstack/react-router'

export const TabLink: React.FC<{
    path: RoutePaths<typeof routeTree>
    params?: { projectId: string }
    label: JSX.Element | string
    value: string
    icon: JSX.Element
    className?: string
}> = ({ path, params, label, value, icon, className }) => {
    return (
        <Link
            to={path}
            params={params}
            activeProps={{
                className:
                    'text-primary shadow-primary focus:shadow-primary relative shadow-[inset_0_-1px_0_0,0_1px_0_0]',
            }}
        >
            <Tabs.Trigger
                className={cn(
                    'bg-background px-1 gap-2 group h-[45px] w-fit flex items-center justify-center text-[15px] leading-none select-none first:rounded-tl-md last:rounded-tr-md outline-none cursor-default',
                    className
                )}
                value={value}
            >
                <div className={'flex gap-1 pr-1 items-center'}>
                    {icon}
                    {label}
                </div>
            </Tabs.Trigger>
        </Link>
    )
}
