import { DeleteModel, GetAllModels, type Model, ReprocessUploadedModel } from '@/api/models'
import { DefaultDataTable } from '@/components/DefaultDataTable'
import PaginationHeader, {
    type paginationAndSortingParams,
} from '@/components/pagination/paginationHeader'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Toaster } from '@/components/ui/toaster'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { usePagination } from '@/hooks/use-pagination'
import useSearchEffect from '@/hooks/use-search-effect'
import { useSorting } from '@/hooks/use-sorting'
import { HandleDeleteError } from '@/lib/HandleErrors'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
    Link,
    Outlet,
    type SearchSchemaInput,
    createFileRoute,
    useNavigate,
    useRouter,
} from '@tanstack/react-router'
import type { ColumnDef } from '@tanstack/react-table'
import { useDebounce } from '@uidotdev/usehooks'
import { Check, Loader2, MoreHorizontal, Pencil, RotateCcw, Trash, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from '@/lib/i18n'

export const Route = createFileRoute('/_mainLayout/$projectId/_projectLayout/models')({
    component: () => {
        const params = Route.useParams()
        return <AllModels key={`models-overview-${params.projectId}`} />
    },
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams & { libraryModelId: string } => {
        return {
            projectId: search.projectId as string,
            pageNumber: search.pageNumber as number,
            pageSize: search.pageSize as number,
            sortBy: search.sortBy as string,
            libraryModelId: search.libraryModelId as string,
        }
    },
})

function AllModels() {
    const { t } = useTranslation()
    const params = Route.useParams()
    const queryParams = Route.useSearch()
    const router = useRouter()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const deleteModelMutation = useMutation({
        mutationFn: DeleteModel,
        onError: (error) => {
            HandleDeleteError(error, 'models')
        },
    })
    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy,
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })

    const GetAllModelsQuery = useQuery({
        queryKey: [QueryKeys.ALL_MODELS, params.projectId, queryParams],
        queryFn: () =>
            GetAllModels(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams, projectId: params.projectId }
                    : { pageSize: 25, pageNumber: 1, projectId: params.projectId }
            ),
    })

    const [searchValue, setSearchValue] = useState<string>('')
    const debouncedSearchValue = useDebounce(searchValue, 500)
    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        params,
        onPaginationChange,
    })

    const handleDeleteModel = async (modelId: string) => {
        try {
            await deleteModelMutation.mutateAsync(modelId)
            await queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_MODELS, params.projectId],
            })
            await router.invalidate()
        } catch (error) {
            HandleDeleteError(error, 'model')
        }
    }

    const handleRowClick = async (row: { original: Model }) => {
        await router.navigate({
            to: '/$projectId/models/$modelId/edit',
            params: { modelId: row.original.id, projectId: params.projectId },
            search: { ...queryParams },
        })
    }

    const handleReprocess = async (modelId: string) => {
        await ReprocessUploadedModel(modelId)
    }

    const columns: ColumnDef<Model>[] = [
        {
            accessorKey: 'displayName',
            header: t('name'),
        },
        {
            accessorKey: 'modelName',
            header: t('listModel.modelName'),
        },
        { accessorKey: 'provider', header: t('listModel.provider') },
        {
            accessorKey: 'sourceType',
            header: t('listModel.credential_type'),
            cell: ({ row }) => {
                return (
                    <p>
                        {row.original.sourceType === 'Library'
                            ? t('listModel.airia_credential')
                            : t('listModel.custom_credential')}
                    </p>
                )
            },
        },
        {
            accessorKey: 'createdAt',
            header: t('last_modified'),
            cell: ({ row }) => {
                return (
                    <p>
                        {new Date(
                            row.original.updatedAt ?? row.original.createdAt ?? ''
                        ).toLocaleString()}
                    </p>
                )
            },
        },
        {
            accessorKey: 'state',
            header: t('columns_pipelines.status'),
            cell: ({ row }) => {
                if (
                    row.original.userProvidedDetails.deploymentType === 'Internal' &&
                    (row.original.userProvidedDetails.state?.toLowerCase() === 'failed' ||
                        row.original?.userProvidedDetails.state?.toLowerCase() === 'unknown')
                ) {
                    return (
                        <div className="flex items-center gap-1">
                            <Badge className="rounded-full bg-error-muted hover:bg-error-muted">
                                <div className="flex flex-row gap-1 items-center text-success-foreground text-xs">
                                    <X />
                                    {row.original.userProvidedDetails.state}
                                </div>
                            </Badge>
                            <Button
                                variant="ghost"
                                className="px-2 py-0 rounded-full"
                                onClick={() => handleReprocess(row.original.id)}
                            >
                                <RotateCcw className="w-5 h-5" />
                            </Button>
                        </div>
                    )
                }
                if (
                    row.original.userProvidedDetails.deploymentType === 'Internal' &&
                    row.original.userProvidedDetails.state?.toLowerCase() !== 'finished'
                ) {
                    return (
                        <div className="flex items-center gap-1">
                            {/* <Progress value={getProgressPercentage(row.original.state)} /> */}
                            <Badge className="rounded-full bg-warning-muted hover:bg-warning-muted">
                                <div className="flex flex-row gap-1 items-center text-success-foreground text-xs">
                                    <Loader2 className="h-6 w-6 animate-spin" />
                                    {/*@ts-ignore*/}
                                    {row.original.state}
                                </div>
                            </Badge>
                        </div>
                    )
                }
                return (
                    <Badge className="rounded-full bg-success hover:bg-success">
                        <div className="flex flex-row gap-1 items-center text-success-foreground text-xs">
                            <Check />
                            {t('status_ready')}
                        </div>
                    </Badge>
                )
            },
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only fixed">{t('open_menu')}</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel className="flex gap-1">
                                {t('actions')}
                            </DropdownMenuLabel>
                            <DropdownMenuItem
                                onClick={() => handleRowClick(row)}
                                className="flex gap-1"
                            >
                                <Pencil className="h-4 w-4" /> {t('edit')}
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                onClick={() => {
                                    handleDeleteModel(row.original.id)
                                }}
                                className="flex gap-1 text-red-500"
                            >
                                <Trash className="h-4 w-4" />
                                {t('delete')}
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy:
                    //@ts-ignore
                    columns.find((col) => col.accessorKey === field) != null ? field : 'createdAt',
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState, router.history.location])

    return (
        <div className="flex flex-col gap-2 pr-2">
            <div className="flex justify-between items-center">
                <div className={'flex gap-2 items-center'}>
                    <PaginationHeader
                        queryParams={queryParams}
                        filter={searchValue}
                        setFilter={setSearchValue}
                        sortOptions={columns}
                    />
                    <Button
                        variant="outline"
                        className={'text-primary p-2 shadow-none'}
                        onClick={() => {
                            router.invalidate()
                        }}
                    >
                        <RotateCcw />{' '}
                    </Button>
                </div>
                <div className="flex items-center gap-2">
                    <Link
                        to={'/$projectId/library/models'}
                        params={{ projectId: params.projectId }}
                        search={{ ...queryParams }}
                    >
                        <Button>{t('create_new_model')}</Button>
                    </Link>
                </div>
            </div>
            <DefaultDataTable
                data={GetAllModelsQuery?.data?.items ?? []}
                columns={columns}
                onRowClick={handleRowClick}
                serverPagination
                totalCount={GetAllModelsQuery.data?.totalCount}
                rowsPerPage={queryParams.pageSize}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
            />
            <Toaster />
            <Outlet />
        </div>
    )
}
