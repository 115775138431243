import { createTenantV1, getTenantDetailsV1, getTenantV1 } from '@/api/marketplace.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useAuth } from '@/hooks/use-auth.tsx'

const useTenantQuery = () => {
    const { user } = useAuth()
    const queryClient = useQueryClient()
    const [tenantId, setTenantId] = useState<string | null>()

    const tenantQuery = useQuery({
        queryKey: [QueryKeys.TENANT],
        queryFn: getTenantV1,
        retry: false,
    })

    const tenantDetailsQuery = useQuery({
        queryKey: [QueryKeys.TENANT_DETAILS],
        queryFn: getTenantDetailsV1,
        enabled: !!tenantQuery.data, // only run when tenant exists
    })

    const createTenantMutation = useMutation({
        mutationFn: () => createTenantV1({ emailAddress: user?.profile.email ?? '' }),
        onSuccess: (data) => {
            if (data) {
                // Invalidate and refetch the tenant query after creation
                queryClient.invalidateQueries({ queryKey: [QueryKeys.TENANT] })
                queryClient.invalidateQueries({ queryKey: [QueryKeys.TENANT_DETAILS] })
            }
        },
        onError: () => {
            setTenantId(undefined)
        },
    })

    useEffect(() => {
        if (!tenantQuery.isLoading) {
            setTenantId(tenantQuery.data?.id ?? null)
        }
    }, [tenantQuery.data, tenantQuery.isLoading])

    useEffect(() => {
        // If tenant doesn't exist, and we're not already creating one, trigger creation
        if (tenantId === null) {
            createTenantMutation.mutate()
        }
    }, [tenantId, createTenantMutation.mutate])

    return {
        tenantExists: !!tenantId,
        details: tenantDetailsQuery.data,
        isLoading:
            tenantQuery.isLoading || createTenantMutation.isPending || tenantDetailsQuery.isLoading,
        isError: tenantQuery.isError || createTenantMutation.isError,
    }
}

export default useTenantQuery
