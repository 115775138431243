import type { Project } from '@/api/projects'
import { getAccordionLabels } from '@/components/sidenav/MenuList'
import { create } from 'zustand'

interface SideNavState {
    open: boolean
    recentProjectIds: string[]
    isAccordionOpen: string[]
    pinnedProjects: Project[]
}

interface SideNavActions {
    setOpen: (open: boolean) => void
    setRecentProjectIds: (recentProjectIds: string[]) => void
    setIsAccordionOpen: (isAccordionOpen: string[]) => void
    setPinnedProjects: (pinnedProjects: Project[]) => void
}

export const useSideNavStore = create<SideNavState & SideNavActions>((set) => ({
    open: true,
    recentProjectIds: [],
    isAccordionOpen: getAccordionLabels(),
    pinnedProjects: [],
    setOpen: (open: boolean) => set({ open }),
    setRecentProjectIds: (recentProjectIds: string[]) => set({ recentProjectIds }),
    setIsAccordionOpen: (isAccordionOpen: string[]) => set({ isAccordionOpen }),
    setPinnedProjects: (pinnedProjects: Project[]) => set({ pinnedProjects }),
}))
