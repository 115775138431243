import { deleteDeployment, Deployment, getAllDeployments } from '@/api/deployments'
import { DefaultDataTable } from '@/components/DefaultDataTable'
import PaginationHeader, {
    paginationAndSortingParams,
} from '@/components/pagination/paginationHeader'
import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { toast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { usePagination } from '@/hooks/use-pagination'
import useSearchEffect from '@/hooks/use-search-effect'
import { useSorting } from '@/hooks/use-sorting'
import { HandleDeleteError } from '@/lib/HandleErrors'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
    createFileRoute,
    Link,
    Outlet,
    SearchSchemaInput,
    useNavigate,
    useRouter,
} from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import { useDebounce } from '@uidotdev/usehooks'
import { MoreHorizontal, Trash } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from '@/lib/i18n'
import PaperAirplane from '@/assets/icons/paper-airplane.svg?react'

export const Route = createFileRoute('/_mainLayout/$projectId/_projectLayout/deployments')({
    component: () => {
        const params = Route.useParams()
        return <DeploymentsOverview key={`Deployments-Overview-${params.projectId}`} />
    },
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            projectId: search.projectId as string,
            pageNumber: search.pageNumber as number,
            pageSize: search.pageSize as number,
            sortBy: search.sortBy as string,
        }
    },
})

function DeploymentsOverview() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const router = useRouter()
    const params = Route.useParams()
    const queryParams = Route.useSearch()
    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy,
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })

    const [searchValue, setSearchValue] = useState<string>('')
    const debouncedSearchValue = useDebounce(searchValue, 500)
    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        params,
        onPaginationChange,
    })

    const GetAllDeployments = useQuery({
        queryKey: [
            QueryKeys.ALL_DEPLOYMENTS,
            { projectId: params.projectId, ...paginationState, ...sortingState },
        ],
        queryFn: () =>
            getAllDeployments(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams, projectId: params.projectId }
                    : { pageSize: 25, pageNumber: 1, projectId: params.projectId }
            ),
    })

    const queryClient = useQueryClient()

    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy: field,
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState, router.history.location])

    const deleteMutation = useMutation({
        mutationFn: deleteDeployment,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [
                    QueryKeys.ALL_DEPLOYMENTS,
                    ,
                    { projectId: params.projectId, ...paginationState, ...sortingState },
                ],
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('editMemory.errorDescription'),
            })
        },
    })

    const handleDeleteDeployment = async (id: string) => {
        if (window.confirm(t('deployment_delete_confirmation'))) {
            try {
                await deleteMutation.mutateAsync(id)
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.ALL_DEPLOYMENTS],
                })
                router.invalidate()
            } catch (error) {
                HandleDeleteError(error, 'deployment')
            }
        }
    }

    const columns: ColumnDef<Deployment>[] = [
        {
            accessorKey: 'name',
            header: t('name'),
        },
        {
            accessorKey: 'pipeline.name',
            header: t('pipeline'),
            cell: ({ row }) => {
                return (
                    <Link
                        to="/$projectId/pipelines/$pipelineId/$versionNumber"
                        params={{
                            projectId: params.projectId,
                            pipelineId: row.original.pipelineId,
                            versionNumber:
                                row.original.pipeline?.activeVersion.versionNumber.toString() ??
                                '1',
                        }}
                        className=" text-link"
                    >
                        {row.original.pipeline?.name}
                    </Link>
                )
            },
        },
        {
            accessorKey: 'updatedAt',
            header: t('last_modified'),
            cell: ({ row }) => {
                return <p>{new Date(row.original.updatedAt).toLocaleString()}</p>
            },
        },
        {
            header: 'Executions',
            cell: ({ row }) => {
                return <div>{row.original.pipeline?.executionStats?.successCount}</div>
            },
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only fixed">{t('open_menu')}</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel className="flex gap-1 ">
                                {t('actions')}
                            </DropdownMenuLabel>

                            <DropdownMenuItem
                                onClick={async () => {
                                    try {
                                        await handleDeleteDeployment(row.original.id)
                                    } catch (error) {
                                        HandleDeleteError(error, 'deployment')
                                    }
                                }}
                                className="flex gap-1 text-red-500"
                            >
                                <Trash className="h-4 w-4" />
                                Delete Deployment
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    return (
        <div className="flex flex-col gap-2 pr-2">
            <div className="flex justify-between">
                <div className="flex items-center gap-3">
                    <PaginationHeader
                        queryParams={queryParams}
                        filter={searchValue}
                        setFilter={setSearchValue}
                        sortOptions={columns}
                    />
                </div>
                <Link
                    to="/$projectId/deployments/new"
                    params={{ projectId: params.projectId }}
                    search={{ ...queryParams }}
                >
                    <Button className="gap-2">
                        Deploy Pipeline
                        <PaperAirplane className="h-4 w-4 text-primary-foreground" />
                    </Button>
                </Link>
            </div>
            <DefaultDataTable
                data={GetAllDeployments.data?.items ?? []}
                columns={columns}
                onRowClick={(row) => {
                    console.log(row)
                }}
                serverPagination
                totalCount={GetAllDeployments.data?.totalCount}
                rowsPerPage={queryParams.pageSize}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
            />
            <Outlet />
        </div>
    )
}
