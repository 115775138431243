import AiriaLogoBlack from '@/assets/airia-logo-black.svg?react'

export default function ErrorHappened() {
    return (
        <div
            className="flex flex-col justify-center items-center h-screen"
            style={{
                background:
                    'radial-gradient(78.79% 87.03% at 91.8% 96.59%, #4DDDFF 22%, #99F 100%)',
                padding: '10px',
                paddingBottom: '32px',
            }}
        >
            <AiriaLogoBlack className="text-black absolute top-0 left-10 w-40 h-40" />
            <div className="self-center">
                <div className="flex flex-col gap-[60px]">
                    <h2 className="text-black text-4xl font-semibold leading-tight">
                        Oops! Something Went Wrong.
                    </h2>
                    <div>
                        <span className="text-black text-lg font-normal font-['Helvetica Neue']">
                            We're sorry, but it looks like an error has occurred. Don't worry, we're
                            on it!
                            <br />
                            <br />
                            Here’s What You Can Do:
                            <br />
                        </span>
                        <ul className="list-disc pl-5">
                            <li className="text-black text-lg font-normal font-['Helvetica Neue']">
                                Refresh the Page: Sometimes a quick refresh fixes the problem.
                            </li>
                            <li className="text-black text-lg font-normal font-['Helvetica Neue']">
                                Try Again Later: The issue might resolve itself shortly.
                            </li>
                        </ul>
                        <br />
                        <br />
                        <span className="text-black text-lg font-bold font-['Helvetica Neue']">
                            Need Help?
                            <br />
                        </span>
                        <span className="text-black text-lg font-normal font-['Helvetica Neue']">
                            If the problem persists, please contact our{' '}
                        </span>
                        <span className="text-black text-lg font-bold font-['Helvetica Neue'] underline">
                            <a href="mailto:Support@airia.com">support team</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
