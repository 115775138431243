import apiRequest from '@/lib/apiClient'
export type Memory = {
    id: string
    version: string
    name: string
    storageLocation: string
    createdAt: string
    updatedAt: string
    projectId: string
}

export type MemoryList = {
    items: Memory[]
    totalCount: number
}

export const getAllMemory = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<MemoryList> => {
    return apiRequest('/v1/Memory', { queryParams })
}

export const createMemory = async (memory: Memory): Promise<void> => {
    return apiRequest('/v1/Memory', { method: 'POST', body: memory })
}

export const updateMemory = async (memory: Memory): Promise<void> => {
    return apiRequest(`/v1/Memory/${memory.id}`, { method: 'PUT', body: memory })
}

export const getMemoryById = async (id: string): Promise<Memory> => {
    return apiRequest(`/v1/Memory/${id}`)
}

export const deleteMemory = async (id: string): Promise<void> => {
    return apiRequest(`/v1/Memory/${id}`, { method: 'DELETE' })
}
