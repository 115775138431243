import { getAllTools } from '@/api/tools'
import SelectProjectForm from '@/components/modelLibrary/selectProjectForm'
import { ToolLibraryCard } from '@/components/tools/ToolLibraryCard'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { QueryKeys } from '@/constants/QueryKeys'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Search } from 'lucide-react'
import { useEffect, useState } from 'react'

export const Route = createFileRoute('/_mainLayout/tools')({
    component: () => <ToolLibrary />,
})

function ToolLibrary() {
    const [search, setSearch] = useState('')
    //@ts-ignore
    const [_, setFilteredTools] = useState<any[]>([])
    const [addToProjectOpen, setAddToProjectOpen] = useState(false)
    //@ts-ignore
    const [__, setSelectedToolId] = useState('')

    const toolsQuery = useQuery({
        queryKey: [QueryKeys.ALL_TOOLS],
        queryFn: () => getAllTools(),
    })

    useEffect(() => {
        if (search !== '' && toolsQuery.data) {
            setFilteredTools(() =>
                toolsQuery.data.filter((tool) =>
                    tool.name.toLowerCase().includes(search.toLowerCase())
                )
            )
        } else {
            setFilteredTools(toolsQuery.data ?? [])
        }
    }, [search, toolsQuery.data])

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    const onCardClick = (toolId: string) => {
        setSelectedToolId(toolId)
        setAddToProjectOpen(true)
    }

    const onAddToProject = (projectId: string) => {
        console.log(projectId)
    }
    return (
        <div className={'gap-6 mt-2 mx-1'}>
            <div>
                <div>
                    <div className="flex flex-row justify-between items-center">
                        <div className="relative w-full">
                            <Input
                                placeholder={'Search Tools'}
                                onChange={onSearchChange}
                                className={'px-4 py-2 pl-10 w-1/3'}
                            />
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                        </div>
                    </div>
                </div>
                {toolsQuery.data && toolsQuery.data.length > 0 ? (
                    <div
                        className={
                            'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-8'
                        }
                    >
                        {toolsQuery.data.map((tool) => (
                            <ToolLibraryCard onCardClick={onCardClick} key={tool.id} tool={tool} />
                        ))}
                    </div>
                ) : (
                    <div>
                        <p className="self-center text-center mt-7">No results.</p>
                    </div>
                )}
            </div>
            <Dialog open={addToProjectOpen} onOpenChange={setAddToProjectOpen}>
                <DialogContent>
                    <SelectProjectForm onSelectProjectSubmit={onAddToProject} />
                </DialogContent>
            </Dialog>
        </div>
    )
}
