import { cn } from '@/lib/utils'
import type { routeTree } from '@/routeTree.gen'
import { Link, type RoutePaths } from '@tanstack/react-router'
import type { ReactElement } from 'react'

export const SideNavItem: React.FC<{
    icon?: ReactElement
    title: string
    path?: RoutePaths<typeof routeTree>
    params?: Record<string, string>
    projectId?: string
    onClick?: () => void
    endAdornment?: ReactElement
    className?: string
    disabled?: boolean
}> = ({ icon, title, path, projectId, onClick, endAdornment, className, params, disabled }) => {
    if (path) {
        return (
            <Link
                to={path}
                params={params ? params : { projectId }}
                className={cn(
                    'flex rounded-[8px] items-center text-sm hover:bg-primary-100 transition-colors px-2 h-8 ml-2 select-none',
                    className,
                    disabled && 'pointer-events-none opacity-50'
                )}
                activeProps={{
                    className: path ? 'text-primary bg-primary-100 hover:bg-accent' : '',
                }}
                activeOptions={{
                    exact: false,
                }}
                disabled={disabled}
                onClick={() => onClick?.()}
            >
                <div className="w-full flex items-center gap-4">
                    {icon && <div className="flex items-center justify-center">{icon}</div>}
                    <div className="flex-grow min-w-0">
                        <h3 className="truncate" title={title}>
                            {title}
                        </h3>
                    </div>
                    {endAdornment && <div className="flex-shrink-0 ml-2">{endAdornment}</div>}
                </div>
            </Link>
        )
    }
    return (
        // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
        <div
            className={cn(
                'w-full flex gap-4 rounded-lg items-center text-foreground hover:bg-background transition-colors p-2',
                className
            )}
            onClick={() => onClick?.()}
        >
            {icon && (
                <div className="flex-shrink-0 w-6 flex items-center justify-center">{icon}</div>
            )}
            <div className="flex-grow min-w-0 flex items-center justify-between">
                <h3 className="truncate cursor-pointer" title={title}>
                    {title}
                </h3>
            </div>
            {endAdornment && <div className="flex-shrink-0 ml-2">{endAdornment}</div>}
        </div>
    )
}
