import Check from '@/assets/icons/check.svg?react'
import React from 'react'

interface SelectOptionProps {
    checked: boolean
    value: string
    label: string
    onChange: (value: string) => void
}
const SelectOption: React.FC<SelectOptionProps> = ({ value, label, onChange, checked }) => {
    const toggleCheck = () => {
        onChange(value)
    }

    return (
        <div
            className="flex gap-1 items-center bg-background border border-border rounded-lg p-3 cursor-pointer select-none duration-200"
            onClick={toggleCheck}
        >
            <div
                className={`w-6 h-6 rounded-full flex items-center justify-center mr-3 transition-colors duration-200 ${checked ? 'bg-primary' : 'border-2 border-border'}`}
            >
                {checked && <Check className={'text-primary-foreground h-4 w-4'} />}
            </div>
            <span className="text-sm">{label}</span>
        </div>
    )
}

export default SelectOption
