import type { Credential } from '@/api/credentials.ts'
import apiRequest from '@/lib/apiClient'
import type { ApiKey } from './apiKeys'
import type { Dataset } from './datasets'
import type { Memory } from './memory'
import type { Model } from './models'
import type { Pipeline } from './pipelinesConfig'
import type { Prompt } from './prompts'

interface BaseProject {
    id: string
    name: string
    createdAt: string
    updatedAt: string
    description?: string
    projectIcon?: string
    projectIconId?: string
    projectType: string
    isPinned: boolean
}

export type Project = BaseProject & {
    pipelines: Pipeline[]
    models: Model[]
    dataSources: Dataset[]
    prompts: Prompt[]
    apiKeys: ApiKey[]
    memories: Memory[]
    credentials: Credential[]
}

export type PinnedProject = {
    projectId: string
    id: string
    name: string
}

export type ProjectList = {
    items: Project[]
    totalCount: number
}

export const getAllProjects = async (): Promise<Project[]> => {
    return apiRequest('/v1/Project')
}

export const getAllProjectsPaginated = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<ProjectList> => {
    return apiRequest('/v1/Project/paginated', { queryParams })
}

export const createProject = async (project: Project): Promise<Project> => {
    return apiRequest('/v1/Project', { method: 'POST', body: project })
}
export const updateProject = async (project: Project): Promise<void> => {
    return apiRequest(`/v1/Project/${project.id}`, { method: 'PUT', body: project })
}

export const getProjectById = async (id: string): Promise<Project> => {
    return apiRequest(`/v1/Project/${id}`)
}

export const deleteProject = async (id: string): Promise<void> => {
    return apiRequest(`/v1/Project/${id}`, { method: 'DELETE' })
}

export const pinProject = async (id: string): Promise<void> => {
    return apiRequest(`/v1/Project/pin/${id}`, { method: 'POST' })
}

export const unPinProject = async (id: string): Promise<void> => {
    return apiRequest(`/v1/Project/unpin/${id}`, { method: 'POST' })
}

export const getAllPinnedProjects = async (): Promise<PinnedProject[]> => {
    return apiRequest('/v1/Project/pinned')
}
