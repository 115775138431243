import { Connector } from '@/api/connectors.ts'
import Plus from '@/assets/icons/plus.svg?react'
import { Badge } from '@/components/ui/badge.tsx'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip.tsx'
import { ArrowRight } from 'lucide-react'

interface ConnectorCardProps {
    connector: Connector
    isDisabled?: boolean
    onCardClick?: () => void
}

export function ConnectorCard({ connector, onCardClick, isDisabled = false }: ConnectorCardProps) {
    return (
        <div
            className={`p-4 rounded-lg flex flex-1 flex-col gap-3 cursor-pointer border ${isDisabled ? '' : 'hover:border-primary group'}`}
            onClick={() => onCardClick && !isDisabled && onCardClick()}
        >
            <div className={'flex gap-2 items-center justify-between'}>
                <div className={'flex gap-4'}>
                    <h3 className={`text-lg ${isDisabled ? 'text-gray-350' : 'text-gray-550'}`}>
                        {connector.name}
                    </h3>
                    {isDisabled && (
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <div>
                                        <Badge
                                            className={
                                                'whitespace-nowrap overflow-hidden text-ellipsis bg-gray-150 text-card-foreground rounded-full shadow-none hover:bg-card hover:text-card-foreground'
                                            }
                                        >
                                            {'Private Preview'}
                                        </Badge>
                                    </div>
                                </TooltipTrigger>
                                <TooltipContent
                                    sideOffset={3}
                                    side={'bottom'}
                                    className={
                                        'flex flex-col gap-2 bg-gray-150 text-foreground text-sm p-4 max-w-[250px]'
                                    }
                                >
                                    <p>
                                        {'Reach out to '}
                                        <a
                                            href={'mailto:support@airia.com'}
                                            className={'text-primary hover:underline'}
                                        >
                                            support@airia.com
                                        </a>
                                        {' to request access.'}
                                    </p>
                                    <div className={'flex justify-end'}>
                                        <a
                                            href={
                                                'https://airia.com/explore/platform/Configuration/Data-Source-Connectors/data-sources'
                                            }
                                            target={'_blank'}
                                            className={
                                                'flex gap-1 items-center text-primary hover:underline'
                                            }
                                        >
                                            Learn more
                                            <ArrowRight className={'w-4 h-4'} />
                                        </a>
                                    </div>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    )}
                </div>
                <div
                    className={`rounded-md p-1 ${isDisabled ? 'dark:bg-gray-350' : 'dark:bg-white'}`}
                >
                    {connector.icon ? (
                        <img
                            src={connector.icon}
                            alt={connector.name}
                            className={`w-10 h-10 ${isDisabled ? 'opacity-40 grayscale' : ''}`}
                        />
                    ) : (
                        connector.iconComponent && <connector.iconComponent />
                    )}
                </div>
            </div>
            <p className={`${isDisabled ? 'text-gray-350' : 'text-gray-450'} text-base`}>
                {connector.description}
            </p>
            <div className={'flex flex-col justify-end h-[100%]'}>
                <div className={'flex justify-between items-center'}>
                    <Badge
                        className={
                            'bg-gray-150 text-card-foreground rounded-full shadow-none hover:bg-card hover:text-card-foreground'
                        }
                    >
                        <span className={`${isDisabled ? 'text-gray-350' : 'text-gray-450'}`}>
                            {connector.classification}
                        </span>
                    </Badge>
                    <Plus className={'h-6 w-6 text-gray-350 group-hover:text-primary'} />
                </div>
            </div>
        </div>
    )
}
