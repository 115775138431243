import { themes } from 'prism-react-renderer'
import { CodeBlock } from 'react-code-block'

interface ParametersCodeBlockProps {
    valueToDisplay: string
    langauge: string
    codeBlockTitle: string
}

export function ParametersCodeBlock({
    valueToDisplay,
    langauge,
    codeBlockTitle,
}: ParametersCodeBlockProps) {
    return (
        <div className="min-h-[150px] rounded-md w-full bg-gray-100/50 dark:bg-gray-100/5 p-2 flex flex-col gap-1">
            <p className="text-xs text-muted-foreground">{codeBlockTitle}</p>
            <CodeBlock language={langauge} code={valueToDisplay} theme={themes.nightOwlLight}>
                <CodeBlock.Code as="div" className="p-3 h-full">
                    <div className="table-row overflow-auto">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell text-sm whitespace-pre-wrap break-all">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
            </CodeBlock>
        </div>
    )
}
