import { type Group, getAllGroups } from '@/api/groups'
import {
    type User,
    createUser,
    deleteUserByAdmin,
    getAllInternalUsers,
    syncUsers,
} from '@/api/users'
import PaginationHeader, {
    type paginationAndSortingParams,
} from '@/components/pagination/paginationHeader'
import { Button } from '@/components/ui/button'
import { CardHeader } from '@/components/ui/card'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { toast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { usePagination } from '@/hooks/use-pagination'
import useSearchEffect from '@/hooks/use-search-effect'
import { useSorting } from '@/hooks/use-sorting'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, type SearchSchemaInput, createFileRoute } from '@tanstack/react-router'
import { useNavigate } from '@tanstack/react-router'
import { type ColumnDef, flexRender } from '@tanstack/react-table'
import { useDebounce } from '@uidotdev/usehooks'
import { cx } from 'class-variance-authority'
import { type LucideIcon, MoreHorizontal, Pencil, RotateCcw, Search, Trash } from 'lucide-react'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@/lib/i18n'
import { DefaultDataTable } from '../../../components/DefaultDataTable'

interface UsersProps {
    // Define the props for the component here
    id: string
    firstName: string
    lastName: string
    isActive: boolean
    email: string
    userName: string
    password: string
    confirmPassword: string
    enterprise: string
    userId: string
    groups: Group[]
}

export const Route = createFileRoute('/_mainLayout/settings/users')({
    component: () => {
        return <Users key={'users-overview'} />
    },
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            pageNumber: search?.pageNumber as number,
            pageSize: search?.pageSize as number,
            sortBy: search?.sortBy as string,
        }
    },
})
const Users: React.FC = () => {
    const params = Route.useParams()
    const queryParams = Route.useSearch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy,
        initialOrder: queryParams.sortDirection ?? 'ASC',
    })
    const queryClient = useQueryClient()
    const [searchValue, setSearchValue] = useState<string>('')
    const debouncedSearchValue = useDebounce(searchValue, 500)
    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        params,
        onPaginationChange,
    })
    const AllUsersQuery = useQuery({
        queryKey: [QueryKeys.USERS, queryParams],
        queryFn: async () =>
            await getAllInternalUsers(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams }
                    : { pageSize: 25, pageNumber: 1 }
            ),
    })
    const handleRowClick = (row: { original: User }) => {
        navigate({
            to: '/settings/edit-user/$userId',
            // @ts-ignore
            params: { userId: row.original.id },
        })
    }

    const handleDelete = async (id: string) => {
        if (window.confirm(t('confirmation_to_continue'))) {
            await deleteUserByAdmin(id).catch((error) => {
                toast({
                    title: 'Error',
                    description: error.message,
                    variant: 'destructive',
                })
            })
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_USERS] })
            AllUsersQuery.refetch()
        }
    }

    const columns: ColumnDef<any>[] = [
        {
            accessorKey: 'name',
            header: t('name'),
            cell: (info: any) =>
                flexRender(({ firstName, lastName }: UsersProps) => {
                    return `${firstName} ${lastName}`
                }, info.row.original),
        },
        {
            accessorKey: 'status',
            header: t('columns_pipelines.status'),
            cell: (info: any) =>
                flexRender(({ isActive }: UsersProps) => {
                    return isActive ? (
                        <div className="flex items-center gap-1">
                            <span>{t('active')}</span>
                        </div>
                    ) : (
                        <div className="flex items-center gap-1">
                            <span>{t('inactive')}</span>
                        </div>
                    )
                }, info.row.original),
        },
        {
            accessorKey: 'groups',
            header: t('groups'),
            cell: (info: any) =>
                flexRender(
                    ({ groups }: UsersProps) => (
                        <div className="flex space-x-4">
                            {groups.map((group) => group.name).join(', ')}
                        </div>
                    ),
                    info.row.original
                ),
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only fixed">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel className="flex gap-1 "> Actions</DropdownMenuLabel>
                            <DropdownMenuItem>
                                <Link
                                    //@ts-ignore
                                    to="/settings/edit-user/$Id"
                                    params={{
                                        //@ts-ignore
                                        Id: row.original.id,
                                    }}
                                    className="flex gap-1"
                                >
                                    <Pencil className="h-4 w-4" />
                                    {t('users_models.editUser')}
                                </Link>
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                className="flex gap-1 text-red-500"
                                onClick={() => handleDelete(row.original.id)}
                            >
                                <Trash className="h-4 w-4" />
                                {t('users_models.deleteUser')}
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy:
                    //@ts-ignore
                    columns.find((col) => col.accessorKey === field) != null ? field : 'name',
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState])

    const syncUsersMutation = useMutation({
        mutationFn: syncUsers,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.USERS] })
            toast({
                title: t('success_title'),
                description: 'Users refreshed.',
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    return (
        <div className="">
            <CardHeader>
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col gap-2 grow">
                        <PaginationHeader
                            queryParams={queryParams}
                            filter={searchValue}
                            setFilter={setSearchValue}
                            sortOptions={columns}
                        />
                    </div>
                    <Button
                        type="button"
                        variant="secondary"
                        disabled={syncUsersMutation.isPending}
                        className="bg-white hover:bg-white/75 text-primary-legal font-gilroy-bold leading-6 font-normal text-[13px] rounded-lg"
                        onClick={async () => {
                            await syncUsersMutation.mutate()
                            await queryClient.invalidateQueries({
                                queryKey: [QueryKeys.USERS],
                            })
                        }}
                    >
                        <RotateCcw className="w-5 h-5" />
                    </Button>
                    <CreateNewUser
                        variant="default"
                        onSuccess={() =>
                            toast({
                                title: t('success_title'),
                                description: t('users_models.createUserSuccess'),
                            })
                        }
                    />
                </div>
            </CardHeader>
            <DefaultDataTable
                className="h-[70vh]"
                onRowClick={handleRowClick}
                data={AllUsersQuery.data?.items ?? []}
                columns={columns}
                rowsPerPage={queryParams.pageSize}
                serverPagination
                totalCount={AllUsersQuery.data?.totalCount}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
            />
        </div>
    )
}

export const CreateNewUser = ({
    variant,
    onSuccess,
    size,
    Icon,
}: {
    variant:
        | 'default'
        | 'destructive'
        | 'outline'
        | 'secondary'
        | 'ghost'
        | 'link'
        | null
        | undefined
    size?: 'default' | 'sm' | 'lg' | 'icon'
    Icon?: LucideIcon
    onSuccess?: () => void
}) => {
    const queryClient = useQueryClient()
    const [openCreate, setOpenCreate] = useState(false)
    const appForm = useForm<UsersProps>()
    const { t } = useTranslation()
    const [searchTerm, setSearchTerm] = useState('')
    const [suggestedGroups, setSuggestedGroups] = useState([] as Group[])
    const [selectedGroups, setSelectedGroups] = useState([] as Group[])
    const debouncedSearchTerm = useDebounce(searchTerm, 500)

    const options = {
        page: 1,
        pageSize: 10,
        filter: '',
    }
    const [groupQuery, setGroupQuery] = useState(options)
    const handleSearch = (searchText: string) => {
        setSearchTerm(searchText)
    }

    const handleRemoveGroup = (group: Group) => {
        setSelectedGroups(selectedGroups.filter((g) => g.id !== group.id))
        setSuggestedGroups([...suggestedGroups, group])
    }

    const handleAddGroup = (group: Group) => {
        setSelectedGroups([...selectedGroups, group])
    }
    const isGroupSelected = (group: Group) => {
        return selectedGroups.some((selectedGroup) => selectedGroup.id === group.id)
    }
    const handleCreateUser = async (data: UsersProps) => {
        try {
            //@ts-ignore
            await createUser({
                ...data,
                groups: selectedGroups,
            }).catch((error) => {
                console.log(error)
                toast({
                    title: 'Error',
                    description: error.message,
                    variant: 'destructive',
                })
            })

            await queryClient.invalidateQueries({ queryKey: [QueryKeys.USERS] })
            setOpenCreate(false)
            onSuccess && onSuccess()
        } catch (error: any) {
            console.error(error)
            toast({
                title: t('error_title'),
                description: error.message,
                variant: 'destructive',
            })
        }
    }
    const GetAllGroupsQuery = useQuery({
        queryKey: [QueryKeys.GROUPS, groupQuery],
        queryFn: () => getAllGroups(groupQuery ?? { page: 1, pageSize: 10 }),
    })

    useEffect(() => {
        const query = { ...groupQuery, filter: debouncedSearchTerm }
        setGroupQuery(query)
        GetAllGroupsQuery.refetch()
    }, [debouncedSearchTerm])

    return (
        <div>
            <Button type="button" size={size} onClick={() => setOpenCreate(true)} variant={variant}>
                {Icon ? <Icon className="text-muted-foreground h-5 w-5" /> : t('user_create')}
            </Button>
            <Dialog open={openCreate} onOpenChange={setOpenCreate} aria-label="Create User">
                <form onSubmit={appForm.handleSubmit(handleCreateUser)}>
                    <DialogContent className={'max-w-modal-lg max-h-modal px-0'}>
                        <DialogHeader className="-mt-3 mb-2 px-5">
                            <DialogTitle>{t('users_models.createNewUser')}</DialogTitle>
                        </DialogHeader>
                        <div className={'overflow-auto max-h-[625px] px-5'}>
                            <div className={'grid grid-cols-4'}>
                                <div></div>
                                <div className={'col-span-4'}>
                                    <div className={'flex flex-col gap-4'}>
                                        <div>
                                            <Label htmlFor="firstName" className="text-left">
                                                {t('users_models.firstName')}:
                                            </Label>
                                            <Input
                                                id="firstName"
                                                type="text"
                                                className="col-span-3"
                                                {...appForm.register('firstName', {
                                                    required: true,
                                                })}
                                            />
                                            {appForm.formState.errors.firstName && (
                                                <span>{t('users_models.fieldRequired')}</span>
                                            )}
                                        </div>
                                        <div>
                                            <Label htmlFor="lastName" className="text-left">
                                                {t('users_models.lastName')}:
                                            </Label>
                                            <Input
                                                id="lastName"
                                                type="text"
                                                className="col-span-3"
                                                {...appForm.register('lastName')}
                                            />
                                        </div>
                                        <div>
                                            <Label htmlFor="email" className="text-left">
                                                {t('users_models.email')}:
                                            </Label>
                                            <Input
                                                id="email"
                                                type="text"
                                                className="col-span-3"
                                                {...appForm.register('email', { required: true })}
                                            />
                                            {appForm.formState.errors.email && (
                                                <span>{t('users_models.fieldRequired')}</span>
                                            )}
                                        </div>
                                        <div>
                                            <Label htmlFor="addGroups" className="text-left">
                                                {t('users_models.addGroups')}:
                                            </Label>
                                            <Input
                                                startIcon={Search}
                                                value={searchTerm}
                                                onChange={(e) => handleSearch(e.target.value)}
                                                className={cx('border-none, outline-none, flex-1')}
                                            />
                                        </div>
                                        <div>
                                            <Label htmlFor="suggestedGroups" className="text-left">
                                                {t('users_models.suggestedGroups')}:
                                            </Label>
                                            <ul className="overflow-auto h-60 w-96 border border-b-0 border-gray-200">
                                                {GetAllGroupsQuery.data?.items.map((group) => (
                                                    <li
                                                        key={group.id}
                                                        className="flex h-10 items-center justify-center gap-1 border-b border-gray-200"
                                                    >
                                                        <div className="ml-2 space-x-4"></div>
                                                        <h6 className="flex-1 text-sm text-slate-600">
                                                            {group.name}
                                                        </h6>

                                                        <button
                                                            type="button"
                                                            className="group block h-full w-16 hover:bg-slate-400"
                                                            onClick={() =>
                                                                isGroupSelected(group)
                                                                    ? handleRemoveGroup(group)
                                                                    : handleAddGroup(group)
                                                            }
                                                        >
                                                            <span className="text-lg text-slate-400 group-hover:text-slate-200">
                                                                {isGroupSelected(group) ? '√' : '+'}
                                                            </span>
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div>
                                            <Label htmlFor="selectedGroups" className="text-left">
                                                {t('users_models.selectedGroups')}:
                                            </Label>
                                            <ul className="overflow-auto h-60 w-96 border border-b-0 border-gray-200">
                                                {selectedGroups.map((group) => (
                                                    <li
                                                        key={group.id}
                                                        className="flex h-10 items-center justify-center gap-1 border-b border-gray-200"
                                                    >
                                                        <div className="ml-2 space-x-4"></div>
                                                        <h6 className="flex-1 text-sm text-slate-600">
                                                            {group.name}
                                                        </h6>

                                                        <button
                                                            type="button"
                                                            className="group block h-full w-16 hover:bg-slate-400"
                                                            onClick={() => handleRemoveGroup(group)}
                                                        >
                                                            <span className="text-lg text-slate-400 group-hover:text-slate-200">
                                                                -
                                                            </span>
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        </div>
                        <DialogFooter>
                            <Button
                                type="submit"
                                onClick={appForm.handleSubmit(handleCreateUser)}
                                disabled={selectedGroups.length <= 0}
                            >
                                {t('users_models.create')}
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </form>
            </Dialog>
        </div>
    )
}
