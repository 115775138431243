import { getIngestionLogsById } from '@/api/datasets'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
    '/_mainLayout/$projectId/_projectLayout/datasets/$datasetId/logs'
)({
    loader: async ({ params: { datasetId } }) => {
        const data = await getIngestionLogsById(datasetId)
        return data
    },
})
