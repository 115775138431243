import { getProjectById } from '@/api/projects'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'
import Container from '@/assets/icons/container.svg?react'
import Database from '@/assets/icons/database.svg?react'
import DataFlow from '@/assets/icons/dataflow.svg?react'
import SimCard from '@/assets/icons/simcard.svg?react'
import Terminal from '@/assets/icons/terminal.svg?react'
import Tools from '@/assets/icons/tools.svg?react'
import Deployments from '@/assets/icons/deployments.svg?react'
import { TabLink } from '@/components/ui/tab-link'
import type { routeTree } from '@/routeTree.gen.ts'
import * as Tabs from '@radix-ui/react-tabs'
import { Outlet, type RoutePaths, createFileRoute } from '@tanstack/react-router'
import { Badge } from '@/components/ui/badge'

export const Route = createFileRoute('/_mainLayout/$projectId/_projectLayout')({
    component: () => {
        const params = Route.useParams()
        return <ProjectLayout key={params.projectId} />
    },
    loader: ({ params }) => getProjectById(params.projectId),
    staleTime: 1000 * 60 * 5,
})

function ProjectLayout() {
    const ProjectInfo = Route.useLoaderData()
    const TABS: {
        path: RoutePaths<typeof routeTree>
        params: { projectId: string }
        label: JSX.Element | string
        icon: JSX.Element
        value: string
        className?: string
    }[] = [
        {
            path: '/$projectId/pipelines',
            params: { projectId: ProjectInfo.id },
            label: 'Pipelines',
            icon: <DataFlow className="w-4 h-4" stroke="currentColor" />,
            value: 'pipelines',
            className: 'pipeline-step',
        },
        {
            path: '/$projectId/models',
            params: { projectId: ProjectInfo.id },
            label: 'Models',
            icon: <Container className="w-4 h-4" stroke="currentColor" />,
            value: 'models',
            className: 'model-step',
        },
        {
            path: '/$projectId/datasets',
            params: { projectId: ProjectInfo.id },
            label: 'Data Sources',
            icon: <Database className="w-4 h-4" stroke="currentColor" />,
            value: 'datasets',
            className: 'datasource-step',
        },
        {
            path: '/$projectId/prompts',
            params: { projectId: ProjectInfo.id },
            label: 'Prompts',
            icon: <Terminal className="w-4 h-4" stroke="currentColor" />,
            value: 'prompts',
            className: 'prompt-step',
        },
        {
            path: '/$projectId/memory',
            params: { projectId: ProjectInfo.id },
            label: 'Memory',
            icon: <SimCard className="w-4 h-4" stroke="currentColor" />,
            value: 'memory',
            className: 'memory-step',
        },
        {
            path: '/$projectId/tools',
            params: { projectId: ProjectInfo.id },
            label: 'Tools',
            icon: <Tools className="w-4 h-4" stroke="currentColor" />,
            value: 'tools',
            className: 'tool-step',
        },
    ]
    if (ProjectInfo.name === 'Airia App') {
        TABS.push({
            path: '/$projectId/deployments',
            params: { projectId: ProjectInfo.id },
            label: (
                <div className="flex items-center gap-2">
                    App Configuration{' '}
                    <Badge variant={'secondary'} className=" bg-secondary-muted text-gray-550">
                        BETA
                    </Badge>
                </div>
            ),
            icon: <Deployments className="w-4 h-4" stroke="currentColor" />,
            value: 'deployments',
        })
    }
    const currentActiveTab = 'pipelines'

    return (
        <div className="flex flex-col pt-6 px-6 h-full">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                    {ProjectInfo.projectIcon ? (
                        <img
                            src={ProjectInfo.projectIcon}
                            alt={ProjectInfo.name}
                            height={16}
                            width={16}
                        />
                    ) : (
                        <AiriaIcon className="w-10 h-10" />
                    )}
                    <h2 className="text-4xl">{ProjectInfo.name}</h2>
                </div>
            </div>

            <Tabs.Root className="flex flex-col gap-5 py-4 " value={currentActiveTab}>
                <Tabs.List
                    className="shrink-0 flex border-b border-mauve6 w-full gap-10 [&_*]:cursor-pointer"
                    aria-label="Manage your account"
                >
                    {TABS.map((tab) => (
                        <TabLink key={tab.path} {...tab} />
                    ))}
                </Tabs.List>
            </Tabs.Root>
            <div className="p-1">
                <Outlet />
            </div>
        </div>
    )
}
