import { ChevronRight } from 'lucide-react'
import { Badge } from '../ui/badge'

interface ToolLibraryCardProps {
    tool: any
    onCardClick?: (toolId: string) => void
}

export function ToolLibraryCard({ tool, onCardClick }: ToolLibraryCardProps) {
    return (
        <div
            className={
                'p-3 rounded-lg flex flex-1 flex-col gap-3 cursor-pointer border hover:border-primary group'
            }
            onClick={() => onCardClick && onCardClick(tool)}
        >
            <div className={'flex gap-2 items-center justify-between'}>
                <h3 className={'text-lg'}>{tool.name}</h3>
                {tool.icon ? (
                    <img src={tool.icon} alt={tool.icon} className={'w-8 h-8'} />
                ) : (
                    tool.iconComponent && <tool.iconComponent />
                )}
            </div>
            <p className={'text-background-foreground'}>{tool.description}</p>
            <div className={'flex flex-col justify-end h-[100%]'}>
                <div className={'flex justify-between items-center'}>
                    <Badge
                        className={
                            'bg-card text-card-foreground rounded-full shadow-none hover:bg-card hover:text-card-foreground'
                        }
                    >
                        <span className={'text-base'}>{tool.classification}</span>
                    </Badge>
                    <ChevronRight className={'h-6 w-6 group-hover:text-primary'} />
                </div>
            </div>
        </div>
    )
}
