import Azure from '@/assets/azure.svg?react'
import OpenAi from '@/assets/icons/openai.svg?react'
import Airia from '@/assets/models/Airia.svg?react'
import Anthropic from '@/assets/models/Anthropic.svg?react'
import Gemini from '@/assets/models/Gemini.svg?react'
import Mistral from '@/assets/models/Mistral.svg?react'
import Ollama from '@/assets/models/Ollama.svg?react'
import HuggingFace from '@/assets/models/huggingFace.svg?react'
import AI21 from '@/assets/models/AI21.svg?react'
import Cohere from '@/assets/models/Cohere.svg?react'

const ProviderIcon: React.FC<{ provider: string; className?: string }> = ({
    provider,
    className,
}) => {
    const iconClassName = `dark:bg-white p-1 rounded-md ${className}`
    switch (provider.toLowerCase()) {
        case 'ai21':
            return <AI21 className={iconClassName} />
        case 'airia':
            return <Airia className={iconClassName} />
        case 'anthropic':
            return <Anthropic className={iconClassName} />
        case 'azure':
            return <Azure className={iconClassName} />
        case 'cohere':
            return <Cohere className={iconClassName} />
        case 'gemini':
            return <Gemini className={iconClassName} />
        case 'hugging face':
            return <HuggingFace className={iconClassName} />
        case 'mistral':
            return <Mistral className={iconClassName} />
        case 'ollama':
            return <Ollama className={iconClassName} />
        case 'openai':
            return <OpenAi className={iconClassName} />
        default:
            return <Airia className={iconClassName} />
    }
}

export default ProviderIcon
