import { Table } from '@tanstack/react-table'

import { Button } from '@/components/ui/button'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'

interface DataTablePaginationProps<TData> {
    table: Table<TData>
    rowsPerPage?: number
    totalCount?: number
}

export function DataTablePagination<TData>({
    table,
    rowsPerPage,
    totalCount,
}: DataTablePaginationProps<TData>) {
    const pageCount = Math.ceil((totalCount ?? 0) / table.getState().pagination.pageSize)

    return (
        <div className="flex items-center px-2 py-2 sticky top-full">
            <div className="flex flex-row items-center justify-between space-x-6 lg:space-x-8 w-full">
                <div className="flex items-center flex-row gap-2">
                    <div className="flex w-[100px] text-sm font-medium select-none">
                        Page {totalCount !== 0 ? table.getState().pagination.pageIndex + 1 : 0} of{' '}
                        {pageCount}
                    </div>
                    {!rowsPerPage && (
                        <div className="flex items-center space-x-2">
                            <p className="text-sm font-medium">Rows per page</p>
                            <Select
                                value={`${table.getState().pagination.pageSize}`}
                                onValueChange={(value) => {
                                    table.setPageSize(Number(value))
                                }}
                            >
                                <SelectTrigger className="h-8 w-[70px]">
                                    <SelectValue
                                        placeholder={table.getState().pagination.pageSize}
                                    />
                                </SelectTrigger>
                                <SelectContent side="top">
                                    {[10, 25, 50, 100].map((pageSize) => (
                                        <SelectItem key={pageSize} value={`${pageSize}`}>
                                            {pageSize}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    )}
                </div>
                <div className="flex items-center space-x-2 gap-1">
                    <Button
                        variant="ghost"
                        type="button"
                        className=" select-none text-primary hover:text-primary px-2"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <span className="sr-only fixed">Go to previous page</span>
                        Previous
                    </Button>
                    <Button
                        variant="ghost"
                        type="button"
                        className=" select-none text-primary hover:text-primary px-2"
                        onClick={() => table.nextPage()}
                        disabled={
                            table.getState().pagination.pageIndex === pageCount - 1 ||
                            totalCount === 0
                        }
                    >
                        <span className="sr-only fixed">Go to next page</span>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}
