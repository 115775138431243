import React, { useEffect } from 'react'
import { Button } from '@/components/ui/button.tsx'
import { formatCurrency } from '@/lib/utils.ts'
import useTenantQuery from '@/hooks/use-tenant-query.ts'

interface AccountSummaryProps {
    onAddFundsClick: () => void
}

export const LOW_BALANCE_THRESHOLD = 1

const AccountSummary: React.FC<AccountSummaryProps> = ({ onAddFundsClick }) => {
    const [balance, setBalance] = React.useState(0)
    const { details, isLoading } = useTenantQuery()

    useEffect(() => {
        if (!isLoading && details) {
            setBalance(details.balance)
        }
    }, [details, isLoading])

    return (
        <div className={'flex justify-between items-center'}>
            <div className={'flex flex-col jusify-center gap-4'}>
                <h1>Account Balance</h1>
                <span
                    className={`${balance <= LOW_BALANCE_THRESHOLD ? 'text-destructive' : 'text-primary'} text-5xl font-gilroy-regular`}
                >
                    {formatCurrency(balance)}
                </span>
            </div>
            <div className={'flex items-center'}>
                <Button onClick={onAddFundsClick} className={'font-gilroy-medium'}>
                    Manage Account
                </Button>
            </div>
        </div>
    )
}

export default AccountSummary
