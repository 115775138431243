import SelectOption from '@/components/settings/SelectOption.tsx'
import React, { useEffect, useState } from 'react'
import { Separator } from '@/components/ui/separator.tsx'
import { Button } from '@/components/ui/button.tsx'
import { downloadOperationsCsv } from '@/api/aiOperationsExecution.ts'
import { downloadCsv } from '@/lib/utils.ts'
import { startOfMonth, endOfMonth, subMonths, subDays, startOfDay, endOfDay } from 'date-fns'
import { DatePicker } from '@/components/ui/date-picker.tsx'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { CaretDownIcon } from '@radix-ui/react-icons'
import type { paginationAndSortingParams } from '@/components/pagination/paginationHeader.tsx'

interface ExportFormProps {
    queryParams: paginationAndSortingParams & {
        startTime?: number
        endTime?: number
        projectIds?: string
    }
    currentWindow?: 'thisMonth' | 'lastMonth' | 'last90Days' | number
    filterCount: number
    totalCount: number
}

const ExportForm: React.FC<ExportFormProps> = ({
    currentWindow,
    filterCount,
    queryParams,
    totalCount,
}) => {
    const [scope, setScope] = useState<'current' | 'all' | 'custom'>('current')
    const [csvParams, setCsvParams] = useState<
        paginationAndSortingParams & {
            startTime?: number
            endTime?: number
        }
    >({ ...queryParams, pageSize: 1000 })
    const [start, setStart] = React.useState<Date | undefined>(undefined)
    const [end, setEnd] = React.useState<Date | undefined>(undefined)
    const [rangeOption, setRangeOption] = React.useState<
        'thisMonth' | 'lastMonth' | 'last90Days' | 'custom'
    >('thisMonth')

    const toUTC = (date: Date) => date.getTime()

    const getMonthRange = (date: Date) => ({
        startTime: toUTC(startOfMonth(date)),
        endTime: toUTC(endOfMonth(date)),
    })

    const getNinetyDaysRange = (date: Date) => ({
        startTime: toUTC(startOfDay(subDays(date, 90))),
        endTime: toUTC(endOfDay(date)),
    })

    const getSingleDayRange = (date: Date) => ({
        startTime: toUTC(startOfDay(date)),
        endTime: toUTC(endOfDay(date)),
    })

    useEffect(() => {
        const now = new Date()
        let range: { startTime?: number; endTime?: number } = {
            startTime: undefined,
            endTime: undefined,
        }

        if (scope === 'all') {
            range = getMonthRange(now)
        } else if (scope === 'current') {
            switch (currentWindow) {
                case 'thisMonth':
                    range = getMonthRange(now)
                    break
                case 'lastMonth':
                    range = getMonthRange(subMonths(now, 1))
                    break
                case 'last90Days':
                    range = getNinetyDaysRange(now)
                    break
                default:
                    if (typeof currentWindow === 'number') {
                        range = getSingleDayRange(new Date(currentWindow))
                    }
            }
        }

        setCsvParams((prev) => ({ ...prev, ...range }))
    }, [scope, currentWindow])

    useEffect(() => {
        const now = new Date()
        let startTime, endTime

        switch (rangeOption) {
            case 'thisMonth':
                startTime = toUTC(startOfMonth(now))
                endTime = undefined
                break
            case 'lastMonth': {
                const lastMonth = subMonths(now, 1)
                startTime = toUTC(startOfMonth(lastMonth))
                endTime = toUTC(endOfMonth(lastMonth))
                break
            }
            case 'last90Days':
                startTime = toUTC(startOfMonth(subDays(now, 90)))
                endTime = undefined
                break
            case 'custom':
                startTime = start ? toUTC(start) : undefined
                endTime = end ? toUTC(end) : undefined
                break
            default:
                startTime = undefined
                endTime = undefined
        }

        setCsvParams((prev) => ({
            ...prev,
            startTime,
            endTime,
        }))
    }, [rangeOption, start, end])

    const onExportClick = () => {
        downloadOperationsCsv(csvParams).then((data) => {
            downloadCsv(data, `operations-${Date.now()}.csv`)
        })
    }

    const getDropdownTriggerLabel = () => {
        switch (rangeOption) {
            case 'thisMonth':
                return 'Current Month'
            case 'lastMonth':
                return 'Previous Month'
            case 'last90Days':
                return 'Past 90 Days'
            case 'custom':
                return 'Custom Date Range'
            default:
                return 'Select a range'
        }
    }

    return (
        <div className={'flex flex-col gap-2'}>
            <div className={'flex flex-col gap-2 p-4'}>
                <span>Export Scope</span>
                <div className={'flex gap-4'}>
                    <SelectOption
                        checked={scope === 'current'}
                        value={'current'}
                        label={'Current Page'}
                        onChange={(value) => setScope(value as 'current')}
                    />
                    {/* <SelectOption
                        checked={scope === 'all'}
                        value={'all'}
                        label={'All Transactions'}
                        onChange={(value) => setScope(value as 'all')}
                    /> */}
                    <SelectOption
                        checked={scope === 'custom'}
                        value={'custom'}
                        label={'Custom Date Range'}
                        onChange={(value) => setScope(value as 'custom')}
                    />
                </div>
            </div>
            {scope === 'current' && (
                <span className={'text-sm p-4 flex flex-row gap-1'}>
                    Export will include the <p>{totalCount}</p> transactions with{' '}
                    <p>{filterCount}</p> filters applied.
                </span>
            )}
            {scope === 'custom' && (
                <div className={'flex flex-col gap-2 p-4'}>
                    <span className={'text-sm'}>
                        Export will include the most recent <b>1000 transactions</b> within the
                        following date range:
                    </span>
                    <span>Range</span>
                    <div className={'flex gap-4'}>
                        <DropdownMenu>
                            <DropdownMenuTrigger>
                                <Button variant={'outline'}>
                                    {getDropdownTriggerLabel()} <CaretDownIcon />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuItem onClick={() => setRangeOption('thisMonth')}>
                                    Current Month
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={() => setRangeOption('lastMonth')}>
                                    Previous Month
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={() => setRangeOption('last90Days')}>
                                    Past 90 Days
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={() => setRangeOption('custom')}>
                                    Custom Date Range
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                        {rangeOption === 'custom' && (
                            <DatePicker placeholder={'Select start date'} onDateSelect={setStart} />
                        )}{' '}
                        {rangeOption === 'custom' && (
                            <DatePicker placeholder={'Select end date'} onDateSelect={setEnd} />
                        )}
                    </div>
                </div>
            )}
            <Separator />
            <div className={'flex justify-end gap-6'}>
                <Button onClick={() => onExportClick()}>Export</Button>
            </div>
        </div>
    )
}

export default ExportForm
