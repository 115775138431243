'use client'

import * as React from 'react'
import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { ReactElement } from 'react'

interface DatePickerProps {
    onDateSelect: (date: Date | undefined) => void
    placeholder?: string
    className?: string
}

export function DatePicker(props: DatePickerProps): ReactElement {
    const [date, setDate] = React.useState<Date>()

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={'outline'}
                    className={cn(
                        'w-[280px] justify-between text-left font-normal rounded-sm',
                        !date && 'text-muted-foreground',
                        props.className
                    )}
                >
                    {date ? format(date, 'PPP') : <span>{props.placeholder ?? 'Pick a date'}</span>}
                    <CalendarIcon className="mr-2 h-4 w-4" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar
                    mode="single"
                    selected={date}
                    onSelect={(d) => {
                        setDate(d)
                        props.onDateSelect(d)
                    }}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    )
}
