import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog.tsx'
import { downloadFile } from '@/lib/utils'
import { Clipboard, Download } from 'lucide-react'
import { themes } from 'prism-react-renderer'
import { CodeBlock } from 'react-code-block'
import { useTranslation } from '@/lib/i18n'
import { useToast } from '../ui/use-toast'

interface ExecutionLogsDialogProps {
    open: boolean
    setOpen: (open: boolean) => void
    report: string
}

export function ExecutionLogsDialog({ open, setOpen, report }: ExecutionLogsDialogProps) {
    const { t } = useTranslation()
    const { toast } = useToast()

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(report)
        toast({
            title: 'Copy to clipboard',
            description: 'Report copied to clipboard',
        })
    }

    const download = () => {
        downloadFile(report, 'report.json', 'text/json')
    }

    return (
        <Dialog open={open} onOpenChange={() => setOpen(false)}>
            <DialogContent className={'max-w-modal-lg max-h-modal'}>
                <DialogHeader>{t('debug_information')}</DialogHeader>
                <CodeBlock language="js" code={report} theme={themes.vsLight}>
                    <CodeBlock.Code
                        as="div"
                        className="bg-gray-100 p-6 rounded-xl shadow-lg text-wrap whitespace-pre-wrap break-all overflow-y-scroll"
                        style={{ maxHeight: '550px' }}
                    >
                        <div className="table-row overflow-auto">
                            <CodeBlock.LineNumber className="table-cell pr-4 w-12 text-xs text-gray-500 text-right select-none" />
                            <CodeBlock.LineContent className="table-cell text-sm">
                                <CodeBlock.Token />
                            </CodeBlock.LineContent>
                        </div>
                    </CodeBlock.Code>
                </CodeBlock>
                <div className="flex w-full flex-row justify-end gap-1">
                    <Button
                        type="button"
                        variant="outline"
                        className="text-primary border-primary flex justify-between gap-1"
                        onClick={copyToClipboard}
                    >
                        {t('copy_text')}
                        <Clipboard className="w-4" />
                    </Button>
                    <Button type="button" className="flex justify-between gap-1" onClick={download}>
                        {t('download_json')}
                        <Download className="w-4" />
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}
