import AiriaLogoBlack from '@/assets/airia-logo-black.svg?react'
import { Button } from './ui/button'
import { Link } from '@tanstack/react-router'

export default function NotFoundComponent() {
    return (
        <div
            className="flex flex-col justify-center items-center h-screen"
            style={{
                background:
                    'radial-gradient(78.79% 87.03% at 91.8% 96.59%, #4DDDFF 22%, #99F 100%)',
                padding: '10px',
                paddingBottom: '32px',
            }}
        >
            <AiriaLogoBlack className="text-black absolute top-0 left-10 w-40 h-40" />
            <div className="self-center flex flex-col items-center gap-2">
                <h1 className="text-black text-[300px] font-normal">404</h1>
                <div className="text-black text-[28px] font-normal">
                    The page you’re looking for can’t be found
                </div>

                <Button>
                    <Link to="/">Back Home</Link>
                </Button>
            </div>
        </div>
    )
}
