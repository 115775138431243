import apiRequest from '@/lib/apiClient'

export interface IngestionFeedResponse {
    items: IngestionFeedEventItem[]
    totalCount: number
}

export enum IngestionFeedStatus {
    Unknown = 'Unknown',
    Pending = 'Pending',
    Validating = 'Validating',
    Ingesting = 'Ingesting',
    Storing = 'Storing',
    Indexing = 'Indexing',
    Finished = 'Finished',
    Failed = 'Failed',
    Validated = 'Validated',
    Ingested = 'Ingested',
    Stored = 'Stored',
    Indexed = 'Indexed',
}

export interface IngestionFeedEventItem {
    projectId: string
    dataSourceName: string
    message: string
    state: IngestionFeedStatus
    createdAt: string
    jobId: string
    isStepComplete: boolean
    dataSourceType: string
    ingestedDataSize: {
        sizeInMb: number
        numberOfRecords: number
    }
    ingestDuration: string
    filesProcessed: {
        total: number
        successful: number
        failed: number
        errorRate: number
    }
    vectorDatabase: {
        databaseType: string
        provider: string
        region: string
        cloud: string
    }
    databaseSpend: {
        totalWriteUnits: number
        totalWriteSpend: number
        totalStorageCost: number
    }
    embeddings: {
        provider: string
        model: string
        totalTokens: number
        totalCost: number
    }
    totalSpend: number
}

export const ingestData = async (id: string): Promise<null> => {
    return apiRequest(`/v1/Ingestion/${id}`, { method: 'POST' })
}

export const getIngestionFeed = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<IngestionFeedResponse> => {
    return apiRequest('/v1/datasourceIngestionLog', { queryParams })
}
