import { LegalTopNav } from '@/components/legal/LegalTopNav'
import { Toaster } from '@/components/ui/toaster'
import { ConfidentialityProvider } from '@/hooks/use-confidentiality'
import { Outlet, createFileRoute } from '@tanstack/react-router'
import { Suspense } from 'react'
import { TanStackRouterDevtools } from './_mainLayout'

export const Route = createFileRoute('/_legalLayout')({
    component: () => {
        const params = Route.useParams()
        return (
            <ConfidentialityProvider>
                <div className="flex h-screen bg-background">
                    <div className="grow">
                        <LegalTopNav params={params} />
                        <div className="mx-14 -mt-56 p-5 rounded-md h-full">
                            <Outlet />
                        </div>
                    </div>
                    <Suspense>
                        <TanStackRouterDevtools position="bottom-right" />
                    </Suspense>
                    <Toaster />
                </div>
            </ConfidentialityProvider>
        )
    },
    parseParams: (params) => {
        return {
            ...params,
            //@ts-ignore
            projectId: params?.projectId,
        }
    },
})
