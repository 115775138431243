import { getDataSourceForms } from '@/api/datasets'
import { getProjectById } from '@/api/projects'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_no_nav_layout/$projectId/library/datasources/')({
    loader: async ({ params }) => {
        const Project = await getProjectById(params.projectId)
        const DataSourceForms = await getDataSourceForms()
        return { Project, DataSourceForms }
    },
})
