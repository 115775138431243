import { LoaderCircle } from 'lucide-react'
import millify from 'millify'
import type React from 'react'
import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    type TooltipProps,
    XAxis,
    YAxis,
} from 'recharts'
import { format } from 'date-fns/format'
import { Separator } from '@/components/ui/separator.tsx'
import type { ChartDataItem } from './Overview'
import type { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent'

interface UsageChartProps {
    data: ChartDataItem[]
    dateRange: string
    loading: boolean
    type: 'pipelines' | 'models'
}

const UsageChart: React.FC<UsageChartProps> = ({ data, dateRange, loading }) => {
    const colors = ['#007AFF', '#006bdf', '#208bff', '#60acff', '#9fcdff', '#dfeeff']

    const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-background rounded-lg p-2 shadow-lg">
                    {payload.map((item: Payload<ValueType, NameType>) => (
                        <div key={item.name} className="flex flex-row items-center gap-2">
                            <div
                                className="w-3 h-3 rounded-full flex-shrink-0"
                                style={{ backgroundColor: item.color }}
                            />
                            <p className="text-sm text-gray-550">{item.name}:</p>
                            <p className="text-sm text-gray-550">{item.value}</p>
                        </div>
                    ))}
                </div>
            )
        }
        return null
    }

    if (loading) {
        return (
            <div className="w-full h-[400px] justify-center flex flex-col">
                <LoaderCircle className="self-center w-12 h-12 animate-spin" />
            </div>
        )
    }

    return (
        <>
            <ResponsiveContainer width="100%" height={180}>
                <BarChart data={data} margin={{ bottom: 0, top: 0, right: 0, left: 0 }}>
                    <XAxis
                        dataKey={
                            dateRange === 'month' || dateRange === 'last30'
                                ? 'originalDate'
                                : 'date'
                        }
                        tickLine={false}
                        axisLine={false}
                        textAnchor={'right'}
                        style={{ fontSize: '13px' }}
                        tickFormatter={(value: Date | string) => {
                            const date =
                                dateRange === 'month' || dateRange === 'last30'
                                    ? new Date(value).toLocaleString('en-US', { timeZone: 'UTC' })
                                    : value
                            return dateRange === 'month' || dateRange === 'last30'
                                ? `${format(new Date(date), 'LLL')} ${new Date(date).getUTCDate()}`
                                : (date as string)
                        }}
                        interval={dateRange === 'year' ? 0 : 'equidistantPreserveStart'}
                    />
                    <YAxis
                        tickCount={3}
                        orientation="right"
                        tickLine={false}
                        axisLine={false}
                        tickFormatter={(value) => millify(value)}
                    />
                    <CartesianGrid vertical={false} />
                    <Tooltip content={<CustomTooltip />} />
                    {(() => {
                        const allKeys = Array.from(
                            new Set(
                                data.flatMap((item) =>
                                    Object.keys(item).filter(
                                        (key) => key !== 'date' && key !== 'originalDate'
                                    )
                                )
                            )
                        )

                        const keyTotals = allKeys.reduce<Record<string, number>>((acc, key) => {
                            acc[key] = data.reduce((sum, item) => sum + (Number(item[key]) || 0), 0)
                            return acc
                        }, {})

                        const sortedKeys = allKeys
                            .filter((key) => key !== 'Other')
                            .sort((a, b) => keyTotals[b] - keyTotals[a])

                        if (allKeys.includes('Other')) {
                            sortedKeys.push('Other')
                        }

                        return sortedKeys.map((key, index) => (
                            <Bar
                                key={key}
                                dataKey={key}
                                stackId="a"
                                fill={colors[index % colors.length]}
                            />
                        ))
                    })()}
                </BarChart>
            </ResponsiveContainer>
            <Separator className={'mt-4'} />
        </>
    )
}

export default UsageChart
